import styled from 'styled-components'
import { Modal as BaseModal, ModalContentWrapper } from 'secondstep-components'

export const Modal = styled(BaseModal)`
  .Overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  ${ModalContentWrapper} {
    width: fit-content;
  }
`
