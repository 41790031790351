import styled, { css } from 'styled-components'
import { themeGet } from 'styled-system'

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid ${themeGet(`colors.themePrimary`)};
  outline: none;
  margin-right: 0.75rem;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${themeGet(`colors.blue`)};
    border-color: ${themeGet(`colors.blue`)};

    &:before {
      content: '✔';
      position: absolute;
      left: 0.0625rem;
      font-size: 1rem;
      color: white;
    }
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: ${themeGet(`fontFamily`)};
  color: ${themeGet(`colors.darkGray`)};
  margin-top: 0.9375rem;
`
