import React, { useState, useEffect, useRef } from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import { connect } from 'react-redux'
import { Loading } from 'secondstep-components'
import {
  operations as sitesOperations,
  selectors as sitesSelectors,
} from '../../store/sitesManager'
import { actions as notificationActions } from '../../store/notificationsManager'
import Unauthorized from 'secondstep-components/src/components/Unauthorized'
import UserManagement from './component'
import {
  inputModeType,
  siteLicenseChecker,
  UpdateSiteUserNotifications,
} from 'lib/util'
import { FILTER_USER_MAP, LOADING_TEXT } from './constants'

export const UserManagementContainer = props => {
  const {
    addBulkUsersResponse,
    createSiteUser,
    createSiteUsers,
    fetchK8SiteUsers,
    fetchSiteUsers,
    hasUnassignedLicenses,
    isAddBulkUsersCompleted,
    isLoading,
    isUpdating,
    location,
    message,
    pushTo,
    remindSiteUser,
    remindSiteUsers,
    resetBulkAddUsers,
    site,
    siteId,
    siteUsers,
    sites,
    updateSiteUser,
    // userFilters,
  } = props
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
  const [sendInvitesModalOpen, setRemindAllModalOpen] = useState(false)
  const [users, setUsers] = useState(siteUsers)
  const [selectedFilterOption, setSelectedFilterOption] = useState({})
  const [userCounts, setUserCounts] = useState()
  const [userInviteDialogCounts, setUserInviteDialogCounts] = useState()
  const [userFilterOptions, setUserFilterOptions] = useState()
  const { hasK8License, hasSelaLicense } = site ? siteLicenseChecker(site) : {}
  const prevFilterOptionRef = useRef({})

  useEffect(() => {
    if (!siteId) {
      pushTo(`/user-management/${site.id}`)
    }
  }, [])

  useEffect(() => {
    const { id: siteId } = site
    const filterOption = {
      key: 'all',
      name: FILTER_USER_MAP['all'],
    }
    if (!siteUsers) {
      fetchSiteUsers(siteId)
      if (hasK8License) {
        fetchK8SiteUsers(siteId)
      }
    } else {
      createUserMetaData()
      setUsers(siteUsers.filter(user => user.isActive))
      if (location?.query) {
        if (location.query?.accountStatus === 'pending') {
          filterOption.key = 'pending'
          filterOption.name = FILTER_USER_MAP['pending']
        }
        if (
          location.query?.classCount === '0' ||
          location.query?.classCount === 0
        ) {
          filterOption.key = 'noClasses'
          filterOption.name = FILTER_USER_MAP['noClasses']
        }
        if (location.query?.role === 'Administrator') {
          filterOption.key = 'admin'
          filterOption.name = FILTER_USER_MAP['admin']
        }
        if (location.query?.role === 'LeaderTeam') {
          filterOption.key = 'leaderTeam'
          filterOption.name = FILTER_USER_MAP['leaderTeam']
        }
        if (location.query?.role === 'Teacher') {
          filterOption.key = 'teacher'
          filterOption.name = FILTER_USER_MAP['teacher']
        }
      }
      setSelectedFilterOption(filterOption)
    }
  }, [siteUsers])

  useEffect(() => {
    if (prevFilterOptionRef?.current?.key !== selectedFilterOption?.key) {
      filterSiteUsers(selectedFilterOption)
      prevFilterOptionRef.current = selectedFilterOption
    }
  }, [selectedFilterOption])

  const createUserMetaData = () => {
    if (!siteUsers) {
      return
    }

    const activeUsers = siteUsers.filter(user => user.isActive === true)

    const newUserCounts = activeUsers.reduce(
      (counts, user) => {
        const role = user.roles[0]
        const isActive = !!user.userId

        switch (role) {
          case 'Admin':
            counts.admin++
            isActive ? counts.adminActive++ : counts.adminPending++
            break
          case 'Leader Team':
            counts.leaderTeam++
            isActive ? counts.leaderTeamActive++ : counts.leaderTeamPending++
            break
          case 'Teacher':
            counts.teacher++
            isActive ? counts.teacherActive++ : counts.teacherPending++
            break
          default:
            console.warn(`Unknown role: ${role}`)
        }

        if (!isActive) {
          counts.pending++
        }

        if (user.classesCreated === 0) {
          counts.noClasses++
        }

        return counts
      },
      {
        pending: 0,
        all: activeUsers.length,
        admin: 0,
        adminActive: 0,
        adminPending: 0,
        leaderTeam: 0,
        leaderTeamActive: 0,
        leaderTeamPending: 0,
        teacher: 0,
        teacherActive: 0,
        teacherPending: 0,
        noClasses: 0,
      },
    )

    const filterUserCounts = (({
      all,
      admin,
      teacher,
      leaderTeam,
      pending,
      noClasses,
    }) => ({
      all,
      admin,
      teacher,
      leaderTeam,
      pending,
      noClasses,
    }))(newUserCounts)

    if (!hasSelaLicense) delete filterUserCounts.leaderTeam

    const filterOptions = Object.entries(filterUserCounts).map(e => ({
      name: `${FILTER_USER_MAP[e[0]]} (${e[1]})`,
      key: e[0],
    }))
    setUserFilterOptions(filterOptions)

    const newUserInviteDialogCounts = (({
      pending,
      all,
      admin,
      leaderTeam,
      teacher,
    }) => ({
      pending,
      all,
      admin,
      leaderTeam,
      teacher,
    }))(newUserCounts)

    setUserCounts(newUserCounts)
    setUserInviteDialogCounts(newUserInviteDialogCounts)
  }

  const filterSitesByAdminClaim = sites =>
    sites.filter(site => site.claims.includes('Admin'))

  const filterSiteUsers = event => {
    const filterOption = event.key
    let filteredSiteUsers
    const siteUrl = `/user-management/${site.id}`

    if (filterOption === 'all') {
      setUsers(siteUsers)
      pushTo(siteUrl)
    } else if (filterOption === 'admin') {
      filteredSiteUsers = siteUsers.filter(user => user.roles.includes('Admin'))
      setUsers(filteredSiteUsers)
      pushTo(`${siteUrl}?role=Administrator`)
    } else if (filterOption === 'teacher') {
      filteredSiteUsers = siteUsers.filter(user =>
        user.roles.includes('Teacher'),
      )
      pushTo(`${siteUrl}?role=Teacher`)
      setUsers(filteredSiteUsers)
    } else if (filterOption === 'pending') {
      filteredSiteUsers = siteUsers.filter(
        user => user.isActive === true && user.status === 'Invited',
      )
      pushTo(`${siteUrl}?accountStatus=pending`)
      setUsers(filteredSiteUsers)
    } else if (filterOption === 'noClasses') {
      filteredSiteUsers = siteUsers.filter(user => user.classesCreated === 0)
      pushTo(`${siteUrl}?classCount=0`)
      setUsers(filteredSiteUsers)
    } else if (filterOption === 'leaderTeam') {
      filteredSiteUsers = siteUsers.filter(user =>
        user.roles.includes('Leader Team'),
      )
      pushTo(`${siteUrl}?role=LeaderTeam`)
      setUsers(filteredSiteUsers)
    }
  }

  const onSiteChange = e => {
    const { value } = e
    const newSiteId = value.id || {}
    const isDifferentSite = siteId !== newSiteId

    if (isDifferentSite) {
      pushTo(`/user-management/${newSiteId}`)
    }
  }

  const redirectToUserManagement = () => {
    pushTo(`/user-management/${siteId}`)
  }

  const redirectToLicenseManagement = () => {
    pushTo(`/license-management`)
  }

  const handleSendInvites = ({ all, pending, ...roles }) => {
    const { id } = site
    const sendInvObj = { pendingUsers: !!pending }

    if (!all && !pending && Object.values(roles).every(role => !role)) {
      setRemindAllModalOpen(false)
    }

    if (all || pending) {
      remindSiteUsers(id, sendInvObj)
      setRemindAllModalOpen(false)
      return
    }
    const sendToRoles = Object.entries(roles)
      .filter(([role, value]) => value && role !== 'all' && role !== 'pending')
      .map(([role]) =>
        role === 'leaderTeam'
          ? 'Leader Team'
          : `${role.charAt(0).toUpperCase()}${role.slice(1)}`,
      )

    if (sendToRoles.length > 0) {
      sendInvObj.roles = sendToRoles
    }

    remindSiteUsers(id, sendInvObj)
    setRemindAllModalOpen(false)
  }

  const handleOpenSendInvitesModal = () => setRemindAllModalOpen(true)

  const handleCloseSendInvitesModal = () => setRemindAllModalOpen(false)

  const handleOpenAddUsersModal = () => setIsAddUserModalOpen(true)

  const handleSubmitAddUsers = async (
    emails,
    role,
    sendNotification,
    inputMode,
  ) => {
    const addSiteUsers = emails.map(email => ({
      inviteEmail: email?.trim(),
      isActive: true,
      roles: [role],
      siteId,
    }))
    if (inputMode === inputModeType.bulk) {
      await createSiteUsers(siteId, addSiteUsers, sendNotification)
    } else {
      await createSiteUser(addSiteUsers[0], sendNotification, site)
      resetBulkAddUsers()
      setIsAddUserModalOpen(false)
    }
  }

  const handleCloseAddUsersModal = () => {
    resetBulkAddUsers()
    redirectToUserManagement()
    setIsAddUserModalOpen(false)
  }

  const handleRemindEmail = siteUser => {
    remindSiteUser(siteUser)
  }

  const handleGrantNewRole = (siteUser, newRole) => {
    updateSiteUser(
      { ...siteUser, roles: [newRole] },
      UpdateSiteUserNotifications.grantNewRole,
    )
  }

  const handleCancelUserAccess = siteUser => {
    updateSiteUser(
      { ...siteUser, isActive: false },
      UpdateSiteUserNotifications.removeUser,
    )
  }

  if (isLoading) {
    return (
      <Loading
        dataTestId="loading-user-management"
        loadingText={LOADING_TEXT}
      />
    )
  }

  if (sites && !site) {
    return <Unauthorized location={location} />
  }

  return site && users ? (
    <UserManagement
      addBulkUsersResponse={addBulkUsersResponse}
      allActiveUsersCount={userCounts}
      filterSiteUsers={filterSiteUsers}
      handleCancelUserAccess={handleCancelUserAccess}
      handleCloseAddUsersModal={handleCloseAddUsersModal}
      handleCloseSendInvitesModal={handleCloseSendInvitesModal}
      handleGrantNewRole={handleGrantNewRole}
      handleOpenAddUsersModal={handleOpenAddUsersModal}
      handleOpenSendInvitesModal={handleOpenSendInvitesModal}
      handleRemindEmail={handleRemindEmail}
      handleSendInvites={handleSendInvites}
      handleSubmitAddUsers={handleSubmitAddUsers}
      hasK8License={hasK8License}
      hasSelaLicense={hasSelaLicense}
      hasUnassignedLicenses={hasUnassignedLicenses}
      isAddBulkUsersCompleted={isAddBulkUsersCompleted}
      isAddUserModalOpen={isAddUserModalOpen}
      isLoading={isLoading}
      isSendInvitesModalOpen={sendInvitesModalOpen}
      isUpdating={isUpdating}
      message={message}
      onSiteChange={onSiteChange}
      redirectToLicenseManagement={redirectToLicenseManagement}
      selectedFilterOption={selectedFilterOption}
      selectedSite={site}
      selectedSiteUsers={users}
      siteUsers={siteUsers}
      sites={filterSitesByAdminClaim(sites)}
      updateSiteUser={updateSiteUser}
      userFilterOptions={userFilterOptions}
      userInviteDialogCounts={userInviteDialogCounts}
    />
  ) : null
}

UserManagementContainer.propTypes = {
  addBulkUsersResponse: PropTypes.objectOf(Array),
  createSiteUser: PropTypes.func.isRequired,
  createSiteUsers: PropTypes.func.isRequired,
  fetchK8SiteUsers: PropTypes.func.isRequired,
  fetchSiteUsers: PropTypes.func.isRequired,
  hasUnassignedLicenses: PropTypes.bool,
  isAddBulkUsersCompleted: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape({
      accountStatus: PropTypes.string,
      classCount: PropTypes.string,
      role: PropTypes.string,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      siteId: PropTypes.string,
    }),
  }),
  message: PropTypes.string,
  pushTo: PropTypes.func.isRequired,
  remindSiteUser: PropTypes.func,
  remindSiteUsers: PropTypes.func,
  resetBulkAddUsers: PropTypes.func.isRequired,
  site: PropTypes.object.isRequired,
  siteId: PropTypes.number,
  siteUsers: PropTypes.objectOf(arrayOf(Object)),
  sites: PropTypes.arrayOf(Object),
  updateSiteUser: PropTypes.func.isRequired,
  userFilters: PropTypes.object,
}

const mapStateToProps = (state, { match }) => {
  const {
    licenseManager,
    router,
    sitesManager: {
      message,
      sites: { data: sites, isLoading: isSitesLoading },
      siteUsers: {
        addBulkUsersResponse,
        isLoading: isSiteUsersLoading,
        isUpdating,
        isAddBulkUsersCompleted,
      },
      remindUsers: { isLoading: isSending },
    },
  } = state
  const hasUnassignedLicenses =
    licenseManager?.userLicenses?.unassignedLicenses?.length > 0
  const isLoading = isSitesLoading || isSiteUsersLoading || isSending
  let { siteId } = match.params
  siteId = parseInt(siteId, 10)
  const site = !siteId ? sites[0] : sites.find(site => site.id === siteId)

  const { hasK8License } = site ? siteLicenseChecker(site) : false
  const siteUsers = sitesSelectors.selectSiteUsersBySiteId(
    state,
    siteId,
    hasK8License,
  )
  const { location } = router

  const { query } = location
  const { role, accountStatus, classCount } = query
  const userFilters = {}
  role && (userFilters.role = role)
  accountStatus && (userFilters.accountStatus = accountStatus)
  classCount &&
    !isNaN(classCount) &&
    (userFilters.classCount = parseInt(classCount))

  return {
    addBulkUsersResponse,
    hasUnassignedLicenses,
    isAddBulkUsersCompleted,
    isLoading,
    isUpdating,
    location,
    message,
    site,
    siteId,
    siteUsers,
    sites,
    userFilters,
  }
}

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
  createSiteUser: sitesOperations.createSiteUser,
  createSiteUsers: sitesOperations.createSiteUsers,
  fetchSiteUsers: sitesOperations.fetchSiteUsers,
  fetchK8SiteUsers: sitesOperations.fetchK8SiteUsers,
  remindSiteUser: sitesOperations.remindSiteUser,
  remindSiteUsers: sitesOperations.remindSiteUsers,
  resetBulkAddUsers: sitesOperations.resetBulkAddUsers,
  updateSiteUser: sitesOperations.updateSiteUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserManagementContainer)
