import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'

const ACCOUNT_DETAILS = 'Account Details'
const HELP = 'Help'
const LOG_OUT = 'Log out'
const MY_DASHBOARD = 'My Dashboard'
const redirectUrl = domains.PUBLIC_SITE_DOMAIN
const logout = () => {
  const auth0 = Auth0Manager.getAuth0Instance()
  auth0.logout({ returnTo: redirectUrl })
}

export const NAVIGATION_ITEMS = [
  {
    label: MY_DASHBOARD,
    link: domains.LEARN_APP_DOMAIN,
  },
  {
    label: ACCOUNT_DETAILS,
    link: `${domains.APP_DOMAIN}/account-details`,
  },
  {
    label: HELP,
    link: 'https://support.secondstep.org/',
  },
  {
    label: LOG_OUT,
    onClick: logout,
  },
]
