import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Licenses from './component'

class LicensesContainer extends React.Component {
  render() {
    return <Licenses {...this.props} />
  }
}

function mapStateToProps({
  userProfileManager: {
    profile: { id },
  },
}) {
  return {
    userId: id,
  }
}

LicensesContainer.propTypes = {
  userId: PropTypes.number.isRequired,
}

export default connect(mapStateToProps)(LicensesContainer)
