import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from 'components/FormGroup'
import Label from 'components/Label'
import { Select } from './component.styles'

const SelectGroup = ({
  data,
  dataTestId,
  disabled,
  label,
  name,
  onChange,
  renderOptions,
  required,
  warning,
  value,
}) => {
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Select
        data-testid={dataTestId}
        disabled={!data || disabled}
        id={name}
        name={name}
        onChange={onChange}
        required={required}
        type="select"
        value={value}
      >
        <option />
        {data &&
          data.map(
            renderOptions ||
              (val => (
                <option data-testid={`select-value-${val}`} key={val}>
                  {val}
                </option>
              )),
          )}
      </Select>
      {warning && <span>{warning}</span>}
    </FormGroup>
  )
}

SelectGroup.propTypes = {
  data: PropTypes.array,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderOptions: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  warning: PropTypes.string,
}

export default SelectGroup
