import styled from 'styled-components'
import { HeaderFive } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const RosterInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${themeGet('colors.rosterInfoBackground')};
  color: ${themeGet('colors.backgroundBody')};
  padding: 0.625rem 1.25rem 0.75rem;
  margin: 0 1.75rem;
  border: 0.078rem solid ${themeGet('colors.rosterInfoBorder')};
  border-radius: 0.688rem;
`

export const RosterInfoIconWrapper = styled.div`
  display: flex;
  min-width: 2.625rem;
`

export const RosterInfoContent = styled.div`
  margin-left: 1rem;
`

export const RosterInfoHeader = styled(HeaderFive)`
  font-size: 1rem;
  font-weight: bold;
  margin: 0 1.25rem 0 0;
  letter-spacing: 0.5px;
`

export const RosterInfoText = styled.p`
  margin: 0;
`
