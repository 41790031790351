import SSLogo from './assets/logo.svg'
import { domains } from 'config/env'
export { SSLogo }

export const LOGO_LINK = `${domains.LEARN_APP_DOMAIN}`

const pathToTitle = {
  'license-management': 'License Management',
  'user-management': 'User Management',
  'account-details': 'Account Details',
}

// Simply converting the path to the name
export const pathToProduct = pathName => {
  const firstPathPart = pathName.split('/')[1] || ''
  return {
    title: pathToTitle[firstPathPart.toLowerCase()],
  }
}
