import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import thunk from 'redux-thunk'
import loggerMiddleware from './middleware/logger'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  thunk,
  sagaMiddleware,
  routerMiddleware(history),
  apiMiddleware,
]
const enhancers = []

if (process.env.NODE_ENV !== 'production') {
  // add redux console logging
  middleware.push(loggerMiddleware)

  // add redux dev tool
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const rootReducer = createRootReducer(history)
const initialState = {}
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)
export const persistor = persistStore(store)

export default store
