import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { operations as licenseOperations } from 'store/licenseManager'
import { validateEmail } from 'lib/util'
import { EDITABLE_LICENSE_FIELDS } from './data'
import { UNLISTED as UNLISTED_SITE } from 'components/AssignSchool/constants'
import AssignLicense from './component'
import { Skus } from './constants'

class AssignLicenseContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      city: '',
      country: '',
      isAssignmentConfirmed: false,
      isReadyToSubmit: false,
      isTosConfirmed: false,
      region: '',
      siteId: '',
      siteName: '',
    }
  }

  componentDidMount = () => {
    const {
      defaultSiteData: { city, country, region, siteId, siteName },
    } = this.props

    this.setState({
      city,
      country,
      region,
      siteId: siteId + '',
      siteName,
    })
  }

  handleChange = data => {
    const updatedState = {}

    EDITABLE_LICENSE_FIELDS.forEach(prop => {
      if (data.hasOwnProperty(prop)) {
        updatedState[prop] = data[prop]
      }
    })

    // TODO if we aren't allowing adding email, remove this and associated validation functions
    if (data.hasOwnProperty('email')) {
      if (this.validateEmail(data.email, data.confirmEmail)) {
        updatedState.email = data.email
      } else {
        updatedState.email = undefined
      }
    }

    this.setState(updatedState, this.validateReadyToSubmit)
  }

  handleToggle = data => {
    const updatedState = {}

    if (this.state.hasOwnProperty(data.target.name)) {
      updatedState[data.target.name] = !this.state[data.target.name]
    }
    this.setState(updatedState, this.validateReadyToSubmit)
  }

  validateEmail = (email, confirmEmail) => {
    return (
      email && confirmEmail && email === confirmEmail && validateEmail(email)
    )
  }

  validateReadyToSubmit = () => {
    const {
      siteId,
      siteName,
      isAssignmentConfirmed,
      isTosConfirmed,
    } = this.state

    const { license } = this.props
    const { skuCode } = license
    const shouldIgnoreTos =
      skuCode !== Skus.MSPSkuCode && skuCode !== Skus.K8SkuCode
    const isTosValid = shouldIgnoreTos || isTosConfirmed
    const isSiteIdSet = !!siteId && siteId !== 'undefined'
    const isReadyToSubmit =
      isSiteIdSet && !!siteName && isAssignmentConfirmed && isTosValid
    this.setState({ isReadyToSubmit })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { isReadyToSubmit } = this.state
    if (!isReadyToSubmit) return

    this.assignSchoolAndAdmin()
  }

  assignSchoolAndAdmin = async () => {
    const {
      city,
      country,
      region,
      siteId,
      siteName,
      isTosConfirmed,
    } = this.state
    const {
      clearSelectedLicense,
      defaultAdminEmail,
      license,
      sendLicenseAssignment,
    } = this.props

    const useCustomSite = siteId === UNLISTED_SITE

    const licenseAssignmentRequestBase = {
      adminEmail: defaultAdminEmail,
      city,
      country,
      siteName,
      ...(region && { region }),
      sku: license?.product?.sku?.skuValue,
      subscriptionId: license.subscriptionId,
      isTosConfirmed,
    }

    const licenseAssignmentRequest = {
      ...licenseAssignmentRequestBase,
      ...(useCustomSite
        ? {
            siteId: null,
            nsCustomerId: null,
          }
        : {
            nsCustomerId: siteId,
          }),
    }

    await sendLicenseAssignment(licenseAssignmentRequest)
    clearSelectedLicense()
  }

  render() {
    const {
      city,
      country,
      isAssignmentConfirmed,
      isReadyToSubmit,
      isTosConfirmed,
      region,
      siteId,
      siteName,
    } = this.state
    const { clearSelectedLicense, license } = this.props
    const { skuCode } = license.product
    const siteData = { city, country, region, siteId, siteName }
    const isSelaLicense = skuCode === Skus.ADLSkuCode
    const enableTermsOfService =
      skuCode === Skus.MSPSkuCode ||
      skuCode === Skus.K8SkuCode ||
      skuCode === Skus.K8FamilySkuCode ||
      skuCode === Skus.HighSchoolSkuCode ||
      skuCode === Skus.K12SkuCode ||
      skuCode === Skus.K12FamamilySkuCode ||
      isSelaLicense
    return (
      <AssignLicense
        clearSelectedLicense={clearSelectedLicense}
        enableTermsOfService={enableTermsOfService}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        handleToggle={this.handleToggle}
        isAssignmentConfirmed={isAssignmentConfirmed}
        isReadyToSubmit={isReadyToSubmit}
        isSelaLicense={isSelaLicense}
        isTosConfirmed={isTosConfirmed}
        siteData={siteData}
      />
    )
  }
}

AssignLicenseContainer.propTypes = {
  clearSelectedLicense: PropTypes.func.isRequired,
  defaultAdminEmail: PropTypes.string,
  defaultSiteData: PropTypes.shape({
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    siteId: PropTypes.number,
    siteName: PropTypes.string,
  }),
  fetchLicenses: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
  sendLicenseAssignment: PropTypes.func.isRequired,
}

AssignLicenseContainer.defaultProps = {
  defaultSiteData: {
    country: '',
    region: '',
    city: '',
    siteId: '',
    siteName: '',
  },
  defaultAdminValues: {
    email: '',
  },
}

const mapDispatchToProps = {
  sendLicenseAssignment: licenseOperations.sendLicenseAssignment,
}

export default connect(null, mapDispatchToProps)(AssignLicenseContainer)
