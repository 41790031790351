import actions from './actions'
import api from 'lib/api/secondStepApi'
import reportingApi from 'lib/api/reportsApi'
import { actions as notificationActions } from 'store/notificationsManager'

const fetchK8SiteUsers = siteId => {
  return async dispatch => {
    dispatch(actions.fetchingK8SiteUsers())

    try {
      const response = await reportingApi.getSiteUsers(siteId)
      dispatch(
        actions.fetchingK8SiteUsersSuccess(
          siteId,
          // TODO: should this be done in the reducer? Once LEARN-15879 is merged we will have a better idea
          response.data.data.SiteUsers.users,
        ),
      )
    } catch (error) {
      dispatch(actions.fetchingK8SiteUsersError(error))
    }
  }
}

const fetchSites = () => {
  return async dispatch => {
    dispatch(actions.fetchingSites())
    try {
      const response = await api.getSites()
      dispatch(actions.fetchingSitesSuccess(response.data))
    } catch (error) {
      dispatch(actions.fetchingSitesError(error))
    }
  }
}

const fetchSiteUsers = siteId => {
  return async dispatch => {
    dispatch(actions.fetchingSiteUsers())

    try {
      const response = await api.getSiteUsers(siteId)
      dispatch(actions.fetchingSiteUsersSuccess(siteId, response.data))
    } catch (error) {
      dispatch(actions.fetchingSiteUsersError(error))
    }
  }
}

const createSiteUser = (siteUser, sendEmail, selectedSite) => {
  return async dispatch => {
    dispatch(actions.creatingSiteUser())
    try {
      const response = await api.createSiteUser(siteUser, sendEmail)
      const newSiteUser = response.data
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: sendEmail
            ? `${newSiteUser.inviteEmail} was added to ${selectedSite.name} and was sent an email notification.`
            : `${newSiteUser.inviteEmail} was added to ${selectedSite.name}.`,
        }),
      )
      dispatch(actions.creatingSiteUserSuccess(response.data))
    } catch (error) {
      dispatch(
        notificationActions.addNotification({
          color: 'info',
          text: `The user ${siteUser.inviteEmail} is already a member of this site.`,
        }),
      )
      dispatch(actions.creatingSiteUserError(error))
    }
  }
}

const createSiteUsers = (siteId, siteUsers, sendEmail) => {
  return async dispatch => {
    dispatch(actions.creatingSiteUsers())
    try {
      const response = await api.createSiteUsers(siteId, siteUsers, sendEmail)
      const newSiteUsers = response.data.createdItems
      const failedSiteUsers = response.data.failedItems
      const invalidSiteUsers = response.data.invalidItems
      dispatch(
        actions.creatingSiteUsersSuccess(
          siteId,
          newSiteUsers,
          failedSiteUsers,
          invalidSiteUsers,
          sendEmail,
        ),
      )
    } catch (error) {
      dispatch(actions.creatingSiteUsersError(error))
    }
  }
}

const updateSiteUser = (siteUser, notification) => {
  return async dispatch => {
    dispatch(actions.updatingSiteUser())
    const msgParams = {
      role: siteUser.roles[0],
      email: siteUser.inviteEmail,
    }
    try {
      await api.updateSiteUser(siteUser)
      dispatch(actions.updatingSiteUserSuccess(siteUser))
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: notification.successMessage(msgParams),
        }),
      )
    } catch (error) {
      dispatch(actions.updatingSiteUserError(error))
      dispatch(actions.updatingSiteUserSuccess(siteUser))
      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: notification.errorMessage(msgParams),
        }),
      )
    }
  }
}

const resetBulkAddUsers = () => {
  return async dispatch => {
    dispatch(actions.resetBulkAddUsers())
  }
}

const remindSiteUser = siteUser => {
  return async dispatch => {
    dispatch(actions.remindingSiteUsers())

    try {
      await api.remindSiteUser(siteUser)
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: `You've sent a reminder to ${siteUser.inviteEmail}`,
        }),
      )
      dispatch(actions.remindingSiteUserSuccess())
    } catch (error) {
      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: `We weren't able to remind ${siteUser.inviteEmail}. Please try again later.`,
        }),
      )
      dispatch(actions.remindingSiteUserError(error))
    }
  }
}

const remindSiteUsers = (siteId, emailFilters) => {
  return async dispatch => {
    dispatch(actions.remindingSiteUsers())

    try {
      await api.remindSiteUsers(siteId, emailFilters)
      // TODO: Should we be calculating the total affect users, or should the API return the number of emails it sent?
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: `Email notifications sent`,
        }),
      )
      dispatch(actions.remindingSiteUsersSuccess())
    } catch (error) {
      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: `Something went wrong: ${error.message}`,
        }),
      )
      dispatch(actions.remindingSiteUsersError(error))
    }
  }
}

export default {
  fetchK8SiteUsers,
  fetchSites,
  fetchSiteUsers,
  createSiteUser,
  createSiteUsers,
  remindSiteUser,
  remindSiteUsers,
  resetBulkAddUsers,
  updateSiteUser,
}
