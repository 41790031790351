import styled from 'styled-components'

export const NotificationList = styled.div`
  position: relative;
  z-index: 10000;
`

export const NotificationWrapper = styled.div`
  position: fixed;
  width: calc(100% - 4.5rem);
  padding: 0 2.25rem;
  margin-top: 1rem;
`
