import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Badge = styled.span.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  background-color: ${themeGet('colors.lightGray')};
  border-radius: 0.125rem;
  box-sizing: border-box;
  color: ${themeGet('colors.white')};
  cursor: pointer;
  display: inline-block;
  font-family: ${themeGet('fontFamilyBold')};
  font-size: 0.844rem;
  font-weight: 700;
  line-height: 0.844rem;
  padding: 0.211rem 0.338rem;
  margin-right: 0.25rem;
  text-align: center;
`

export default Badge
