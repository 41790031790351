import React from 'react'
import PropTypes from 'prop-types'
import { inputModeType } from 'lib/util'
import RegTrademarkText from '../RegTrademarkText/component'
import {
  Body,
  FaqLink,
  Header,
  RadioField,
  RadioGroup,
  RadioLabel,
  SendNotificationWrapper,
} from './component.styles'
import {
  NOTIFY_ADMINS_TEXT_BODY,
  SECOND_STEP_TEXT,
  NOTIFY_ADMINS_TEXT_HEADER,
  NOTIFY_USER_TEXT_HEADER_SINGLE,
  NOTIFY_USERS_TEXT_HEADER_MULTIPLE,
  NOTIFY_RADIO_LABEL_TRUE_SINGLE,
  NOTIFY_RADIO_LABEL_TRUE_MULTIPLE,
  NOTIFY_RADIO_LABEL_FALSE,
  FAQ_LINK_TEXT,
  FAQ_LINK_URL,
} from './constants'

const AddUserModalSendNotification = ({ inputMode, values }) => {
  const { selectedRole, sendNotification } = values
  const isAdmin = inputMode === inputModeType.single && selectedRole === 'Admin'

  return (
    <SendNotificationWrapper>
      <Header data-testid="send-notification-header">
        {isAdmin ? (
          <>
            <RegTrademarkText fontWeight={'semibold'} text={SECOND_STEP_TEXT} />
            {NOTIFY_ADMINS_TEXT_HEADER}
          </>
        ) : inputMode === inputModeType.single ? (
          NOTIFY_USER_TEXT_HEADER_SINGLE
        ) : (
          NOTIFY_USERS_TEXT_HEADER_MULTIPLE
        )}
      </Header>
      {isAdmin && <Body>{NOTIFY_ADMINS_TEXT_BODY}</Body>}
      {!isAdmin && (
        <>
          <RadioGroup>
            <RadioLabel>
              <RadioField
                checked={sendNotification === 'true'}
                data-testid="send-notification-true"
                name="sendNotification"
                type="radio"
                value="true"
              />
              {inputMode === inputModeType.single
                ? NOTIFY_RADIO_LABEL_TRUE_SINGLE
                : NOTIFY_RADIO_LABEL_TRUE_MULTIPLE}
            </RadioLabel>
            <RadioLabel>
              <RadioField
                checked={sendNotification === 'false'}
                data-testid="send-notification-false"
                name="sendNotification"
                type="radio"
                value="false"
              />
              {NOTIFY_RADIO_LABEL_FALSE}
            </RadioLabel>
          </RadioGroup>
          <div>
            <FaqLink href={FAQ_LINK_URL} target="_blank">
              {' '}
              {FAQ_LINK_TEXT}
            </FaqLink>
          </div>
        </>
      )}
    </SendNotificationWrapper>
  )
}

AddUserModalSendNotification.propTypes = {
  inputMode: PropTypes.string,
  values: PropTypes.shape({
    selectedRole: PropTypes.string,
    sendNotification: PropTypes.string,
  }),
}
export default AddUserModalSendNotification
