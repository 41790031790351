import actions from './actions'
import api from 'lib/api/lmsApi'

const fetchTrainingAttempts = (siteId, license, learners) => {
  return async (dispatch, getState) => {
    const state = getState()
    const program = license.product.skuCode
    const key = `${siteId}.${license.id}.${program}`
    if (state.lmsManager.trainingData?.[key]) return
    dispatch(actions.fetchingTrainingAttempts())
    try {
      const program = license.product.skuCode
      const { data: trainingData } = await api.getTrainingAttempts(
        program,
        learners,
      )
      dispatch(
        actions.fetchingTrainingAttemptsSuccess(
          siteId,
          license.id,
          program,
          trainingData,
        ),
      )
    } catch (error) {
      dispatch(actions.fetchingTrainingAttemptsError(error))
    }
  }
}

export default {
  fetchTrainingAttempts,
}
