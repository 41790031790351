import types from './types'

const fetchingCountries = () => {
  return {
    type: types.FETCHING_COUNTRIES,
  }
}

const fetchingCountriesSuccess = countries => {
  return {
    type: types.FETCHING_COUNTRIES_SUCCESS,
    countries,
  }
}
const fetchingLocationError = (
  error = 'There was a problem loading your location. Please try again later.',
) => {
  return {
    type: types.FETCHING_LOCATION_ERROR,
    error,
  }
}

const fetchingRegions = () => {
  return {
    type: types.FETCHING_REGIONS,
  }
}

const fetchingRegionsSuccess = regions => {
  return {
    type: types.FETCHING_REGIONS_SUCCESS,
    regions,
  }
}

const fetchingCitiesWithRegion = () => {
  return {
    type: types.FETCHING_CITIES_WITH_REGION,
  }
}

const fetchingCitiesWithRegionSuccess = cities => {
  return {
    type: types.FETCHING_CITIES_WITH_REGION_SUCCESS,
    cities,
  }
}

const fetchingCitiesWithoutRegion = () => {
  return {
    type: types.FETCHING_CITIES_WITHOUT_REGION,
  }
}

const fetchingCitiesWithoutRegionSuccess = cities => {
  return {
    type: types.FETCHING_CITIES_WITHOUT_REGION_SUCCESS,
    cities,
  }
}

const fetchingNetSuiteCustomersWithRegion = () => {
  return {
    type: types.FETCHING_NETSUITECUSTOMERS_WITH_REGION,
  }
}

const fetchingNetSuiteCustomersWithRegionSuccess = siteData => {
  return {
    type: types.FETCHING_NETSUITECUSTOMERS_WITH_REGION_SUCCESS,
    siteData,
  }
}

const fetchingNetSuiteCustomersWithoutRegion = () => {
  return {
    type: types.FETCHING_NETSUITECUSTOMERS_WITHOUT_REGION,
  }
}

const fetchingNetSuiteCustomersWithoutRegionSuccess = siteData => {
  return {
    type: types.FETCHING_NETSUITECUSTOMERS_WITHOUT_REGION_SUCCESS,
    siteData,
  }
}

export default {
  fetchingCitiesWithoutRegion,
  fetchingCitiesWithoutRegionSuccess,
  fetchingCitiesWithRegion,
  fetchingCitiesWithRegionSuccess,
  fetchingCountries,
  fetchingCountriesSuccess,
  fetchingLocationError,
  fetchingRegions,
  fetchingRegionsSuccess,
  fetchingNetSuiteCustomersWithoutRegion,
  fetchingNetSuiteCustomersWithoutRegionSuccess,
  fetchingNetSuiteCustomersWithRegion,
  fetchingNetSuiteCustomersWithRegionSuccess,
}
