export default store => next => action => {
  const [prefix, actionType] = action.type.split('/')
  const label = `Action type: ${prefix}.${actionType}`
  const payload = action.payload
  const state = store.getState()[prefix] || store.getState()[`${prefix}Manager`]

  const disable = true
  next(action)
  if (disable) return
  console.groupCollapsed(label)
  console.info('Action payload:', payload)
  console.info('State before:', state)
  console.info(
    'State after:',
    store.getState()[prefix] || store.getState()[`${prefix}Manager`],
  )
  console.groupEnd()
}
