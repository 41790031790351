import React from 'react'
import api from './Api'
import PropTypes from 'prop-types'

export default function withData(WrappedComponent) {
  return class WithData extends React.Component {
    static propTypes = {
      apiEndpoint: PropTypes.string.isRequired,
      onLoadData: PropTypes.func,
    }
    constructor(props) {
      super(props)
      this.state = {
        data: null,
        error: '',
      }
    }

    componentDidMount() {
      if (this.props.apiEndpoint) {
        this.fetchData()
      }
    }

    /* eslint-disable */
    componentDidUpdate(prevProps) {
      if (this.props.apiEndpoint !== prevProps.apiEndpoint) {
        if (this.props.apiEndpoint) {
          this.fetchData()
        } else {
          this.setState({ data: null })
        }
      }
    }
    /* eslint-enable */

    fetchData = async () => {
      this.setState({ data: null, error: '' })
      try {
        const response = await api.get(this.props.apiEndpoint)
        if (response.status === 200) {
          let data = response.data
          if (this.props.onLoadData) data = this.props.onLoadData(data)
          this.setState({ data, error: '' })
        } else {
          this.setState({ data: null, error: response.status })
        }
      } catch (error) {
        this.setState({ data: null, error })
      }
    }

    render() {
      return (
        <WrappedComponent
          data={this.state.data}
          error={this.state.error}
          {...this.props}
        />
      )
    }
  }
}
