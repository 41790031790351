import React from 'react'
import PropTypes from 'prop-types'

import { SITE_PICKER_HEADER } from './constants'
import { StyledFormInputSelect } from './component.styles'
import { SkuCodeToDisplay } from '../../lib/util'

const SitePicker = ({ sites, onSiteChange, selectedSite }) => {
  const disabled = ({ component }) => !!component

  const createLicenseList = site => [
    ...new Set(
      site.licenses
        .map(
          lic =>
            SkuCodeToDisplay[
              !lic.IsExpired && lic.product && lic.product.skuCode
            ] || { productCode: 'N/A', hierarchy: 9999 },
        )
        .sort((a, b) => a.hierarchy - b.hierarchy)
        .map(display => display.productCode),
    ),
  ]

  const formatSite = site => {
    let schoolCount = 0
    sites.forEach(s => {
      if (s.name === site.name) {
        schoolCount++
      }
    })
    const siteHasADuplicate = schoolCount > 1

    if (!siteHasADuplicate) return site.name || 'Your School'

    const licenseList = createLicenseList(site)
    const formattedName = `${site.name || 'Your School'} ${
      licenseList.length > 0 ? '-' : ''
    } ${licenseList.join(', ')}`

    return formattedName
  }

  return (
    <StyledFormInputSelect
      autoComplete={false}
      autofocus={false}
      dataTestId="form-input-select-site"
      disabledKey={disabled}
      label={SITE_PICKER_HEADER}
      name="role"
      onChange={onSiteChange}
      optionKey={formatSite}
      options={sites}
      value={selectedSite}
      valueKey={formatSite}
    />
  )
}

SitePicker.propTypes = {
  onSiteChange: PropTypes.func.isRequired,
  selectedSite: PropTypes.object,
  sites: PropTypes.arrayOf(PropTypes.object),
}

SitePicker.defaultProps = {
  sites: [],
  onSiteChange: () => {},
}

export default SitePicker
