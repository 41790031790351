import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  TextLink,
  Tooltip,
  TooltipContentWrapper,
} from 'secondstep-components'

export const UserActionsWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin-right: 2.188rem;
`

export const UserActionsButton = styled.button`
  display: flex;
  border: 0;
  padding: 0;
  font-size: 1.25rem;
  color: ${themeGet('colors.gray600')};
  background-color: white;
  :hover {
    color: ${themeGet('colors.backgroundBody')};
  }
`
export const UserActionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const UserActionMenu = styled.div`
  position: absolute;
  top: 1.75rem;
  z-index: 1;
  border: 2px solid ${themeGet('colors.mediumGray')};
  border-radius: 5px;
  background-color: ${props =>
    props.useGrayBackground
      ? themeGet('colors.backgroundNeutralGray')
      : 'white'};
  padding: 0 1rem;
`

export const UserActionMenuItem = styled.div`
  border: 0;
  padding: 0.438rem 0rem;
  background-color: white;
  display: flex;
  width: 100%;
  white-space: nowrap;
  :not(:last-child) {
    border-bottom: 1px solid ${themeGet('colors.mediumGray')};
  }
`

export const CantEditRemoveWrapper = styled.div`
  width: 15.063rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  background-color: ${themeGet('colors.backgroundNeutralGray')};
`

export const CantEditRemoveCopy = styled(BodyCopy)`
  line-height: 1.25rem;
`

export const ActionLink = styled(TextLink)`
  transition: none;
  font-family: ${themeGet('fontFamily')};
  color: ${props =>
    props.type === 'remove' ? '#D0021B' : themeGet('colors.darkerDarkBlue')};
  &:not([disabled]):hover {
    font-weight: 600;
    color: ${props =>
      props.type === 'remove'
        ? themeGet('colors.orange')
        : themeGet('colors.darkerDarkBlue')};
  }
`

export const Tip = styled(Tooltip)`
  ${TooltipContentWrapper} {
    white-space: normal;
    left: -132%;
    top: -112%;
    border: 1px solid ${themeGet('colors.tabBorder')};
    background-color: ${themeGet('colors.rosterTooltipBackground')};
    box-shadow: none;
  }
`
