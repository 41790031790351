import React from 'react'
import PropTypes from 'prop-types'
import { ROLE_HEADER } from './constants'
import { inputModeType } from 'lib/util'
import {
  Header,
  RadioGroup,
  RadioRoleField,
  RoleDescriptionUnorderedList,
  RolesGroup,
  RoleLabel,
} from './component.styles'

const AddUserModalSelectRole = ({
  handleRoleSelect,
  inputMode,
  rolesInfo,
  setFieldValue,
  values,
}) => {
  return (
    <RolesGroup aria-labelledby="my-radio-group" role="group">
      <Header id="my-radio-group">{ROLE_HEADER}</Header>
      {rolesInfo.map(roleInfo => {
        const isAdminRoleAndMultipleMode =
          inputMode === inputModeType.bulk && roleInfo.id === 'Admin'
        return (
          <RadioGroup key={roleInfo.id}>
            <RoleLabel disabled={isAdminRoleAndMultipleMode}>
              <RadioRoleField
                checked={values.selectedRole === roleInfo.id}
                dataTestId={`roles-radio-input-${roleInfo.role?.toLowerCase()}`}
                disabled={isAdminRoleAndMultipleMode}
                name="selectedRole"
                onClick={() => handleRoleSelect(setFieldValue, roleInfo.id)}
                type="radio"
                value={roleInfo.id}
              />
              {roleInfo.role}
            </RoleLabel>
            <RoleDescriptionUnorderedList
              isAdminRoleAndMultipleMode={isAdminRoleAndMultipleMode}
            >
              {roleInfo.description.map(description => (
                <li key={`${roleInfo.id}-${description}`}>- {description}</li>
              ))}
            </RoleDescriptionUnorderedList>
          </RadioGroup>
        )
      })}
    </RolesGroup>
  )
}

AddUserModalSelectRole.propTypes = {
  handleRoleSelect: PropTypes.func,
  inputMode: PropTypes.string,
  rolesInfo: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      description: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    emails: PropTypes.string,
    selectedRole: PropTypes.string,
  }),
}

export default AddUserModalSelectRole
