import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  CloseButton,
  DropdownFilter,
  ErrorBoundary,
  Loading,
  SpinnerAnimatedIconMedium,
} from 'secondstep-components'
import SitePicker from 'components/SitePicker'
import SiteSummary from 'components/SiteSummary'
import SiteUserGrid from 'components/SiteUserGrid'
import Notification from 'components/Notification'
import SiteUserAddRemind from 'components/SiteUserAddRemind'
import SiteUserCountInfo from '../../components/SiteUserCountInfo'
import SendInvitesDialog from 'components/SendInvitesDialog'
import AddUserModalForm from 'components/AddUserModalForm'
import AddUserModalSummary from 'components/AddUserModalSummary'
import { domains } from '../../config/env'
import {
  NO_SITES_FOUND_TX,
  REDIRECT_TO_DASHBOARD,
  REDIRECT_TO_LICENSE_MANAGEMENT,
} from './constants'

import {
  AddUserModalContainer,
  LoadingWrapper,
  RemindUsersModal,
  SitePickerContainer,
  SiteUserGridWrapper,
  StyledGearIcon,
  StyledTextLinkDashboard,
  StyledTextLinkLicenseManagement,
  TableContainer,
  TextLinkContainer,
  UserCountFilterWrapper,
  UserInfoWrapper,
} from './component.styles'

const UserManagement = ({
  addBulkUsersResponse,
  allActiveUsersCount,
  className,
  filterSiteUsers,
  handleCancelUserAccess,
  handleCloseAddUsersModal,
  handleCloseSendInvitesModal,
  handleGrantNewRole,
  handleOpenAddUsersModal,
  handleOpenSendInvitesModal,
  handleRemindEmail,
  handleSendInvites,
  handleSubmitAddUsers,
  hasK8License,
  hasSelaLicense,
  hasUnassignedLicenses,
  isAddBulkUsersCompleted,
  isAddUserModalOpen,
  isLoading,
  isSendInvitesModalOpen,
  isUpdating,
  message,
  onSiteChange,
  redirectToLicenseManagement,
  selectedFilterOption,
  selectedSite,
  selectedSiteUsers,
  siteUsers,
  sites,
  userFilterOptions,
  userInviteDialogCounts,
}) => {
  const isReadyToDisplayUsers = !!selectedSite
  const hasSites = sites && sites.length > 0
  const allLicensesExpired = !selectedSite.licenses.some(
    license => !license.isExpired,
  )

  useEffect(() => {
    if (addBulkUsersResponse && isAddBulkUsersCompleted) {
      handleOpenAddUsersModal()
    }
  }, [addBulkUsersResponse, isAddBulkUsersCompleted])

  return (
    <>
      {
        <TextLinkContainer>
          <StyledTextLinkDashboard
            dataTestId="textlink-back-to-dashboard"
            href={`${domains.LEARN_APP_DOMAIN}`}
          >
            {REDIRECT_TO_DASHBOARD}
          </StyledTextLinkDashboard>
          <StyledTextLinkLicenseManagement
            dataTestId="textlink-back-to-license-management"
            onClick={redirectToLicenseManagement}
          >
            <StyledGearIcon />
            {REDIRECT_TO_LICENSE_MANAGEMENT}
          </StyledTextLinkLicenseManagement>
        </TextLinkContainer>
      }
      <ErrorBoundary>
        {isLoading && (
          <Loading dataTestId="loading-user-management" loadingText={message} />
        )}
        {!isLoading && (
          <>
            {!hasSites && (
              <Notification color="danger">{NO_SITES_FOUND_TX}</Notification>
            )}
            {hasSites && userFilterOptions && (
              <>
                <SitePickerContainer>
                  <SitePicker
                    onSiteChange={onSiteChange}
                    selectedSite={selectedSite}
                    sites={sites}
                  />
                </SitePickerContainer>
                <SiteSummary
                  hasUnassignedLicenses={hasUnassignedLicenses}
                  redirectToLicenseManagement={redirectToLicenseManagement}
                  selectedSite={selectedSite}
                  siteUsers={siteUsers}
                />
                <UserInfoWrapper>
                  <UserCountFilterWrapper>
                    <SiteUserCountInfo
                      hasSelaLicense={hasSelaLicense}
                      userCounts={allActiveUsersCount}
                    />
                    <DropdownFilter
                      filterSiteUsers={filterSiteUsers}
                      selectedFilterOption={selectedFilterOption}
                      userFilterOptions={userFilterOptions}
                    />
                  </UserCountFilterWrapper>
                  {!allLicensesExpired && (
                    <SiteUserAddRemind
                      handleOpenAddUsersModal={handleOpenAddUsersModal}
                      handleOpenSendInvitesModal={handleOpenSendInvitesModal}
                      site={selectedSite}
                      userCounts={allActiveUsersCount}
                    />
                  )}
                </UserInfoWrapper>
                <TableContainer>
                  <LoadingWrapper>
                    {isUpdating && <SpinnerAnimatedIconMedium />}
                  </LoadingWrapper>
                  {isReadyToDisplayUsers && (
                    <SiteUserGridWrapper isUpdating={isUpdating}>
                      <SiteUserGrid
                        className={className}
                        displayUserActions={!allLicensesExpired}
                        handleCancelUserAccess={handleCancelUserAccess}
                        handleGrantNewRole={handleGrantNewRole}
                        handleRemindEmail={handleRemindEmail}
                        hasK8License={hasK8License}
                        site={selectedSite}
                        users={selectedSiteUsers}
                      />
                    </SiteUserGridWrapper>
                  )}
                </TableContainer>
              </>
            )}
          </>
        )}

        <AddUserModalContainer
          dataTestId="add-user-content-wrapper"
          isModalOpen={isAddUserModalOpen}
        >
          {isAddBulkUsersCompleted ? (
            <AddUserModalSummary
              data={addBulkUsersResponse}
              onClose={handleCloseAddUsersModal}
            />
          ) : (
            <AddUserModalForm
              handleCloseAddUsersModal={handleCloseAddUsersModal}
              handleOpenAddUsersModal={handleOpenAddUsersModal}
              handleSubmitAddUsers={handleSubmitAddUsers}
              isAddUserModalOpen={isAddUserModalOpen}
              selectedSite={selectedSite}
            />
          )}
        </AddUserModalContainer>

        <RemindUsersModal
          dataTestId="confirm-reminder-modal"
          isModalOpen={isSendInvitesModalOpen}
        >
          <CloseButton
            dataTestId="close-confirm-reminder-modal-button"
            onClick={handleCloseSendInvitesModal}
          />
          <SendInvitesDialog
            onCancel={handleCloseSendInvitesModal}
            onConfirm={handleSendInvites}
            showIcon={false}
            userMetaInfo={userInviteDialogCounts}
          />
        </RemindUsersModal>
      </ErrorBoundary>
    </>
  )
}

UserManagement.propTypes = {
  addBulkUsersResponse: PropTypes.object,
  allActiveUsersCount: PropTypes.object.isRequired,
  className: PropTypes.string,
  filterSiteUsers: PropTypes.func.isRequired,
  handleCancelUserAccess: PropTypes.func.isRequired,
  handleCloseAddUsersModal: PropTypes.func.isRequired,
  handleCloseSendInvitesModal: PropTypes.func.isRequired,
  handleGrantNewRole: PropTypes.func.isRequired,
  handleOpenAddUsersModal: PropTypes.func.isRequired,
  handleOpenSendInvitesModal: PropTypes.func.isRequired,
  handleRemindEmail: PropTypes.func.isRequired,
  handleSendInvites: PropTypes.func.isRequired,
  handleSubmitAddUsers: PropTypes.func.isRequired,
  hasK8License: PropTypes.bool.isRequired,
  hasSelaLicense: PropTypes.bool.isRequired,
  hasUnassignedLicenses: PropTypes.bool.isRequired,
  isAddBulkUsersCompleted: PropTypes.bool,
  isAddUserModalOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSendInvitesModalOpen: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onSiteChange: PropTypes.func.isRequired,
  redirectToDashboard: PropTypes.func.isRequired,
  redirectToLicenseManagement: PropTypes.func.isRequired,
  selectedFilterOption: PropTypes.object.isRequired,
  selectedSite: PropTypes.object,
  selectedSiteUsers: PropTypes.arrayOf(Object),
  siteUsers: PropTypes.arrayOf(Object),
  sites: PropTypes.arrayOf(Object),
  userFilterOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  userInviteDialogCounts: PropTypes.object.isRequired,
}

export default UserManagement
