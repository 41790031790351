import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const EmailErrorEmail = styled.pre`
  display: inline-block;
  font-weight: bold;
  margin: 0;
`

export const EmailErrorListWrapper = styled.ul`
  max-height: 18.75rem;
  overflow-y: auto;
`

export const EmailErrorListItem = styled.li`
  margin-bottom: 0.313rem;
  font-family: ${themeGet('fontFamily')};
`
export const EmailErrorListItemError = styled.li`
  color: ${themeGet('colors.error')};
  font-family: ${themeGet('fontFamily')};
`
