export default {
  FETCHING_LICENSES: 'license/FETCHING_LICENSES',
  FETCHING_LICENSES_SUCCESS: 'license/FETCHING_LICENSES_SUCCESS',
  FETCHING_LICENSES_ERROR: 'license/FETCHING_LICENSES_ERROR',
  SENDING_LICENSE_USER: 'license/SENDING_LICENSE_USER',
  SENDING_LICENSE_USER_SUCCESS: 'license/SENDING_LICENSE_USER_SUCCESS',
  SENDING_LICENSE_USER_ERROR: 'license/SENDING_LICENSE_USER_ERROR',
  SENDING_LICENSE_USER_BULK: 'license/SENDING_LICENSE_USER_BULK',
  SENDING_LICENSE_USER_BULK_SUCCESS:
    'license/SENDING_LICENSE_USER_BULK_SUCCESS',
  SENDING_LICENSE_USER_BULK_ERROR: 'license/SENDING_LICENSE_USER_BULK_ERROR',
  SENDING_LICENSE_ASSIGNMENT: 'license/SENDING_LICENSE_ASSIGNMENT',
  SENDING_LICENSE_ASSIGNMENT_SUCCESS:
    'license/SENDING_LICENSE_ASSIGNMENT_SUCCESS',
  SENDING_LICENSE_ASSIGNMENT_ERROR: 'license/SENDING_LICENSE_ASSIGNMENT_ERROR',
  SET_SELECTED_USER_LICENSE: 'license/SET_SELECTED_USER_LICENSE',
  // UPDATE_USER_LICENSE: 'license/UPDATE_USER_LICENSE',
  UPDATE_USER_LICENSE_ADD_USER: 'license/UPDATE_USER_LICENSE_ADD_USER',
  UPDATE_USER_LICENSE_ADD_USERS: 'license/UPDATE_USER_LICENSE_ADD_USERS',
  UPDATE_USER_LICENSE_REMOVE_USER: 'license/UPDATE_USER_LICENSE_REMOVE_USER',
  GRANTING_NEW_ROLE_LICENSE_USER: 'license/GRANTING_NEW_ROLE_LICENSE_USER',
  GRANTING_NEW_ROLE_LICENSE_USER_SUCCESS:
    'license/GRANTING_NEW_ROLE_LICENSE_USER_SUCCESS',
  GRANTING_NEW_ROLE_LICENSE_USER_ERROR:
    'license/GRANTING_NEW_ROLE_LICENSE_USER_ERROR',
  RESET_BULK_ADD_USERS: 'license/RESET_BULK_ADD_USERS',
  START_ADD_USER: 'license/START_ADD__USER',
  END_ADD_USERS: 'license/END_ADD_USERS',
  SWITCH_ADD_USER_MODE: 'license/SWITCH_ADD_USER_MODE',
}
