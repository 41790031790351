import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, MenuItemContainer } from './component.styles'

export default class GlobalNavItem extends Component {
  static propTypes = {
    index: PropTypes.number,
    label: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
  }

  render() {
    const { label, index, onClick } = this.props
    return (
      <MenuItemContainer onClick={onClick}>
        <MenuItem
          data-testid={`global-nav-profile-dropdown-item-${index}-${label}`}
        >
          {label}
        </MenuItem>
      </MenuItemContainer>
    )
  }
}
