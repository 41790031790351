import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import UserActions from './component'
import {
  CANCEL_PENDING_INVITE_TEXT,
  CANT_BE_REMOVED_TEXT,
  REMOVE_USER_TEXT,
  SEND_REMINDER_TEXT,
  CHANGE_TO_TEXT,
  ROSTER_USER_CANT_BE_REMOVED_TEXT,
  ROSTER_INVITE_CANT_BE_REMOVED_TEXT,
} from './constants'
import { InvitationStatus, AccessLevel } from 'lib/util'

export class UserActionsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { areActionsOpen: false }
  }
  toggleActions = () => {
    this.setState({ areActionsOpen: !this.state.areActionsOpen })
  }

  // works with onClickOutside library
  handleClickOutside = () => {
    if (this.state.areActionsOpen) {
      this.setState({
        areActionsOpen: false,
      })
    }
  }

  removeUser = () => {
    const { user, handleCancelUserAccess } = this.props
    handleCancelUserAccess(user)
    this.setState({ areActionsOpen: false })
  }

  remindUser = () => {
    const { user, handleRemindEmail } = this.props
    handleRemindEmail(user)
    this.setState({ areActionsOpen: false })
  }

  render() {
    const {
      applicableRoles,
      currentUserId,
      handleGrantNewRole,
      user,
      id: rowId,
    } = this.props

    const isCurrentUser = currentUserId === user.userId
    const currentRole = user.roles[0]
    const cantRemoveEditText = CANT_BE_REMOVED_TEXT.replace(
      /ROLE/g,
      AccessLevel[currentRole]?.friendlyName,
    )
    const inactiveRoles = applicableRoles.filter(
      applicableRole =>
        applicableRole?.role?.toLowerCase() !== currentRole?.toLowerCase(),
    )

    const isActive = user.status === InvitationStatus.Active
    const isInvited = user.status === InvitationStatus.Invited
    let actions = []
    if (isActive || isInvited) {
      actions = inactiveRoles.map(inactiveRole => {
        const text = `${CHANGE_TO_TEXT} ${
          AccessLevel[inactiveRole?.role]?.friendlyName
        }`

        return {
          text,
          handler: () => {
            handleGrantNewRole(
              {
                ...user,
                sendEmail: true,
              },
              inactiveRole?.role,
            )
            this.setState({ areActionsOpen: false })
          },
          type: `grant-${inactiveRole?.role?.toLowerCase()}`,
        }
      })
    }

    actions.push({
      text: isActive ? REMOVE_USER_TEXT : CANCEL_PENDING_INVITE_TEXT,
      tooltipText: isActive
        ? ROSTER_USER_CANT_BE_REMOVED_TEXT
        : ROSTER_INVITE_CANT_BE_REMOVED_TEXT,
      handler: this.removeUser,
      type: 'remove',
    })

    if (isInvited) {
      actions.unshift({
        text: SEND_REMINDER_TEXT,
        handler: this.remindUser,
        type: 'remind',
      })
    }

    return (
      <UserActions
        actions={actions}
        areActionsOpen={this.state.areActionsOpen}
        cantRemoveEditText={cantRemoveEditText}
        isCurrentUser={isCurrentUser}
        isRostered={user.isRostered}
        rowId={rowId}
        toggleActions={this.toggleActions}
      />
    )
  }
}

UserActionsContainer.propTypes = {
  applicableRoles: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  handleCancelUserAccess: PropTypes.func.isRequired,
  handleGrantNewRole: PropTypes.func.isRequired,
  handleRemindEmail: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired, // this is a row id generated by formik
  user: PropTypes.object.isRequired,
}

export default onClickOutside(UserActionsContainer)
