export default {
  FETCHING_CITIES_WITHOUT_REGION:
    'location/FETCHING_CITIES_WITHOUT_REGION',
  FETCHING_CITIES_WITHOUT_REGION_SUCCESS:
    'location/FETCHING_CITIES_WITHOUT_REGION_SUCCESS',
  FETCHING_CITIES_WITH_REGION: 'location/FETCHING_CITIES_WITH_REGION',
  FETCHING_CITIES_WITH_REGION_SUCCESS:
    'location/FETCHING_CITIES_WITH_REGION_SUCCESS',
  FETCHING_COUNTRIES: 'location/FETCHING_COUNTRIES',
  FETCHING_COUNTRIES_SUCCESS: 'location/FETCHING_COUNTRIES_SUCCESS',
  FETCHING_LOCATION_ERROR: 'location/FETCHING_LOCATION_ERROR',
  FETCHING_REGIONS: 'location/FETCHING_REGIONS',
  FETCHING_REGIONS_SUCCESS: 'location/FETCHING_REGIONS_SUCCESS',
  FETCHING_NETSUITECUSTOMERS_WITH_REGION: 'location/FETCHING_NETSUITECUSTOMERS_WITH_REGIONS',
  FETCHING_NETSUITECUSTOMERS_WITH_REGION_SUCCESS: 'location/FETCHING_NETSUITECUSTOMERS_WITH_REGION_SUCCESS',
  FETCHING_NETSUITECUSTOMERS_WITHOUT_REGION: 'location/FETCHING_NETSUITECUSTOMERS_WITHOUT_REGIONS',
  FETCHING_NETSUITECUSTOMERS_WITHOUT_REGION_SUCCESS:
    'location/FETCHING_NETSUITECUSTOMERS_WITHOUT_REGION_SUCCESS',
}
