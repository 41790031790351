import styled from 'styled-components'
import { Box } from 'grommet'
import { themeGet } from 'styled-system'
import { ContainedButton, OutlinedButton } from 'secondstep-components'

export const AssignLicenseButtonBox = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 344px;
  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const AssignLicenseOutlinedButton = styled(OutlinedButton)`
  width: 162px;
  &:not([disabled]):hover {
    background: ${themeGet('colors.buttonHover')};
    border-color: ${themeGet('colors.buttonHover')};
  }
`

export const AssignLicenseContainedButton = styled(ContainedButton)`
  width: 162px;
  &:not([disabled]):hover {
    background: ${themeGet('colors.buttonHover')};
    border-color: ${themeGet('colors.buttonHover')};
  }
`
