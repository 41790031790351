import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ContainedButton, CloseButton } from 'secondstep-components'

export const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const LeftWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 59%;
  border-radius: 0.3125rem;
  background-color: ${themeGet('colors.backgroundModalGray')};
  width: 26.5rem;
  padding: 2.125rem 1.75rem 1.375rem 1.75rem;
  ${themeGet('breakpoints.tablet')} {
    width: auto;
  }
`

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 41%;
  box-sizing: border-box;
  padding: 1.9375rem 1.75rem 1.8125rem 1.75rem;
  ${themeGet('breakpoints.tablet')} {
    margin-top: 0.875rem;
    padding-left: 0;

    p[label='Administrator'] {
      margin-bottom: 1.3125rem;
      padding-left: 1.5625rem;
    }

    p[label='Leader Team'] {
      margin-bottom: 0.5rem;
      padding-left: 1.5625rem;
    }

    p[label='Teacher'] {
      margin-bottom: 0.5rem;
      padding-left: 1.5625rem;
    }
  }
`

export const StyledCloseButton = styled(CloseButton)`
  & svg {
    color: ${themeGet('colors.blue')};
    height: 0.75rem;
    width: 0.75rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  min-height: 2.6875rem;

  ${themeGet('breakpoints.tablet')} {
    justify-content: center;
    flex-direction: row;
  }
`

export const AddSingleUserButton = styled(ContainedButton)`
  margin-left: 1.25rem;
  padding: 0.5625rem 0.8125rem 0.375rem;
  width: 7.4375rem;
  height: 2.6875rem;

  &:not([disabled]):hover {
    background-color: ${themeGet('colors.secondStepBlue')};
    border-color: ${themeGet('colors.secondStepBlue')};
  }

  ${themeGet('breakpoints.tablet')} {
    margin-left: 0.75rem;
    padding: 0.125rem 2rem 0 2rem;
  }

   &:focus {
    outline-color: ${themeGet('colors.darkBlue')};
    outline-style: dashed;
    outline-width: 2.5px;
    outline-offset: 5px;
  }
`

export const AddMultipleUsersButton = styled(AddSingleUserButton)`
  ${themeGet('breakpoints.tablet')} {
    margin-left: 0.75rem;
    padding: 0.125rem 1.7rem 0 1.7rem;
  }

   &:focus {
    outline-color: ${themeGet('colors.darkBlue')};
    outline-style: dashed;
    outline-width: 2.5px;
    outline-offset: 5px;
  }
`

export const CancelButton = styled(ContainedButton)`
  background-color: transparent;
  border-color: ${themeGet('colors.lightGray')};
  color: ${themeGet('colors.darkGray')};
  max-width: 9.375rem;
  padding: 0.5625rem 0.8125rem 0.375rem;
  width: 6.875rem;

  &:not([disabled]):hover {
    background-color: ${themeGet('colors.darkBlue')};
    border-color: ${themeGet('colors.darkBlue')};
    color: ${themeGet('colors.white')};
  }

  ${themeGet('breakpoints.tablet')} {
    padding: 0.125rem 2.4rem 0 2.4rem;
    width: 8.75rem;
  }

   &:focus {
    outline-color: ${themeGet('colors.darkBlue')};
    outline-style: dashed;
    outline-width: 2.5px;
    outline-offset: 5px;
  }
`
