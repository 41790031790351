import types from './types'

export const initialState = {
  error: null,
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_COUNTRIES: {
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    }

    case types.FETCHING_COUNTRIES_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        countries: action.countries,
      }
    }

    case types.FETCHING_REGIONS: {
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    }

    case types.FETCHING_REGIONS_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        regions: action.regions,
      }
    }

    case types.FETCHING_CITIES_WITH_REGION: {
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    }

    case types.FETCHING_CITIES_WITH_REGION_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        cities: action.cities,
      }
    }

    case types.FETCHING_CITIES_WITHOUT_REGION: {
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    }

    case types.FETCHING_CITIES_WITHOUT_REGION_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        cities: action.cities,
      }
    }

    case types.FETCHING_NETSUITECUSTOMERS_WITH_REGION: {
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    }

    case types.FETCHING_NETSUITECUSTOMERS_WITH_REGION_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        siteData: action.siteData,
      }
    }

    case types.FETCHING_NETSUITECUSTOMERS_WITHOUT_REGION: {
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    }

    case types.FETCHING_NETSUITECUSTOMERS_WITHOUT_REGION_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        siteData: action.siteData,
      }
    }

    case types.FETCHING_LOCATION_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }
    }

    default:
      return state
  }
}
