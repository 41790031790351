import isEmpty from 'lodash/isEmpty'
import { UserAnalytics } from '@cfc/analytics'
import { fetchUserAccess } from 'auth/stores/access/operations'
import { fetchUserProfile } from 'auth/stores/profile/operations'
import { setCookie } from 'lib/util'
import { FlagsSingleton } from 'flags'
import { domains } from 'config/env'

// ============= AUTH UPDATES ================
export const handleAuthUpdate = ({ auth0, dispatch }) => {
  if (!auth0.isLoading && !auth0.isAuthenticated && !auth0.error) {
    const pathname =
      window.location.pathname + window.location.search + window.location.hash
    setCookie('pathname', pathname)
    auth0.loginWithRedirect()
  } else if (auth0.isAuthenticated) {
    fetchUserAccess(dispatch, `${domains.SECONDSTEP_API}/`)
    fetchUserProfile(dispatch, `${domains.SECONDSTEP_API}/`)
  }
}
// ========== SITES ME ==========
export const handleSitesMeFetch = (auth0, fetchSites) => {
  if (auth0.isAuthenticated) {
    fetchSites()
  }
}

// ============= Launch DARKLY USER CONTEXT ================
export const handleUpdateUserContext = async ({
  profile,
  setContextHasBeenCalled,
  setLoadingUserContext,
}) => {
  setContextHasBeenCalled(true)
  const { displayName, email, roles } = profile || {}
  const updatedUserConfigForLaunchDarkly = {
    userEmail: email,
    roles,
    name: displayName,
  }
  setLoadingUserContext(true)
  try {
    await FlagsSingleton.updateUserContext(updatedUserConfigForLaunchDarkly)
  } catch (e) {
    // Failing silently and we just serve a false variation
    console.error(e)
  }
  setLoadingUserContext(false)
}
// ============= USER ANALYTICS/PENDO SETUP ================
export const handleUserAnalyticsSetup = (profile, sites) => {
  if (!isEmpty(profile) && sites !== null) {
    UserAnalytics.initialize({
      email: profile.email,
      userId: profile.id,
      profile,
      sites,
    })
  }
}
