import { ATTEMPT_STATUS, DATE_FORMAT, NO_DATA_PLACEHOLDER } from './constants'
import { displayDate } from 'lib/util'

const formatAttemptData = (siteUser, attempt) => {
  const { inviteEmail, title, firstName, lastName } = siteUser || {}
  const { done_at, status, updated_at } = attempt || {}
  const formattedCertificateDate =
    (done_at && displayDate(done_at, DATE_FORMAT)) || NO_DATA_PLACEHOLDER
  const formattedName =
    firstName && lastName ? `${firstName} ${lastName}` : NO_DATA_PLACEHOLDER
  const formattedStatus =
    (status && ATTEMPT_STATUS[status]) || NO_DATA_PLACEHOLDER
  const formattedTitle = title || NO_DATA_PLACEHOLDER
  const formattedUpdatedAt =
    (updated_at && displayDate(updated_at, DATE_FORMAT)) || NO_DATA_PLACEHOLDER

  const formattedData = {
    certificateDate: formattedCertificateDate,
    email: inviteEmail,
    name: formattedName,
    status: formattedStatus,
    title: formattedTitle,
    updatedAt: formattedUpdatedAt,
  }

  return formattedData
}

const createTrainingDownloadData = (
  attempts = [],
  licenseId,
  program,
  siteUsers = [],
  siteName,
) => {
  const fileDate = displayDate(Date.now(), 'LLL')
  const activeSiteName = siteName || ''
  const filename = `${activeSiteName} ${program} Training Report for license id ${licenseId ||
    NO_DATA_PLACEHOLDER} as of ${fileDate}.csv`.trim()

  const formattedAttemptsData = siteUsers
    .filter(su => su.isActive)
    .map(siteUser => {
      const attempt = attempts.find(attempt =>
        new RegExp(attempt?.learner, 'i').test(siteUser.inviteEmail),
      )
      return formatAttemptData(siteUser, attempt?.attempt)
    })

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Job Title', key: 'title' },
    { label: 'Training Status', key: 'status' },
    { label: 'Last Updated', key: 'updatedAt' },
    { label: 'Certificate Date', key: 'certificateDate' },
  ]
  const trainingDownloadData = {
    data: formattedAttemptsData,
    headers,
    filename,
  }
  return trainingDownloadData
}

export default {
  createTrainingDownloadData,
}
