import React from 'react'
import {
  RosterInfoWrapper,
  RosterInfoHeader,
  RosterInfoText,
  RosterInfoContent,
  RosterInfoIconWrapper,
} from './component.styles'
import {
  ROSTERED_LICENSE_INFO_HEADER_TEXT,
  ROSTERED_LICENSE_INFO_BODY_TEXT,
} from './constants'
import InfoIcon from './assets/InfoIcon'

const RosteredLicenseInfo = () => {
  return (
    <RosterInfoWrapper>
      <RosterInfoIconWrapper>
        <InfoIcon />
      </RosterInfoIconWrapper>
      <RosterInfoContent>
        <RosterInfoHeader>{ROSTERED_LICENSE_INFO_HEADER_TEXT}</RosterInfoHeader>
        <RosterInfoText>{ROSTERED_LICENSE_INFO_BODY_TEXT}</RosterInfoText>
      </RosterInfoContent>
    </RosterInfoWrapper>
  )
}

export default RosteredLicenseInfo
