import styled from 'styled-components'
import { Box } from 'grommet'

export const LicenseListsUnassignedText = styled(Box)`
  margin-top: 2rem;
`

export const LicenseListsExpiredText = styled(Box)`
  margin-top: 2rem;
`
