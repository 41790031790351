import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { findTrainingLicenseIdAndKey } from './data.js'
import {
  operations as lmsOperations,
  selectors as lmsSelectors,
} from 'store/lmsManager'
import SiteSummary from './component'

export const SiteSummaryContainer = props => {
  const {
    bpuTrainingDownloadData,
    cpuTrainingDownloadData,
    fetchTrainingAttempts,
    hasUnassignedLicenses,
    redirectToLicenseManagement,
    selectedSite,
    siteUsers,
  } = props

  useEffect(() => {
    if (siteUsers && selectedSite) {
      const emails = siteUsers.reduce(
        (acc, selectedSiteUser) =>
          selectedSiteUser.isActive
            ? acc.concat(selectedSiteUser.inviteEmail)
            : acc,
        [],
      )
      selectedSite.licenses.forEach(license => {
        if (
          license.product.skuCode === 'BPU' ||
          license.product.skuCode === 'CPU'
        ) {
          fetchTrainingAttempts(selectedSite.id, license, emails)
        }
      })
    }
  }, [siteUsers, selectedSite])

  return (
    <SiteSummary
      bpuTrainingDownloadData={bpuTrainingDownloadData}
      cpuTrainingDownloadData={cpuTrainingDownloadData}
      hasUnassignedLicenses={hasUnassignedLicenses}
      redirectToLicenseManagement={redirectToLicenseManagement}
      selectedSite={selectedSite}
    />
  )
}

SiteSummaryContainer.propTypes = {
  bpuTrainingDownloadData: PropTypes.object,
  cpuTrainingDownloadData: PropTypes.object,
  fetchTrainingAttempts: PropTypes.func,
  hasUnassignedLicenses: PropTypes.bool,
  redirectToLicenseManagement: PropTypes.func,
  selectedSite: PropTypes.object,
  siteUsers: PropTypes.array,
}

const mapStateToProps = ({ lmsManager }, ownProps) => {
  const { selectedSite, siteUsers } = ownProps || {}
  const { trainingData } = lmsManager || {}

  const {
    licenseId: bpuLicenseId,
    dataKey: bpuTrainingDataKey,
  } = findTrainingLicenseIdAndKey(selectedSite, 'BPU')
  const {
    licenseId: cpuLicenseId,
    dataKey: cpuTrainingDataKey,
  } = findTrainingLicenseIdAndKey(selectedSite, 'CPU')
  const bpuTrainingData = trainingData?.[bpuTrainingDataKey]
  const cpuTrainingData = trainingData?.[cpuTrainingDataKey]
  let bpuTrainingDownloadData = {}
  let cpuTrainingDownloadData = {}
  if (bpuTrainingData) {
    bpuTrainingDownloadData = lmsSelectors.createTrainingDownloadData(
      bpuTrainingData,
      bpuLicenseId,
      'BPU',
      siteUsers,
      selectedSite.name,
    )
  }
  if (cpuTrainingData) {
    cpuTrainingDownloadData = lmsSelectors.createTrainingDownloadData(
      cpuTrainingData,
      cpuLicenseId,
      'CPU',
      siteUsers,
      selectedSite.name,
    )
  }

  return {
    bpuTrainingDownloadData,
    cpuTrainingDownloadData,
    siteUsers,
  }
}

const mapDispatchToProps = {
  deleteLessonProgressReport: lmsOperations.deleteLessonProgressReport,
  deleteTrainingAttempts: lmsOperations.deleteTrainingAttempts,
  fetchTrainingAttempts: lmsOperations.fetchTrainingAttempts,
  fetchLessonProgress: lmsOperations.fetchLessonProgress,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteSummaryContainer)
