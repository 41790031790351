import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ContainedButton, HeaderTwo, BodyCopy } from 'secondstep-components'

export const RootWrapper = styled.div`
  padding: 2rem 2.25rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 29.688rem;
  box-sizing: border-box;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2.25rem;
  min-height: 2.6875rem;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const CancelButton = styled(ContainedButton)`
  background-color: transparent;
  color: ${themeGet('colors.blue')};
  min-width: 8rem;
  padding: 0.25rem 2.57rem 0 2.57rem;
  &:not([disabled]):hover {
    border-color: ${themeGet('colors.blue')};
    background-color: ${themeGet('colors.blue')};
    color: ${themeGet('colors.white')};
  }
`

export const ConfirmButton = styled(ContainedButton)`
  margin-left: 1rem;
  min-width: 8rem;
  padding: 0.25rem 2.25rem 0 2.25rem;
  &:not([disabled]):hover {
    background-color: ${themeGet('colors.secondStepBlue')};
    border-color: ${themeGet('colors.secondStepBlue')};
  }
`

export const Header = styled(HeaderTwo)`
  text-align: center;
  font-size: 1.625rem;
  font-family: ${themeGet('fontFamilyHeadingBold')};
  color: ${themeGet('colors.secondStepBlue')};
`
export const Paragraph = styled(BodyCopy)`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.438rem;
  font-family: ${themeGet('fontFamilySemibold')};
  color: ${themeGet('colors.darkGray')};
  margin-top: 1.25rem;
`
