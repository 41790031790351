import React from 'react'
import PropTypes from 'prop-types'
import {
  CANCEL_BUTTON_TX,
  ADD_USER_TX_BUTTON,
  ADD_USERS_TX_BUTTON,
} from './constants'
import AddUserModalInputText from '../AddUserModalInputText'
import {
  AddMultipleUsersButton,
  AddSingleUserButton,
  ButtonsContainer,
  CancelButton,
  StyledCloseButton,
  LeftWrapper,
  MainWrapper,
  RightWrapper,
} from './component.styles'
import { inputModeType } from 'lib/util'
import AddUserModalSendNotification from '../AddUserSendNotification/component'
import AddUserModalSelectRole from '../AddUserModalSelectRole/component'

const AddUserModalForm = ({
  errors,
  handleCloseModal,
  handleInputModeToggle,
  handleRoleSelect,
  inputMode,
  isSubmitting,
  rolesInfo,
  setFieldValue,
  setSubmitClicked,
  values,
}) => {
  const addUsersButtonText =
    inputMode === inputModeType.single
      ? ADD_USER_TX_BUTTON
      : ADD_USERS_TX_BUTTON

  const AddUserButton =
    inputMode === inputModeType.single
      ? AddSingleUserButton
      : AddMultipleUsersButton

  return (
    <MainWrapper>
      <StyledCloseButton
        dataTestId="modal-form-close-button"
        onClick={handleCloseModal}
      />
      <LeftWrapper>
        <AddUserModalInputText
          errors={errors}
          handleInputModeToggle={handleInputModeToggle}
          inputMode={inputMode}
          values={values}
        />
        <AddUserModalSendNotification inputMode={inputMode} values={values} />
      </LeftWrapper>
      <RightWrapper>
        <AddUserModalSelectRole
          handleRoleSelect={handleRoleSelect}
          inputMode={inputMode}
          rolesInfo={rolesInfo}
          setFieldValue={setFieldValue}
          values={values}
        />
        <ButtonsContainer>
          <CancelButton
            dataTestId="modal-form-cancel-button"
            onClick={handleCloseModal}
          >
            {CANCEL_BUTTON_TX}
          </CancelButton>
          <AddUserButton
            dataTestId="modal-form-add-user-button"
            disabled={isSubmitting}
            onClick={() => setSubmitClicked(true)}
            type="submit"
          >
            {addUsersButtonText}
          </AddUserButton>
        </ButtonsContainer>
      </RightWrapper>
    </MainWrapper>
  )
}

AddUserModalForm.propTypes = {
  errors: PropTypes.object,
  handleCloseModal: PropTypes.func,
  handleInputModeToggle: PropTypes.func,
  handleRoleSelect: PropTypes.func,
  inputMode: PropTypes.string,
  isSubmitting: PropTypes.bool,
  rolesInfo: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      description: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  setFieldValue: PropTypes.func,
  setSubmitClicked: PropTypes.func,
  values: PropTypes.shape({
    emails: PropTypes.string,
    selectedRole: PropTypes.string,
    sendNotification: PropTypes.string,
  }),
}

export default AddUserModalForm
