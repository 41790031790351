import styled from 'styled-components'
import { Modal, ModalContentWrapper } from 'secondstep-components'

export const LUMModal = styled(Modal)`
  .Overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  ${ModalContentWrapper} {
    width: fit-content;
  }
`
