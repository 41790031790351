import React from 'react'
import PropTypes from 'prop-types'
import { HeaderFive, TabButton, TabSet } from 'secondstep-components'
import Badge from 'components/Badge'
import Licenses from 'components/Licenses'
import LicensesUnassigned from 'components/LicensesUnassigned'
import LicensesExpired from 'components/LicensesExpired'
import { withRouter } from 'react-router-dom'

import {
  LICENSE_LISTS_ASSIGNED_TEXT,
  LICENSE_LISTS_ASSIGNED_TAB_TEXT,
  LICENSE_LISTS_EXPIRED_TEXT,
  LICENSE_LISTS_EXPIRED_TAB_TEXT,
  LICENSE_LISTS_UNASSIGNED_TEXT,
  LICENSE_LISTS_UNASSIGNED_TAB_TEXT,
} from './constants'
import { paths } from 'components/LUMRouter/paths'

import {
  LicenseListsUnassignedText,
  LicenseListsExpiredText,
} from './component.styles'

const Tabs = {
  Unassigned: 1,
  Assigned: 2,
  Expired: 3,
}

const TabUrls = {
  [Tabs.Unassigned]: paths.UNASSIGNED_LICENSES,
  [Tabs.Assigned]: paths.ASSIGNED_LICENSES,
  [Tabs.Expired]: paths.EXPIRED_LICENSES,
}

const UrlsToTabs = {
  [paths.BASE_LICENSE_MANAGEMENT]: Tabs.Unassigned,
  [paths.UNASSIGNED_LICENSES]: Tabs.Unassigned,
  [paths.ASSIGNED_LICENSES]: Tabs.Assigned,
  [paths.EXPIRED_LICENSES]: Tabs.Expired,
}

class LicenseLists extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: Tabs.Unassigned,
    }
  }

  componentDidMount() {
    const { licensesUnassigned } = this.props
    const pathName = window.location.pathname

    if (licensesUnassigned?.length === 0) {
      this.setCurrentTab(Tabs.Assigned)
    }
    // if there is no any licensesUnassigned, then Assigned Licenses tab should be selected.
    this.setCurrentTab(UrlsToTabs[pathName])
  }

  setCurrentTab = tab => {
    if (this.state.activeTab !== tab) {
      this.props.history.push(TabUrls[tab])
      this.setState({ activeTab: tab })
    }
  }
  render() {
    const {
      licenses,
      licensesExpired,
      licensesUnassigned,
      onSelectAssign,
      onSelectLicense,
      onSelectUserManagement,
    } = this.props

    const assignedTabLabel = (
      <HeaderFive>
        {LICENSE_LISTS_ASSIGNED_TAB_TEXT}
        <Badge color="default" data-testid="badge-licenses">
          {licenses?.length}
        </Badge>
      </HeaderFive>
    )

    const unassignedTabLabel = (
      <HeaderFive>
        {LICENSE_LISTS_UNASSIGNED_TAB_TEXT}
        <Badge color="default" data-testid="badge-licenses-unassigned">
          {licensesUnassigned?.length}
        </Badge>
      </HeaderFive>
    )

    const expiredTabLabel = (
      <HeaderFive>
        {LICENSE_LISTS_EXPIRED_TAB_TEXT}
        <Badge color="default" data-testid="badge-licenses-expired">
          {licensesExpired?.length}
        </Badge>
      </HeaderFive>
    )

    const { activeTab } = this.state

    return (
      <>
        <TabSet dataTestid="tabset-licenses">
          <TabButton
            dataTestId="tabbutton-licenses-unassigned"
            isActive={activeTab === Tabs.Unassigned}
            label={unassignedTabLabel}
            onClick={() => this.setCurrentTab(Tabs.Unassigned)}
          />
          <TabButton
            dataTestId="tabbutton-licenses-assigned"
            isActive={activeTab === Tabs.Assigned}
            label={assignedTabLabel}
            onClick={() => this.setCurrentTab(Tabs.Assigned)}
          />
          {licensesExpired?.length > 0 && (
            <TabButton
              dataTestId="tabbutton-licenses-expired"
              isActive={activeTab === Tabs.Expired}
              label={expiredTabLabel}
              onClick={() => this.setCurrentTab(Tabs.Expired)}
            />
          )}
        </TabSet>

        {activeTab === Tabs.Assigned && (
          <>
            {licenses.length === 0 && (
              <LicenseListsUnassignedText>
                <p>{LICENSE_LISTS_ASSIGNED_TEXT}</p>
              </LicenseListsUnassignedText>
            )}
            {licenses.length > 0 && (
              <Licenses
                licenses={licenses}
                onSelectUserManagement={onSelectUserManagement}
              />
            )}
          </>
        )}
        {activeTab === Tabs.Unassigned && (
          <>
            {licensesUnassigned.length === 0 && (
              <LicenseListsUnassignedText>
                <p>{LICENSE_LISTS_UNASSIGNED_TEXT}</p>
              </LicenseListsUnassignedText>
            )}
            {licensesUnassigned.length > 0 && (
              <LicensesUnassigned
                licenses={licensesUnassigned}
                onSelectAssign={onSelectAssign}
                onSelectLicense={onSelectLicense}
              />
            )}
          </>
        )}

        {activeTab === Tabs.Expired && (
          <>
            {licensesExpired.length === 0 && (
              <LicenseListsExpiredText>
                <p>{LICENSE_LISTS_EXPIRED_TEXT}</p>
              </LicenseListsExpiredText>
            )}
            {licensesExpired.length > 0 && (
              <LicensesExpired licenses={licensesExpired} />
            )}
          </>
        )}
      </>
    )
  }
}

LicenseLists.propTypes = {
  history: PropTypes.object.isRequired,
  licenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  licensesExpired: PropTypes.arrayOf(PropTypes.object).isRequired,
  licensesUnassigned: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectAssign: PropTypes.func.isRequired,
  onSelectLicense: PropTypes.func.isRequired,
  onSelectUserManagement: PropTypes.func.isRequired,
}

const LicenseListsComponent = withRouter(LicenseLists)
export default LicenseListsComponent
