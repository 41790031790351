import styled from 'styled-components'
import { TextLink } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const NotificationAlert = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1.12rem 1.5rem;
  font-size: 1.125rem;
  border: none;
  border-radius: 0.75rem;
  background-color: ${props => themeGet(`colors.${props.color}`)};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.75rem;
`

export const NotificationIcon = styled.img`
  background-image: url(${props => props.iconUrl});
  background-repeat: no-repeat;
  height: 2rem;
  margin-top: 0.3rem;
  padding-right: 3.4rem;
`

export const Message = styled.div`
  flex-grow: 1;
  margin-top: 0.3rem;
  display: block;
  color: ${themeGet(`colors.backgroundBody`)};
`

export const DismissWrapper = styled.div`
  display: flex;
  padding-left: 3.75rem;
  margin-top: 0.4rem;
`

export const DismissLink = styled(TextLink)`
  margin-right: 0.5rem;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
