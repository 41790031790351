import React from 'react'
import PropTypes from 'prop-types'
import { ProductDisplayWrapper } from './component.styles'

const ProductDisplay = row => {
  const firstRow = row.product?.description
  const stringDuration =
    row.product?.duration > 364
      ? `${row.product?.duration / 365}-Year`
      : `${row.product?.duration} Day`
  const secondRow = `Second Step ${stringDuration}`

  return (
    <ProductDisplayWrapper>
      <div>
        <b>{firstRow}</b>
      </div>
      <div>{secondRow}</div>
      <div>License</div>
    </ProductDisplayWrapper>
  )
}

ProductDisplay.propTypes = {
  productFriendlyName: PropTypes.string.isRequired,
}

export default ProductDisplay
