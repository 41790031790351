import { deepMerge } from 'grommet/utils'
import { themes as componentLibraryThemes } from 'secondstep-components'
import variables from './variables'
import mainTheme from './main'

const main = deepMerge(componentLibraryThemes.baseTheme, mainTheme)

const themes = {
  main,
}

const getTheme = theme => {
  if (themes[theme]) {
    return themes[theme]
  }
  return themes.main
}

export { variables, getTheme }
