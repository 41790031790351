import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 85.375rem;
  min-width: 23.4375rem;
  min-height: 25rem;
  margin: 0 auto;
`
