import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
// import storage from 'redux-persist/lib/storage'

import ProfileReducer from 'auth/stores/profile/reducers.js'
import AccessReducer from 'auth/stores/access'

import licenseManager from './licenseManager'
import lmsManager from './lmsManager'
import locationManager from './locationManager'
import sitesManager from './sitesManager'
import notificationsManager from './notificationsManager'

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    licenseManager,
    lmsManager,
    notificationsManager,
    locationManager,
    sitesManager,
    userAccessManager: AccessReducer,
    userProfileManager: ProfileReducer,
  })

export default rootReducer
