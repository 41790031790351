import React from 'react'
import PropTypes from 'prop-types'
import AssignSchool from './component'
import { UNLISTED } from './constants'

class AssignSchoolContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasRegionData: true,
    }
  }

  handlers = {
    handleChange: event => {
      const name = event.target.name
      const value = event.target.value
      const newState = {
        [name]: value,
      }

      if (name === 'city') {
        newState.siteId = ''
        newState.siteName = ''
      } else if (name === 'region') {
        newState.siteId = ''
        newState.siteName = ''
        newState.city = ''
      } else if (name === 'country') {
        newState.siteId = ''
        newState.siteName = ''
        newState.city = ''
        newState.region = ''
      }

      this.props.onSelection(newState)
    },

    handleLoadStateData: data => {
      if (data.length > 0) {
        this.setState({ hasRegionData: true })
      } else {
        this.setState({ hasRegionData: false })
      }
      return data
    },

    handleSchoolSelect: event => {
      const { options, selectedIndex, value } = event.target
      const text = options[selectedIndex].innerHTML

      this.props.onSelection({
        siteId: value,
        siteName: value === UNLISTED ? '' : text,
      })
    },

    handleSchoolNameChange: event => {
      this.props.onSelection({ siteName: event.target.value })
    },

    selectCitiesEndpoint: () => {
      const {
        siteData: { country, region },
      } = this.props
      const { hasRegionData } = this.state

      if (region) {
        return `location/cities?country=${country}&region=${region}`
      } else if (!hasRegionData && country) {
        return `location/cities?country=${country}`
      }
      return ''
    },

    selectSchoolsEndpoint: () => {
      const {
        siteData: { city, country, region },
      } = this.props
      const { hasRegionData } = this.state
      if (city) {
        return !hasRegionData
          ? `/netsuitecustomers?country=${country}&city=${city}`
          : `/netsuitecustomers?country=${country}&region=${region}&city=${city}`
      }
      return ''
    },
  }

  render() {
    return (
      <AssignSchool
        hasRegionData={this.state.hasRegionData}
        {...this.props}
        {...this.handlers}
      />
    )
  }
}

AssignSchoolContainer.propTypes = {
  onSelection: PropTypes.func.isRequired,
  siteData: PropTypes.object.isRequired,
}

AssignSchoolContainer.defaultProps = {
  siteData: {},
}

AssignSchoolContainer.displayName = 'AssignSchool'

export default AssignSchoolContainer
