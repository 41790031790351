import React from 'react'
import PropTypes from 'prop-types'
import Notifications from 'components/Notifications'
import { Main } from './SecondStepLayout.styles'
import GlobalNavigation from 'components/GlobalNav'
import GlobalStyles from 'themes/globalStyles'

export const SecondStepLayout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <GlobalNavigation />
      <Notifications />
      <Main>{children}</Main>
    </>
  )
}

SecondStepLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SecondStepLayout
