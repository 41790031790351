import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderTwo } from 'secondstep-components'

export const StyledHeaderText = styled(HeaderTwo)`
  display: flex;
  align-self: flex-start;
  font-size: 2.25rem;
  letter-spacing: normal;
  font-family: ${themeGet('headerFontFamilySemibold')};
`

export const StyledMicroText = styled(BodyCopy)`
  font-family: ${props =>
    props.selectedLicense
      ? themeGet('fontFamilyBold')
      : themeGet('fontFamily')};
`

export const StyledMicroTextContainer = styled.div`
  display: flex;
`

export const LicenseManagementHeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const LicenseStatusBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
export const BackLink = styled.a`
  text-decoration: none;
  font-family: ${themeGet('fontFamily')};
  color: ${themeGet('colors.darkGray')};
`
