import React from 'react'
import PropTypes from 'prop-types'
import {
  AssignConfirmTextBox,
  Check,
  AssignConfirmLabel,
  AssignConfirmTextLink,
} from './component.styles'
import {
  ASSIGN_LICENSE_CONFIRM_TEXT,
  TERMS_OF_SERVICE_CONFIRM_TEXT,
} from './constants'

const AssignConfirm = ({
  enableTermsOfService,
  handleToggle,
  isAssignmentConfirmed,
  isTosConfirmed,
  isSelaLicense,
}) => {
  return (
    <>
      {enableTermsOfService && (
        <AssignConfirmTextBox>
          <AssignConfirmLabel>
            <Check
              aria-required="true"
              checked={isTosConfirmed}
              data-testid="tos-confirm-checkbox"
              name="isTosConfirmed"
              onChange={handleToggle}
            />
            {TERMS_OF_SERVICE_CONFIRM_TEXT}&nbsp;
            <AssignConfirmTextLink
              href={
                isSelaLicense
                  ? 'https://www.secondstep.org/license-agreements/second-step-sel-for-adults-license-agreement'
                  : 'https://www.secondstep.org/license-agreements/second-step-grades-k-12-digital-subscription-license-agreement'
              }
            >
              License Agreement
            </AssignConfirmTextLink>
          </AssignConfirmLabel>
        </AssignConfirmTextBox>
      )}
      <AssignConfirmTextBox>
        <AssignConfirmLabel>
          <Check
            aria-required="true"
            checked={isAssignmentConfirmed}
            data-testid="assign-confirm-checkbox"
            name="isAssignmentConfirmed"
            onChange={handleToggle}
          />
          {ASSIGN_LICENSE_CONFIRM_TEXT}
        </AssignConfirmLabel>
      </AssignConfirmTextBox>
    </>
  )
}

AssignConfirm.propTypes = {
  enableTermsOfService: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isAssignmentConfirmed: PropTypes.bool.isRequired,
  isSelaLicense: PropTypes.bool.isRequired,
  isTosConfirmed: PropTypes.bool.isRequired,
}

export default AssignConfirm
