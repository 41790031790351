import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'

const baseApiAddress = `${domains.SECONDSTEP_API}/`

const secondStepApi = (function() {
  const _api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  })
  _api.interceptors.request.use(async request => {
    const token = await Auth0Manager.getAccessToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  })

  return {
    delete: _api.delete,
    get: _api.get,
    patch: _api.patch,
    post: _api.post,
    put: _api.put,
  }
})()

export default secondStepApi
