export const NO_TIMEOUT = -1
export const MAX_EMAILS_COUNT = 200

export const ADDING_USERS_TEXT = 'Adding user(s)'
export const ASSIGNING_LICENSE_TEXT = 'Assigning license'
export const CANCELLING_INVITATION_TEXT = 'Cancelling invitation'
export const INVITING_USERS_TEXT = 'Inviting user(s)'
export const LOADING_LUM_TEXT = 'Loading User Management...'
export const UPDATING_USER_ROLE_TEXT = 'Updating user'
export const PROGRAM_CODES = {
  k8: ['K5P', 'MSP', 'K8P'],
  sela: ['ADL'],
  bpu: ['BPU'],
  cpu: ['CPU'],
}
