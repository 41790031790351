import { deepMerge } from 'grommet/utils'
import variables from './variables'

const grommetMainTheme = {
  global: {
    colors: {
      focus: variables.colors.blue,
    },
    hover: {
      background: variables.colors.lightOrange,
      color: variables.colors.white,
    },
    selected: {
      background: 'transparent',
      color: 'inherit',
    },
  },
  calendar: {
    day: {
      extend: ({ isSelected }) => ({
        background: isSelected ? variables.colors.lightOrange : null,
        borderRadius: '50%',
        lineHeight: 0,
      }),
    },
  },
  colors: {
    input: {
      text: variables.colors.darkerDarkBlue,
    },
  },

  heading: {
    level: {
      '1': {
        font: {
          family: variables.fontFamilyHeadingExtraBold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          size: '2.625rem',
          height: '3rem',
        },
      },
      '2': {
        font: {
          family: variables.fontFamilyHeadingExtraBold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          size: '1.75rem',
          height: '2.125rem',
        },
      },
      '3': {
        font: {
          family: variables.fontFamilyHeadingExtraBold,
          weight: variables.fontWeightNormal,
        },
        large: {
          size: '1.75rem',
          height: '2rem',
        },
        medium: {
          size: '1.375rem',
          height: '1.75rem',
        },
        spacing: '0.069375rem',
      },
    },
  },
}

const mainTheme = {
  breakpoints: variables.breakpoints,
  buttons: variables.buttons,

  headerFontFamily: variables.fontFamilyHeading,
  headerFontFamilySemibold: variables.fontFamilyHeadingSemibold,
  headerFontFamilyBold: variables.fontFamilyHeadingBold,

  font: variables.font,

  colors: {
    ...variables.colors,
    advisoryActivities: {
      classChallenge: {
        backgroundColor: variables.colors.yellow,
        color: variables.colors.navyBlue,
      },
      classMeeting: {
        backgroundColor: variables.colors.darkBlue,
        color: variables.colors.white,
      },
      serviceLearningProject: {
        backgroundColor: variables.colors.orange,
        color: variables.colors.white,
      },
    },
    boxShadow: variables.colors.gray300,
    // Why can't we just have buttons pull the color directly
    buttons: {
      primaryFont: variables.colors.white,
      primaryBackground: variables.colors.blue,
      primaryBackgroundDisabled: variables.colors.buttonDisabled,
      primaryBackgroundHover: variables.colors.orange,
      resumeBackground: variables.colors.yellow,
      resumeBackgroundHover: variables.colors.darkYellow,
      reviewBackground: variables.colors.blue,
      reviewBackgroundHover: variables.colors.darkBlue,
      secondaryText: variables.colors.darkGray,
      secondaryOutline: variables.colors.lightGray,
      startBackground: variables.colors.teal,
      startBackgroundHover: variables.colors.darkTeal,
      iconBase: variables.colors.blue,
      iconHover: variables.colors.orange,
      tabActive: variables.colors.orange,
      tabDefault: variables.colors.darkGray,
    },
    courseInstanceCardBackground: '#F2F2F2',
    headerBase: variables.colors.navyBlue,
    heading: {
      level: {
        1: variables.colors.darkBlue,
        2: variables.colors.darkBlue,
        3: variables.colors.darkBlue,
        4: variables.colors.darkBlue,
        5: variables.colors.darkerDarkBlue,
        6: variables.colors.darkBlue,
      },
    },
    learn: variables.colors,
    lessonABBackground: '#e7eff5',
    progressBarIndicator: variables.colors.yellow,
    tabTitleInactive: variables.colors.darkerDarkBlue,
    textBase: variables.colors.navyBlue,
  },

  lessons: {
    height: '4.07rem',
  },

  padding: ['2px', '4px', '6px'],

  cardBorder: '1px solid #ccc',
  cardBorderRadius: '8px 8px 0 0',
  courseInstanceCardBorderRadius: '0.5625rem', // 9px
  globalNav: {
    backgroundColor: variables.colors.darkBlue,
  },

  programNav: {
    boxShadow: '0px 5px 7px rgba(182,182,182,0.5)',
    backgroundColor: variables.colors.white,
    height: '36px',
    padding: '1.25rem',
  },

  navTabButton: {
    fontFamily: 'Molde-medium',
    colors: {
      active: variables.colors.darkBlue,
      default: variables.colors.mediumGray,
      isHovered: variables.colors.mediumGray,
    },
    marginRight: '1.625rem',
    fontSize: '.9375rem',
  },
}

export default deepMerge(grommetMainTheme, mainTheme)
