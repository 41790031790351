import React from 'react'
import PropTypes from 'prop-types'
import { CloseButton } from 'secondstep-components'
import { domains } from '../../config/env'
import {
  NotificationAlert,
  NotificationIcon,
  Message,
  DismissWrapper,
} from './component.styles'
const { CDN_DOMAIN } = domains

class Notification extends React.Component {
  static pngs = {
    question: `${CDN_DOMAIN}/assets/img/question_icon_32px.png`,
    info: `${CDN_DOMAIN}/assets/img/info_icon_32px.png`,
    success: `${CDN_DOMAIN}/assets/img/success_icon_32px.png`,
    warning: `${CDN_DOMAIN}/assets/img/yield_icon_32px.png`,
    danger: `${CDN_DOMAIN}/assets/img/warning_icon_32px.png`,
    close: `${CDN_DOMAIN}/assets/img/cross_icon.png`,
  }

  constructor(props) {
    super(props)

    this.state = {
      show: true,
      dismissTimeout: null,
    }
  }

  componentDidMount = () => {
    const { dismissable, autoDismissTimeout } = this.props
    if (dismissable && autoDismissTimeout > 0) {
      this.setDismissTimeout(this.props.autoDismissTimeout)
    }
  }

  componentWillUnmount = () => {
    // TODO: If the notification gets dismissed there is an unmounted
    // component update error, find a way to clear the timeout that works
    const { dismissTimeout } = this.state
    if (dismissTimeout) {
      clearTimeout(dismissTimeout)
      this.setState({ dismissTimeout: null })
    }
  }

  setDismissTimeout = (ttl = 500) => {
    const { onDismiss } = this.props
    const timeout = setTimeout(() => {
      this.setState({ dismissed: true }, onDismiss)
    }, ttl)
    this.setState({ dismissTimeout: timeout })
  }

  dismiss = () => {
    this.setState({ show: false }, this.setDismissTimeout)
  }

  render() {
    const { dismissed, show } = this.state
    let {
      color,
      clearBackground,
      showIcon,
      text,
      dismissable,
      children,
    } = this.props
    const content = children || text

    if (dismissed) return null

    const icon = color
    color = clearBackground ? 'none' : color

    return (
      show && (
        <NotificationAlert color={color} data-testid={`notification-${icon}`}>
          {showIcon && <NotificationIcon iconUrl={Notification.pngs[icon]} />}
          <Message>{content}</Message>
          {dismissable && (
            <DismissWrapper>
              <CloseButton
                className="close-button"
                dataTestId="button-close-notification"
                onClick={this.dismiss}
              />
            </DismissWrapper>
          )}
        </NotificationAlert>
      )
    )
  }
}

Notification.propTypes = {
  autoDismissTimeout: PropTypes.number,
  children: PropTypes.element,
  clearBackground: PropTypes.bool,
  color: PropTypes.oneOf(['question', 'info', 'success', 'warning', 'danger'])
    .isRequired,
  dismissable: PropTypes.bool,
  dismissed: PropTypes.bool,
  fade: PropTypes.bool,
  id: PropTypes.number,
  onDismiss: PropTypes.func,
  showIcon: PropTypes.bool,
  text: PropTypes.string,
}

Notification.defaultProps = {
  autoDismissTimeout: 0,
  clearBackground: false,
  dismissed: false,
  dismissable: false,
  showIcon: true,
}

export default Notification
