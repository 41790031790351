import React from 'react'
import PropTypes from 'prop-types'
import {
  displayRoles,
  filterCanceledUsers,
  getSitesApplicableRoles,
  InvitationStatus,
  RolesInfo,
} from 'lib/util'
import UserActions from 'components/UserActions'
import {
  TooltipIcon,
  UserActionsHeader,
  RoleDescription,
  RolesTooltip,
  SiteUserTable,
} from './component.styles'
import {
  ACCOUNT_STATUS,
  EMAIL,
  EMPTY_DATA_PRIMARY_MESSAGE,
  EMPTY_DATA_SECONDARY_MESSAGE,
  LAST_LOGGED_IN,
  NAME,
  NUMBER_OF_CLASSES,
  ROLE,
  USER_ACTIONS,
} from './constants'

const SiteUserGrid = ({
  className,
  displayUserActions,
  site,
  users,
  currentUser,
  handleCancelUserAccess,
  handleGrantNewRole,
  handleRemindEmail,
  hasK8License,
}) => {
  const siteId = site.id
  const productRole = RolesInfo?.[site?.skuCode]

  const RolesList = () => (
    <>
      {productRole.map(role => (
        <RoleDescription key={role.name}>
          <b>{role.name}</b>
          <br />
          {role.description}
        </RoleDescription>
      ))}{' '}
    </>
  )

  const RoleHeader = () => (
    <>
      Role{' '}
      {productRole?.length ? (
        <RolesTooltip
          content={<RolesList />}
          dataTestId="role-description-tooltip-table"
        >
          <TooltipIcon />
        </RolesTooltip>
      ) : null}
    </>
  )

  const parseLastLoggedIn = timestamp => {
    const options = { month: 'numeric', day: 'numeric', year: '2-digit' }
    return new Date(Date.parse(timestamp)).toLocaleDateString('en-US', options)
  }

  const parseSiteUser = row => ({
    classesCreated: row.classesCreated,
    firstName: row.firstName,
    id: row.id,
    inviteEmail: row.inviteEmail,
    isActive: row.isActive,
    isRostered: row.isRostered,
    lastName: row.lastName,
    lastLoggedInAt: row.lastLoggedInAt,
    status: row.status,
    siteId: row.siteId,
    roles: row.roles,
    userId: row.userId,
  })

  const renderClassesCreatedColumn = () => {
    if (hasK8License) {
      return [
        {
          title: NUMBER_OF_CLASSES,
          fieldValue: row => row.classesCreated || '0',
          unbold: true,
        },
      ]
    }
    return []
  }

  const columns = [
    {
      title: NAME,
      fieldValue: row => {
        if (row.firstName && row.lastName) {
          return <b>{`${row.firstName} ${row.lastName}`}</b>
        } else if (row.userName) {
          return <b>{row.userName}</b>
        }
        return <b>Not Registered</b>
      },
      sortValue: row => row.firstName,
    },
    {
      title: EMAIL,
      fieldValue: row => row.inviteEmail,
      unbold: true,
    },
    {
      title: ROLE,
      component: <RoleHeader />,
      fieldValue: row => displayRoles(row.roles),
      unbold: true,
    },
    {
      title: ACCOUNT_STATUS,
      fieldValue: row =>
        InvitationStatus[row.status] === 'Invited'
          ? 'Pending'
          : InvitationStatus[row.status],
      unbold: true,
    },
    {
      title: LAST_LOGGED_IN,
      fieldValue: row =>
        row.lastLoggedInAt ? (
          parseLastLoggedIn(row.lastLoggedInAt)
        ) : (
          <i>Not logged in yet</i>
        ),
      sortValue: row => row.lastLoggedInAt,
      unbold: true,
    },
    ...renderClassesCreatedColumn(),
  ]

  displayUserActions &&
    columns.push({
      title: USER_ACTIONS,
      headerClassName: 'userActionsHeader',
      component: <UserActionsHeader>User Actions</UserActionsHeader>,
      fieldValue: row => (
        <UserActions
          applicableRoles={getSitesApplicableRoles(site)}
          currentUserId={currentUser.id}
          handleCancelUserAccess={handleCancelUserAccess}
          handleGrantNewRole={handleGrantNewRole}
          handleRemindEmail={handleRemindEmail}
          isRostered={site.isRostered}
          siteId={siteId}
          user={parseSiteUser(row)}
          {...row}
        />
      ),
      sort: false,
      filter: false,
      unbold: true,
    })

  return (
    <SiteUserTable
      className={className}
      columns={columns}
      emptyDataPrimaryMessage={EMPTY_DATA_PRIMARY_MESSAGE}
      emptyDataSecondaryMessage={EMPTY_DATA_SECONDARY_MESSAGE}
      filter={false}
      headThemeLight
      numOfColumns={7}
      rows={filterCanceledUsers(users)}
      sort
    />
  )
}

SiteUserGrid.propTypes = {
  className: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  displayUserActions: PropTypes.bool,
  handleCancelUserAccess: PropTypes.func.isRequired,
  handleGrantNewRole: PropTypes.func.isRequired,
  handleRemindEmail: PropTypes.func.isRequired,
  hasK8License: PropTypes.boolean,
  site: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SiteUserGrid
