import React from 'react'
import PropTypes from 'prop-types'
import { ProgressBarMedium, BodyCopy } from 'secondstep-components'
import { LicensesStatusBox } from './component.styles'
import {
  ALL_LICENSES_ASSIGNED_TEXT,
  LICENSES_ASSIGNED_TEXT,
  NO_LICENSES_ASSIGNED_TEXT,
} from './constants'

export const LicensesStatus = ({
  licenses,
  dataTestId,
  licensesUnassigned,
}) => {
  let progressPercent
  let licensesBodyCopy
  const licensesUnassignedLength = licensesUnassigned?.length
  const licensesLength = licenses?.length

  if (licensesUnassignedLength > 0) {
    progressPercent =
      licensesLength / (licensesLength + licensesUnassignedLength)

    licensesBodyCopy = `${licensesLength} of ${licensesLength +
      licensesUnassignedLength}  ${LICENSES_ASSIGNED_TEXT}`
  }

  if (licensesUnassignedLength === 0)
    licensesBodyCopy = NO_LICENSES_ASSIGNED_TEXT

  if (licensesUnassignedLength === 0 && licensesLength > 0)
    licensesBodyCopy = ALL_LICENSES_ASSIGNED_TEXT

  return (
    <LicensesStatusBox dataTestId="container-licenses-status">
      <BodyCopy>{licensesBodyCopy}</BodyCopy>
      {licensesUnassignedLength > 0 && (
        <div data-testid="progressbar-licenses-status">
          <ProgressBarMedium progressPercent={progressPercent} />
        </div>
      )}
    </LicensesStatusBox>
  )
}

LicensesStatus.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  licenses: PropTypes.array,
  licensesUnassigned: PropTypes.array,
}

export default LicensesStatus
