export const ASSIGN_SCHOOL_HEADER = 'Assign School'
export const CITY_SELECT_LABEL = 'City *'
export const COUNTRY_SELECT_LABEL = 'Country *'
export const REGION_SELECT_LABEL = 'State/Province/Territory *'
export const SCHOOL_NAME_SELECT_LABEL = 'School Name *'
export const SCHOOL_SELECT_LABEL = 'School/Organization *'
export const UNLISTED = 'UNLISTED'
export const CITY_SELECT_DATA_TEST_ID = 'city-select'
export const COUNTRY_SELECT_DATA_TEST_ID = 'country-select'
export const REGION_SELECT_DATA_TEST_ID = 'region-select'
export const SCHOOL_SELECT_DATA_TEST_ID = 'school-select'
export const SCHOOL_IS_NOT_LISTED_WARNING =
  "Your school may be listed under a different city name than the one you have selected. Verify the school's address and search again."
