import types from './types'

export const initialState = {
  trainingData: {},
  error: null,
  isFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TRAINING_ATTEMPTS:
    case types.FETCH_TRAINING_ATTEMPTS_ERROR:
    case types.FETCH_TRAINING_ATTEMPTS_SUCCESS:
      return {
        ...state,
        error:
          action.type === types.FETCH_TRAINING_ATTEMPTS_ERROR
            ? action.error
            : null,
        isFetching: action.type === types.FETCH_TRAINING_ATTEMPTS,
        trainingData:
          action.type === types.FETCH_TRAINING_ATTEMPTS_SUCCESS
            ? {
                ...state.trainingData,
                [`${action.siteId}.${action.licenseId}.${action.program}`]: action.trainingData,
              }
            : state.trainingData,
      }

    default:
      return state
  }
}
