import { get } from 'lodash'
import actions from './actions'
import api from 'lib/api/secondStepApi'
import { actions as notificationActions } from 'store/notificationsManager'
import { AccessLevel, capitalize, InvitationStatus } from 'lib/util'
import { LICENSE_ASSIGNMENT_FAILURE_TEXT } from './constants'

const setSelectedUserLicense = licenseId => {
  return async dispatch => {
    dispatch(fetchLicense(licenseId))
    dispatch(actions.setSelectedUserLicense(licenseId))
  }
}

// get details for a single license. only place license role information is available.
const fetchLicense = licenseId => {
  return async dispatch => {
    dispatch(actions.fetchingLicense())
    try {
      const licenseResp = await api.getLicense(licenseId)
      const license = licenseResp.data
      dispatch(actions.fetchingLicenseSuccess(license))
    } catch (error) {
      dispatch(actions.fetchingLicenseError())
    }
  }
}

const grantNewRoleLicenseUser = ({
  firstName,
  id,
  inviteEmail,
  isActive,
  isRostered,
  lastLoggedInAt,
  lastName,
  role,
  sendEmail,
  siteId,
  userId,
}) => {
  const grantAdminLicenseUserPayload = {
    firstName,
    id,
    inviteEmail,
    isActive,
    isRostered,
    lastLoggedInAt,
    lastName,
    roles: [role],
    siteId,
    userId,
  }

  return async dispatch => {
    dispatch(actions.grantingNewRoleLicenseUser(isActive))
    try {
      const { data } = await api.updateSiteUser(grantAdminLicenseUserPayload)
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: `You've successfully granted the ${role} role to ${inviteEmail}.`,
        }),
      )
      dispatch(
        actions.grantingNewRoleLicenseUserSuccess(
          inviteEmail,
          siteId,
          data.roles,
        ),
      )
    } catch (error) {
      let lmsError = false
      if (error.response && error.response.data) {
        if (
          error.response?.data?.detail?.includes(
            'Cannot send notification to LMS',
          )
        ) {
          lmsError = true
        }
      }

      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: lmsError
            ? `Something went wrong and the role for ${inviteEmail} was not changed. Please try again later.`
            : `We weren't able to grant the role ${role} for ${inviteEmail}. Please try again later.`,
        }),
      )
      dispatch(actions.grantingNewRoleLicenseUserError(error))
    }
  }
}

const deleteLicenseUser = (email, licenseId, userId) => {
  const deleteLicenseUserPayload = {
    email,
    licenseId,
    userId,
  }

  return async dispatch => {
    dispatch(actions.deletingLicenseUser())
    try {
      if (!licenseId)
        throw new Error('License is undefined, cannot remove invite user.')
      await api.deleteLicenseUser(deleteLicenseUserPayload)
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: `You've successfully canceled invite for ${email}.`,
        }),
      )
      dispatch(actions.updateUserLicenseRemoveUser(email, licenseId, userId))
      dispatch(actions.deletingLicenseUserSuccess(licenseId, email))
    } catch (error) {
      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: `We weren't able to cancel invite for ${email}. Please try again later.`,
        }),
      )
      dispatch(actions.deletingLicenseUserError(error))
    }
  }
}

const fetchLicenses = () => {
  return async dispatch => {
    dispatch(actions.fetchingLicenses())
    try {
      const response = await api.getLicenses()
      const licenses = response.data
      dispatch(actions.fetchingLicensesSuccess(licenses))
    } catch (error) {
      dispatch(actions.fetchingLicensesError(error))
    }
  }
}

const remindLicenseUser = (accessLevel, email, licenseId) => {
  const remindlicenseUserPayload = {
    email,
    licenseId,
    roles: [capitalize(accessLevel || '')],
  }

  return async dispatch => {
    dispatch(actions.sendingLicenseInvite({ isReminder: true }))
    try {
      if (!licenseId)
        throw new Error('License is undefined, cannot remind user.')
      await api.remindLicenseUser(remindlicenseUserPayload)
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: `You've successfully reminded ${email}.`,
        }),
      )
      dispatch(actions.sendingLicenseInviteSuccess({ isReminder: true }))
    } catch (error) {
      console.error(error)
      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: `We weren't able to remind ${email}. Please try again later.`,
        }),
      )
      dispatch(
        actions.sendingLicenseInviteError({
          customError: error,
          isReminder: true,
        }),
      )
    }
  }
}

const remindAllLicenseUser = (licenseId, totalInvited) => {
  const remindlicenseUserPayload = {
    licenseId,
  }

  return async dispatch => {
    dispatch(actions.sendingLicenseInvite({ isReminder: true }))
    try {
      if (!licenseId)
        throw new Error('License is undefined, cannot remind user.')
      await api.remindAllLicenseUsers(remindlicenseUserPayload)
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: `You've successfully reminded ${totalInvited} invited user${
            totalInvited > 1 ? 's' : ''
          }.`,
        }),
      )
      dispatch(actions.sendingLicenseInviteSuccess({ isReminder: true }))
    } catch (error) {
      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: `We weren't able to remind some users. Please try again later.`,
        }),
      )
      dispatch(
        actions.sendingLicenseInviteError({
          customError: error,
          isReminder: true,
        }),
      )
    }
  }
}

const sendLicenseUser = ({
  name,
  email,
  licenseId,
  accessLevel,
  onClose,
  usePut = false,
}) => {
  const licenseUserPayload = {
    name,
    email,
    licenseId,
    roles: [capitalize(accessLevel || '')],
  }

  const upperCaseRole = `${accessLevel
    .charAt(0)
    .toUpperCase()}${accessLevel.slice(1)}`

  return async dispatch => {
    const { article = 'a' } = AccessLevel[accessLevel] || {}
    dispatch(actions.sendingLicenseInvite({ isReminder: false }))
    try {
      if (!licenseId)
        throw new Error('License is undefined, cannot invite user.')

      let licenseUserResponse
      // PUT method is used if user already exists and is being updated
      if (!usePut) {
        licenseUserResponse = await api.postLicenseUser(licenseUserPayload)
      } else {
        licenseUserResponse = await api.putLicenseUser(licenseUserPayload)
      }

      const licenseUser = licenseUserResponse.data
      const isAdmin = licenseUserPayload.roles.includes('Admin')
      const successPayload = isAdmin ? licenseUserPayload : {}
      dispatch(
        actions.sendingLicenseInviteSuccess({
          successPayload,
          licenseId,
          role: capitalize(accessLevel),
          email,
        }),
      )
      onClose()
      dispatch(actions.updateUserLicenseAddUser(licenseId, licenseUser))
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: `You have ${
            licenseUser?.status === InvitationStatus.Accepted
              ? 'added'
              : 'invited'
          } ${email} to the license in the role of ${upperCaseRole}.`,
        }),
      )
    } catch (error) {
      const data = error?.response?.data || {}
      const { detail = '' } = data
      const errorText =
        // TODO implement a better code to identify specific errors to show or rather do the update in the API
        detail === 'Maximum number of users on license is reached'
          ? `This license has already reached it’s limit of users in the role ${upperCaseRole}`
          : `We weren't able to invite ${email} as ${article} ${upperCaseRole}. ${detail} Please try again later.`
      const customError = {
        status: error?.response?.status,
        statusText: error?.response?.statusText,
        detail: errorText,
      }

      dispatch(
        actions.sendingLicenseInviteError({
          customError,
          licenseId,
          role: capitalize(accessLevel),
          email,
        }),
      )
    }
  }
}

const sendLicenseUsers = ({ emails, licenseId, role }) => {
  return async dispatch => {
    dispatch(actions.sendingLicenseInviteBulk())

    if (!licenseId)
      throw new Error('License is undefined, cannot invite users.')

    try {
      const response = await api.postLicenseUsers({
        emails,
        role,
        licenseId,
      })
      const { data: results } = response
      const { added, invited } = results

      const newUsers = []

      added.details.forEach(detail => {
        detail.emails.forEach(email => {
          newUsers.push({
            email,
            userName: '',
            roles: [capitalize(role)],
            status: 'Active',
            title: '',
            userId: '',
            licenseId,
          })
        })
      })

      invited.details.forEach(detail => {
        detail.emails.forEach(email => {
          newUsers.push({
            email,
            userName: '',
            roles: [capitalize(role)],
            status: 'Invited',
            title: '',
            userId: '',
            licenseId,
          })
        })
      })

      dispatch(
        actions.sendingLicenseInviteBulkSuccess({
          results: {
            ...results,
            role: capitalize(role),
            licenseId,
          },
        }),
      )
      dispatch(actions.updateUserLicenseAddUsers(licenseId, newUsers))
    } catch (error) {
      const customError = {
        status: error?.response?.status,
        statusText: error?.response?.statusText,
        detail: error?.response?.data?.detail,
      }
      dispatch(
        actions.sendingLicenseInviteBulkError({
          customError,
          licenseId,
          role: capitalize(role),
        }),
      )
    }
  }
}

const sendLicenseAssignment = licenseAssignment => {
  return async dispatch => {
    dispatch(actions.sendingLicenseAssignment())
    try {
      if (!licenseAssignment)
        throw new Error('Failed to send License Assignment')
      const response = await api.postLicenseAssignment(licenseAssignment)
      dispatch(
        actions.sendingLicenseAssignmentSuccess(
          response.data,
          licenseAssignment.subscriptionId,
        ),
      )
      dispatch(
        notificationActions.addNotification({
          color: 'success',
          text: "You've successfully assigned a product license to this school",
        }),
      )
    } catch (error) {
      const errorText =
        get(error, 'response.data.detail') || LICENSE_ASSIGNMENT_FAILURE_TEXT
      dispatch(actions.sendingLicenseAssignmentError(error))
      dispatch(
        notificationActions.addNotification({
          color: 'danger',
          text: errorText,
        }),
      )
    }
  }
}

const resetBulkOperation = () => {
  return async dispatch => {
    dispatch(actions.resetBulkUsersAdd())
  }
}

export default {
  setSelectedUserLicense,
  fetchLicense,
  deleteLicenseUser,
  fetchLicenses,
  remindLicenseUser,
  remindAllLicenseUser,
  sendLicenseUser,
  sendLicenseUsers,
  sendLicenseAssignment,
  grantNewRoleLicenseUser,
  resetBulkOperation,
}
