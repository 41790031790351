import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'
const baseApiAddress = `${domains.REPORTING_API}`

const api = (function(baseApiAddress) {
  const _api = axios.create({
    baseUrl: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 60000,
  })

  _api.interceptors.request.use(async request => {
    const token = await Auth0Manager.getAccessToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  })

  const GET_SITE_USERS = siteId => ({
    query: `
      query GetSiteUsers {
        SiteUsers(siteId: ${siteId}) {
          users {
            email
            classCount
          }
        }
      }
    `,
  })

  return {
    getSiteUsers: async siteId => {
      return _api.post(baseApiAddress, GET_SITE_USERS(siteId))
    },
  }
})(baseApiAddress, true)

export default api
