import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'

import NotFound from 'secondstep-components/src/components/NotFound'

import LicenseManagement from 'pages/LicenseManagement'
import UserManagement from 'pages/UserManagement'
import { paths } from './paths'

function PrivateRoute({ component: Component, path, authed, ...rest }) {
  return (
    <Route
      exact
      path={path}
      render={props =>
        authed === true ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  authed: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
}

class LUMRouter extends Component {
  render() {
    const { pushTo, isLicenseAdmin, isSiteAdmin } = this.props

    return (
      <Switch>
        <PrivateRoute
          authed={isLicenseAdmin}
          component={LicenseManagement}
          exact
          path={paths.UNASSIGNED_LICENSES}
          pushTo={pushTo}
        />
        <PrivateRoute
          authed={isLicenseAdmin}
          component={LicenseManagement}
          exact
          path={paths.ASSIGNED_LICENSES}
          pushTo={pushTo}
        />
        <PrivateRoute
          authed={isLicenseAdmin}
          component={LicenseManagement}
          exact
          path={paths.ASSIGN_LICENSE}
          pushTo={pushTo}
        />
        <PrivateRoute
          authed={isLicenseAdmin}
          component={LicenseManagement}
          exact
          path={paths.EXPIRED_LICENSES}
          pushTo={pushTo}
        />
        <PrivateRoute
          authed={isSiteAdmin}
          component={UserManagement}
          path={paths.USER_MANAGEMENT}
          pushTo={pushTo}
          userInvite={false}
        />
        <Redirect exact from="/" to={paths.UNASSIGNED_LICENSES} />
        <Redirect
          exact
          from={paths.BASE_LICENSE_MANAGEMENT}
          to={paths.UNASSIGNED_LICENSES}
        />
        <Route component={NotFound} />
      </Switch>
    )
  }
}

LUMRouter.propTypes = {
  isLicenseAdmin: PropTypes.bool.isRequired,
  isSiteAdmin: PropTypes.bool.isRequired,
  pushTo: PropTypes.func,
  selectedSite: PropTypes.object,
}

export default LUMRouter
