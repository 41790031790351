import types from './types'

let nextNotificationId = 0

const addNotification = ({
  text,
  color = 'info',
  dismissable = true,
  timeout = 10000,
}) => ({
  type: types.ADD_NOTIFICATION,
  id: nextNotificationId++,
  text,
  color,
  dismissable,
  timeout,
})

const removeNotification = notification => ({
  type: types.REMOVE_NOTIFICATION,
  id: notification.id,
})

export default {
  addNotification,
  removeNotification,
}
