import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { YieldIcon } from 'secondstep-components'
import {
  ButtonsContainer,
  CancelButton,
  ConfirmButton,
  Divider,
  FaqLink,
  FaqLinkContainer,
  Header,
  Paragraph,
  RootWrapper,
} from './component.styles'
import {
  ALL,
  CANCEL_TX,
  displayText,
  FAQ_LINK_URL,
  FAQ_LINK,
  PENDING,
  SEND_TX,
} from './constants'
import { CustomCheckBox } from '../CustomCheckBox/component'
import RegTrademarkText from '../RegTrademarkText/component'

const SendInvitesDialog = ({ onCancel, onConfirm, showIcon, userMetaInfo }) => {
  const [isChecked, setIsChecked] = useState({ all: false })
  const [isDisabled, setIsDisabled] = useState({ all: false })

  useEffect(() => {
    setIsChecked(getDefaultCheckedStated())
  }, [])

  useEffect(() => {
    checkIsDisabled()
  }, [isChecked])

  const getCountSection = count => `(${count} user${count > 1 ? 's' : ''})`

  const handleOnChange = e => {
    const { name, checked } = e.target

    setIsChecked(prevState => {
      let newState = { ...prevState }

      if (name === PENDING) {
        // set everything not pending to false
        Object.entries(newState).map(([key, value]) =>
          key === PENDING ? (newState[key] = checked) : (newState[key] = false),
        )
      } else if (name === ALL) {
        // set everything not all to the value of all
        Object.entries(newState).map(([key, value]) =>
          key === PENDING ? (newState[key] = false) : (newState[key] = checked),
        )
      } else {
        newState[name] = checked

        let allNonPendingNonAllAreChecked = true
        for (const role in newState) {
          if (Object.hasOwnProperty.call(newState, role)) {
            if (role === ALL || role === PENDING) {
              continue
            }
            if (
              newState[role] &&
              Object.hasOwnProperty.call(newState, PENDING)
            ) {
              newState.pending = false
            }
            if (!newState[role]) {
              newState.all = false
              allNonPendingNonAllAreChecked = false
            }
          }
        }

        if (allNonPendingNonAllAreChecked) {
          newState.all = true
        }
      }

      return newState
    })
  }

  const checkIsDisabled = () => {
    const disabledState = {}

    if (isChecked[ALL]) {
      for (const role in isChecked) {
        if (Object.hasOwnProperty.call(isChecked, role)) {
          disabledState[role] = !(role === ALL || role === PENDING)
        }
      }
    } else {
      for (const role in isChecked) {
        if (Object.hasOwnProperty.call(isChecked, role)) {
          disabledState[role] = false
        }
      }
    }

    setIsDisabled(disabledState)
  }

  const isSendDisabled = () => {
    for (const role in isChecked) {
      if (isChecked[role]) return false
    }
    return true
  }

  const getDefaultCheckedStated = () => {
    const newCheckedState = {}
    for (const role in userMetaInfo) {
      if (userMetaInfo[role] === 0) continue
      newCheckedState[role] = false
    }
    return newCheckedState
  }

  return (
    <RootWrapper>
      {showIcon && <YieldIcon />}
      <Header dataTestId="send-invites-dialog-header">
        Notify staff about
        <br />
        <RegTrademarkText fontWeight="bold" text="Second Step" />
        access
      </Header>
      <Paragraph>Send an email invite to:</Paragraph>
      {Object.keys(userMetaInfo)
        .filter(prop => !!userMetaInfo[prop])
        .map(prop => (
          <>
            <CustomCheckBox
              checked={isChecked[prop]}
              isDisabled={isDisabled[prop]}
              key={prop}
              name={prop}
              onChange={handleOnChange}
              text={`${displayText[prop]} ${getCountSection(
                userMetaInfo[prop],
              )}`}
            />
            {prop === PENDING && <Divider key="send-invite-div" />}
          </>
        ))}
      <ButtonsContainer>
        <CancelButton
          dataTestId="send-invites-dialog-cancel-button"
          onClick={onCancel}
        >
          {CANCEL_TX}
        </CancelButton>
        <ConfirmButton
          dataTestId="send-invites-dialog-confirm-button"
          disabled={isSendDisabled()}
          onClick={() => onConfirm(isChecked)}
          type="submit"
        >
          {SEND_TX}
        </ConfirmButton>
      </ButtonsContainer>
      <FaqLinkContainer>
        <FaqLink href={FAQ_LINK_URL} target="_blank">
          {' '}
          {FAQ_LINK}
        </FaqLink>
      </FaqLinkContainer>
    </RootWrapper>
  )
}

SendInvitesDialog.propTypes = {
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  showIcon: PropTypes.bool,
  userMetaInfo: PropTypes.object,
}

export default SendInvitesDialog
