import { connect } from 'react-redux'
import { pathToProduct } from './data'

import Component from './component'

const mapStateToProps = ({ router, userProfileManager }) => {
  const { location } = router || {}
  const { pathname } = location || {}
  const product = pathToProduct(pathname)

  const { profile } = userProfileManager || {}
  const { firstName = '', lastName = '' } = profile || {}
  return {
    name: `${firstName} ${lastName}`.trim(),
    product,
  }
}

export default connect(mapStateToProps)(Component)
