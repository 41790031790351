import types from './types'

const fetchingLicenses = () => {
  return {
    type: types.FETCHING_LICENSES,
  }
}

const fetchingLicensesError = (
  error = 'There was a problem loading your licenses. Please try again later.',
) => {
  return {
    type: types.FETCHING_LICENSES_ERROR,
    error,
  }
}

const fetchingLicensesSuccess = licenses => {
  return {
    type: types.FETCHING_LICENSES_SUCCESS,
    licenses,
  }
}

const sendingLicenseInvite = ({ isReminder = false }) => {
  return {
    type: types.SENDING_LICENSE_USER,
    isReminder,
  }
}

const sendingLicenseInviteError = ({
  customError = 'There was a problem submitting an invite license users.',
  licenseId,
  role,
  isReminder = false,
  email,
}) => {
  return {
    type: types.SENDING_LICENSE_USER_ERROR,
    error: customError,
    licenseId,
    role,
    email,
    isReminder,
  }
}

const sendingLicenseInviteSuccess = ({
  licenseUserPayload,
  licenseId,
  role,
  email,
  isReminder = false,
}) => {
  return {
    type: types.SENDING_LICENSE_USER_SUCCESS,
    licenseUserPayload,
    licenseId,
    role,
    email,
    isReminder,
  }
}

const sendingLicenseInviteBulk = () => {
  return {
    type: types.SENDING_LICENSE_USER_BULK,
  }
}

const sendingLicenseInviteBulkError = (
  error = 'There was a problem submitting an invite license users.',
  licenseId,
  role,
) => {
  return {
    type: types.SENDING_LICENSE_USER_BULK_ERROR,
    error,
    licenseId,
    role,
  }
}

const sendingLicenseInviteBulkSuccess = ({ results }) => {
  return {
    type: types.SENDING_LICENSE_USER_BULK_SUCCESS,
    results,
  }
}

const sendingLicenseAssignment = () => {
  return {
    type: types.SENDING_LICENSE_ASSIGNMENT,
  }
}

const sendingLicenseAssignmentError = (
  error = 'Failed to send License Assignment',
) => {
  return {
    type: types.SENDING_LICENSE_ASSIGNMENT_ERROR,
    error,
  }
}

const sendingLicenseAssignmentSuccess = (license, subscriptionId) => {
  return {
    type: types.SENDING_LICENSE_ASSIGNMENT_SUCCESS,
    license,
    subscriptionId,
  }
}

const setSelectedUserLicense = selectedLicenseId => {
  return {
    type: types.SET_SELECTED_USER_LICENSE,
    selectedLicenseId,
  }
}

const updateUserLicenseRemoveUser = (email, licenseId, userId) => {
  return {
    type: types.UPDATE_USER_LICENSE_REMOVE_USER,
    email,
    licenseId,
    userId,
  }
}

const grantingNewRoleLicenseUser = isActive => {
  return {
    type: types.GRANTING_NEW_ROLE_LICENSE_USER,
    isActive,
  }
}

const grantingNewRoleLicenseUserError = (
  error = 'Failed to grant user the new role',
) => {
  return {
    type: types.GRANTING_NEW_ROLE_LICENSE_USER_ERROR,
    error,
  }
}

const grantingNewRoleLicenseUserSuccess = (email, licenseId, roles) => {
  return {
    type: types.GRANTING_NEW_ROLE_LICENSE_USER_SUCCESS,
    email,
    licenseId,
    roles,
  }
}

const resetBulkUsersAdd = () => {
  return {
    type: types.RESET_BULK_ADD_USERS,
  }
}

const startAddUser = payload => {
  return {
    type: types.START_ADD_USER,
    payload,
  }
}

const endAddUser = payload => {
  return {
    type: types.END_ADD_USERS,
    payload,
  }
}
const switchAddUserMode = payload => {
  return {
    type: types.SWITCH_ADD_USER_MODE,
    payload,
  }
}

export default {
  fetchingLicenses,
  fetchingLicensesError,
  fetchingLicensesSuccess,
  sendingLicenseInvite,
  sendingLicenseInviteError,
  sendingLicenseInviteSuccess,
  sendingLicenseInviteBulk,
  sendingLicenseInviteBulkError,
  sendingLicenseInviteBulkSuccess,
  sendingLicenseAssignment,
  sendingLicenseAssignmentError,
  sendingLicenseAssignmentSuccess,
  setSelectedUserLicense,
  updateUserLicenseRemoveUser,
  grantingNewRoleLicenseUser,
  grantingNewRoleLicenseUserError,
  grantingNewRoleLicenseUserSuccess,
  resetBulkUsersAdd,
  startAddUser,
  endAddUser,
  switchAddUserMode,
}
