import React from 'react'
import PropTypes from 'prop-types'
import { YieldIcon } from 'secondstep-components'
import {
  RootWrapper,
  ButtonsContainer,
  ConfirmButton,
  CancelButton,
  Header,
  Paragraph,
} from './component.styles'
import { CANCEL_TX } from './constants'

const LUMConfirmationDialog = ({
  onCancel,
  onConfirm,
  header,
  text,
  confirmText,
  showIcon,
}) => {
  return (
    <RootWrapper>
      {showIcon && <YieldIcon />}
      <Header dataTestId="lum-confirmation-dialog-header">{header}</Header>
      <Paragraph>{text}</Paragraph>
      <ButtonsContainer>
        <CancelButton
          dataTestId="lum-confirmation-dialog-cancel-button"
          onClick={onCancel}
        >
          {CANCEL_TX}
        </CancelButton>
        <ConfirmButton
          dataTestId="lum-confirmation-dialog-confirm-button"
          onClick={onConfirm}
          type="submit"
        >
          {confirmText}
        </ConfirmButton>
      </ButtonsContainer>
    </RootWrapper>
  )
}

LUMConfirmationDialog.propTypes = {
  confirmText: PropTypes.string,
  header: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  showIcon: PropTypes.bool,
  text: PropTypes.string,
}

export default LUMConfirmationDialog
