import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ContainedButton,
  FormInputTextArea,
  HeaderTwo,
  SuccessIcon,
  WarningIcon,
} from 'secondstep-components'

export const CheckIcon = styled(SuccessIcon)`
  width: 1.375rem;
  height: 1.375rem;
`

export const CloseButton = styled(ContainedButton)`
  margin-top: 1.75rem;
  width: 9.188rem;
  margin-left: auto;
  &:not([disabled]):hover {
    background-color: ${themeGet('colors.secondStepBlue')};
    border-color: ${themeGet('colors.secondStepBlue')};
  }
`

export const CopyWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-right: 0.75rem;
  cursor: pointer;
`

export const EmailsTextArea = styled(FormInputTextArea)`
  padding-top: 0rem;
  height: 6.25rem;
  border-color: ${themeGet('colors.gray400')};
  font-family: ${themeGet('fontFamily')};
`

export const ErrorSectionHeader = styled(HeaderTwo).attrs(() => ({
  role: 'alert',
  'aria-live': 'assertive',
}))`
  color: ${themeGet('colors.error')};
  margin-top: 1rem;
`

export const ExclamationIcon = styled(WarningIcon)`
  width: 1.375rem;
  height: 1.375rem;
`

export const Header = styled(HeaderTwo)`
  margin-bottom: 1.1rem;
`

export const IconWrapper = styled.div`
  position: relative;
  margin-left: 0.5rem;
`

export const InnerWrapper = styled.div`
  margin-top: 1.1rem;
  display: flex;
  flex-direction: column;
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
`
export const SecondEmailsTextArea = styled(EmailsTextArea)`
  height: 9.25rem;
`

export const SecondaryWrapper = styled(MessageWrapper)`
  margin-bottom: 0.85rem;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.4rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.7rem;
  width: 29.75rem;
  box-sizing: border-box;
`

// #region Icon hierarchy
export const SquareIcon = styled.div`
  width: 0.688rem;
  height: 0.875rem;
  background-color: #a0a0a0;
  border: 0.0625rem solid white;
`

export const BottomSquareIcon = styled(SquareIcon)`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
`
// #endregion

// #region Paragrah hierarchy
export const Paragraph = styled.p`
  text-align: left;
  font-size: 1rem;
  font-family: ${themeGet('fontFamily')};
  color: ${themeGet('colors.darkerDarkBlue')};
`

export const BoldParagraph = styled(Paragraph)`
  font-family: ${themeGet('fontFamilySemibold')};
  padding-left: 0.438rem;
`

export const SmallParagraph = styled(Paragraph)`
  font-size: 0.875rem;
`

export const BottomParagraph = styled(SmallParagraph)`
  margin-top: 0.375rem;
`
// #endregion
