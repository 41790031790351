import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Field } from 'formik'

export const SendNotificationWrapper = styled.div`
  padding-top: 1.5625rem;
  color: ${themeGet('colors.backgroundBody')};
  border-top: 0.0938rem solid ${themeGet('colors.borderTable')};
`
export const Header = styled.p`
  color: ${themeGet('colors.secondStepBlue')};
  font-size: 1.125rem;
  font-family: ${themeGet('headerFontFamilySemibold')};
  margin: 0;

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 0.125rem;
  }
`

export const Body = styled.p`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
  margin: 0.6875rem 0rem 0rem;
`

export const FaqLink = styled.a`
  text-decoration: none;
  font-family: ${themeGet('fontFamilySemibold')};
  color: ${themeGet('colors.blue')};
  &:hover {
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  margin: 1.125rem 0 0.5rem;

  label:first-child {
    margin-right: 1.25rem;
  }
`

export const RadioField = styled(Field)`
  accent-color: ${themeGet('colors.blue')};
  margin-right: 0.4375rem;
  cursor: pointer;
`

export const RadioLabel = styled.label`
  font-family: ${themeGet('fontFamilySemibold')};
  margin-top: -0.25rem;
  opacity: ${props => (props.disabled ? '60%' : '100%')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  & input {
    margin-right: 0.4375rem;
  }
`
