import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ContainedButton,
  OutlinedButton,
  Tooltip,
  TooltipContentWrapper,
} from 'secondstep-components'
import Container from 'components/Container'

export const InviteAndRemindContainer = styled(Container)`
  background-color: ${themeGet('colors.white')};
  justify-content: flex-end;
  padding: 0;
  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const SiteAndInviteInstructions = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  min-height: 2.6875rem;
`

export const SendInvitesButton = styled(OutlinedButton)`
  margin-right: 1rem;
  :disabled {
    background: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    color: ${themeGet('colors.white')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.primaryBackground')};
    border-color: ${themeGet('colors.buttons.primaryBackground')};
    color: ${themeGet('colors.white')};
  }

  &:focus {
    outline-color: ${themeGet('colors.darkBlue')};
    outline-style: dashed;
    outline-width: 2.5px;
    outline-offset: 5px;
  }

  ${themeGet('breakpoints.medium')} {
    margin-right: 0.5rem;
  }
`

export const AddUsersButton = styled(ContainedButton)`
  margin-left: 0;

  &:focus {
    outline-color: ${themeGet('colors.darkBlue')};
    outline-style: dashed;
    outline-width: 2.5px;
    outline-offset: 5px;
  }
  
  &:not([disabled]):hover {
    background-color: ${themeGet('colors.secondStepBlue')};
    border-color: ${themeGet('colors.secondStepBlue')};
    color: ${themeGet('colors.white')};
  }
`

export const Tip = styled(Tooltip)`
  ${TooltipContentWrapper} {
    white-space: normal;
    left: -120%;
    top: -63%;
    border: 1px solid ${themeGet('colors.tabBorder')};
    background-color: ${themeGet('colors.rosterTooltipBackground')};
    box-shadow: none;
  }
`
