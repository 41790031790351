import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Field } from 'formik'

export const RolesGroup = styled.div`
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 2.5rem;
`

export const Header = styled.h2`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
`

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.75rem;
`

export const RoleLabel = styled.label`
  font-family: ${themeGet('fontFamilySemibold')};
  margin-top: -4px;
  opacity: ${props => (props.disabled ? '60%' : '100%')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  & input {
    margin-right: 0.4375rem;
  }
`

export const RadioRoleField = styled(Field).attrs(
    ({ dataTestId }) => ({
      'data-testid': dataTestId,
    }),
  )`
  accent-color: ${themeGet('colors.blue')};
`

export const RoleDescriptionUnorderedList = styled.ul`
  list-style: none;
  margin: 0 0 0 1.75rem;
  padding: 0;
  font-family: ${themeGet('fontFamily')};
  opacity: ${props => (props.isAdminRoleAndMultipleMode ? '60%' : '100%')};
`
