import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ContainedButton, AutoTable } from 'secondstep-components'

export const LicenseButton = styled(ContainedButton)`
  width: 162px;
  background: ${props => props.hasUsers && 'rgba(0,0,0,0)'};
  color: ${props => props.hasUsers && themeGet('colors.darkGray')};
  border: ${props => props.hasUsers && '2px solid'};
  border-color: ${props => props.hasUsers && themeGet('colors.darkGray')};

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttonHover')};
    color: ${props => props.hasUsers && themeGet('colors.white')};
    border: ${props => props.hasUsers && '0'};
    border-color: ${props => !props.hasUsers && themeGet('colors.buttonHover')};
  }
`

export const LicensesTable = styled(AutoTable)`
  color: ${themeGet('colors.darkGray')};
  border: none;
  background-color: white;
  margin-top: 1rem;

  tbody tr td {
    border-bottom: 1px solid ${themeGet('colors.gray400')};
    line-height: 24px;
  }

  tbody tr td :nth-child(1) div,
  tbody tr td :nth-child(6) div {
    width: 120px;
  }

  [class*='AutoTableHeader'] {
    font-weight: bold;
    width: auto;
  }

  [class*='AutoTableHeader'],
  .child:first-child {
    width: 20%;
  }

  [class*='AutoTableHeaderContent'] div {
    width: 10px;
    height: 5px;
    padding-top: 0;
    padding-right: 5px;
  }

  [class*='AutoTableHeaderTh'] {
    border-bottom: 1px solid ${themeGet('colors.gray400')};
    padding: 0 0.9rem;
    ${themeGet('breakpoints.mobile')} {
      padding-left: ${themeGet('spacing.blue16_1rem')};
      padding-right: ${themeGet('spacing.blue16_1rem')};
    }
    background-color: ${themeGet('colors.beaconGray')};
  }

  [class*='AutoTableHeaderTh']:last-child,
  [class*='Cell']:last-child {
    padding-right: 40px;
  }

  [class*='AutoTableHeaderSpan'] {
    margin-right: ${themeGet('spacing.pink8_0_5rem')};
    padding: 0;
  }
`
