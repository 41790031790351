import types from './types'

const fetchingK8SiteUsers = () => ({
  type: types.FETCHING_K8_SITE_USERS,
})
const fetchingK8SiteUsersError = error => ({
  type: types.FETCHING_K8_SITE_USERS_ERROR,
  error,
})
const fetchingK8SiteUsersSuccess = (siteId, k8SiteUsers) => ({
  type: types.FETCHING_K8_SITE_USERS_SUCCESS,
  siteId,
  k8SiteUsers,
})

const fetchingSites = () => ({ type: types.FETCHING_SITES })
const fetchingSitesSuccess = sites => ({
  type: types.FETCHING_SITES_SUCCESS,
  sites,
})
const fetchingSitesError = error => ({
  type: types.FETCHING_SITES_ERROR,
  error,
})

const fetchingSiteUsers = () => ({ type: types.FETCHING_SITE_USERS })
const fetchingSiteUsersSuccess = (siteId, siteUsers) => ({
  type: types.FETCHING_SITE_USERS_SUCCESS,
  siteId,
  siteUsers,
})
const fetchingSiteUsersError = error => ({
  type: types.FETCHING_SITE_USERS_ERROR,
  error,
})

const creatingSiteUser = () => ({ type: types.CREATING_SITE_USER })
const creatingSiteUserSuccess = siteUser => ({
  type: types.CREATING_SITE_USER_SUCCESS,
  siteUser,
})
const creatingSiteUserError = error => ({
  type: types.CREATING_SITE_USER_ERROR,
  error,
})

const creatingSiteUsers = () => ({ type: types.CREATING_SITE_USERS })
const creatingSiteUsersSuccess = (
  siteId,
  newSiteUsers,
  failedSiteUsers,
  invalidSiteUsers,
  sendEmail,
) => ({
  type: types.CREATING_SITE_USERS_SUCCESS,
  siteId,
  newSiteUsers,
  failedSiteUsers,
  invalidSiteUsers,
  sendEmail,
})
const creatingSiteUsersError = error => ({
  type: types.CREATING_SITE_USERS_ERROR,
  error,
})

const updatingSiteUser = () => ({ type: types.UPDATING_SITE_USER })
const updatingSiteUserSuccess = siteUser => ({
  type: types.UPDATING_SITE_USER_SUCCESS,
  siteUser,
})

const updatingSiteUserError = error => ({
  type: types.UPDATING_SITE_USER_ERROR,
  error,
})

const remindingSiteUser = () => ({ type: types.REMINDING_SITE_USER })
const remindingSiteUserSuccess = () => ({
  type: types.REMINDING_SITE_USER_SUCCESS,
})
const remindingSiteUserError = error => ({
  type: types.REMINDING_SITE_USER_ERROR,
  error,
})

const remindingSiteUsers = () => ({ type: types.REMINDING_SITE_USERS })
const remindingSiteUsersSuccess = () => ({
  type: types.REMINDING_SITE_USERS_SUCCESS,
})
const remindingSiteUsersError = error => ({
  type: types.REMINDING_SITE_USERS_ERROR,
  error,
})

const resetBulkAddUsers = () => ({ type: types.RESET_BULK_ADD_USERS })

export default {
  fetchingK8SiteUsers,
  fetchingK8SiteUsersError,
  fetchingK8SiteUsersSuccess,
  fetchingSites,
  fetchingSitesSuccess,
  fetchingSitesError,
  fetchingSiteUsers,
  fetchingSiteUsersSuccess,
  fetchingSiteUsersError,
  creatingSiteUser,
  creatingSiteUserSuccess,
  creatingSiteUserError,
  creatingSiteUsers,
  creatingSiteUsersSuccess,
  creatingSiteUsersError,
  resetBulkAddUsers,
  updatingSiteUser,
  updatingSiteUserSuccess,
  updatingSiteUserError,
  remindingSiteUser,
  remindingSiteUserSuccess,
  remindingSiteUserError,
  remindingSiteUsers,
  remindingSiteUsersSuccess,
  remindingSiteUsersError,
}
