export const NOTIFY_ADMINS_TEXT_BODY = `Adding Second Step® admins will grant them access to your school’s
            Second Step® programs and features. We’ll send them an email
            notification to let them know.`
export const SECOND_STEP_TEXT = 'Second Step'
export const NOTIFY_ADMINS_TEXT_HEADER = 'Admins will be notified'
export const NOTIFY_USER_TEXT_HEADER_SINGLE = 'Notify user of access?'
export const NOTIFY_USERS_TEXT_HEADER_MULTIPLE = 'Notify users of access?'
export const NOTIFY_RADIO_LABEL_TRUE_SINGLE = 'Yes, send invite now'
export const NOTIFY_RADIO_LABEL_TRUE_MULTIPLE = 'Yes, send invites now'
export const NOTIFY_RADIO_LABEL_FALSE = "No, I'll do this later"
export const FAQ_LINK_TEXT = 'Need help? See FAQ'
export const FAQ_LINK_URL =
  'https://support.secondstep.org/hc/en-us/articles/16638044907547'
