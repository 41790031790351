import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'secondstep-components'
import {
  ADD_USERS_DATA_TEST_ID,
  ADD_USERS_TEXT,
  SEND_INVITES_BUTTON_DATA_TEST_ID,
  SEND_INVITES_BUTTON_TEXT,
  ROSTER_CANT_BE_ADDED_TEXT,
} from './constants'
import {
  AddUsersButton,
  ButtonsContainer,
  InviteAndRemindContainer,
  SendInvitesButton,
  Tip as Tooltip,
} from './component.styles'

const SiteUserAddRemind = ({
  handleOpenAddUsersModal,
  handleOpenSendInvitesModal,
  site,
}) => {
  return (
    <ErrorBoundary>
      <InviteAndRemindContainer>
        <ButtonsContainer>
          {site.isRostered ? (
            <Tooltip
              content={ROSTER_CANT_BE_ADDED_TEXT}
              dataTestId="button-add-user-to-site-roster-tooltip"
            >
              <SendInvitesButton
                dataTestId={SEND_INVITES_BUTTON_DATA_TEST_ID}
                disabled
              >
                {SEND_INVITES_BUTTON_TEXT}
              </SendInvitesButton>
            </Tooltip>
          ) : (
            <SendInvitesButton
              dataTestId={SEND_INVITES_BUTTON_DATA_TEST_ID}
              onClick={handleOpenSendInvitesModal}
            >
              {SEND_INVITES_BUTTON_TEXT}
            </SendInvitesButton>
          )}
          <AddUsersButton
            dataTestId={ADD_USERS_DATA_TEST_ID}
            disabled={false}
            onClick={handleOpenAddUsersModal}
          >
            {ADD_USERS_TEXT}
          </AddUsersButton>
        </ButtonsContainer>
      </InviteAndRemindContainer>
    </ErrorBoundary>
  )
}

SiteUserAddRemind.propTypes = {
  handleOpenAddUsersModal: PropTypes.func,
  handleOpenSendInvitesModal: PropTypes.func,
  site: PropTypes.object,
  userCounts: PropTypes.object,
}

export default SiteUserAddRemind
