import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Loading } from 'secondstep-components'
import { LOADING_LUM_TEXT } from './../../lib/constants'
import { operations as licenseOperations } from 'store/licenseManager'
import { operations as siteOperations } from 'store/sitesManager'

import LUMRouter from './component'

class LUMRouterContainer extends React.Component {
  componentDidMount() {
    this.props.fetchLicenses()
    if (this.props.sitesData === null) {
      this.props.fetchSites()
    }
  }

  render() {
    const {
      areSitesInitialized,
      areLicensesInitialized,
      isLoadingLicenses,
      isLoadingSites,
    } = this.props

    if (
      !areSitesInitialized ||
      !areLicensesInitialized ||
      isLoadingLicenses ||
      isLoadingSites
    ) {
      return <Loading dataTestId="loading-lum" loadingText={LOADING_LUM_TEXT} />
    }

    return <LUMRouter {...this.props} />
  }
}

function mapStateToProps({
  licenseManager: {
    isInitialized: areLicensesInitialized,
    isLoading: isLoadingLicenses,
    isLicenseAdmin,
    selectedUserLicense,
  },
  sitesManager: {
    isSiteAdmin,
    sites: {
      isInitialized: areSitesInitialized,
      isLoading: isLoadingSites,
      data: sitesData,
    },
  },
}) {
  return {
    areLicensesInitialized,
    areSitesInitialized,
    isLoadingLicenses,
    isLoadingSites,
    isLicenseAdmin,
    isSiteAdmin,
    selectedUserLicense,
    sitesData,
  }
}

const mapDispatchToProps = {
  fetchLicenses: licenseOperations.fetchLicenses,
  fetchSites: siteOperations.fetchSites,
}

LUMRouterContainer.propTypes = {
  areLicensesInitialized: PropTypes.bool.isRequired,
  areSitesInitialized: PropTypes.bool.isRequired,
  fetchLicenses: PropTypes.func.isRequired,
  fetchSites: PropTypes.func.isRequired,
  isLicenseAdmin: PropTypes.bool.isRequired,
  isLoadingLicenses: PropTypes.bool.isRequired,
  isLoadingSites: PropTypes.bool.isRequired,
  isSiteAdmin: PropTypes.bool.isRequired,
  selectedUserLicense: PropTypes.object,
  sitesData: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(LUMRouterContainer)
