export const ACCOUNT_STATUS = 'Account Status'
export const CONFIRMATION_SCREEN_SCREEN_TEXT_PREFIX = 'Remember that ROLEs'
export const CONFIRMATION_SCREEN_SCREEN_ASSIGN_ROLE_TX = 'Confirm'
export const CONFIRMATION_SCREEN_SCREEN_HEADER = 'Change to ROLE?'
export const EMAIL = 'Email'
export const LAST_LOGGED_IN = 'Last Logged In'
export const NAME = 'Name'
export const NUMBER_OF_CLASSES = 'Number of Classes'
export const ROLE = 'Role'
export const USER_ACTIONS = 'User Actions'
export const EMPTY_DATA_PRIMARY_MESSAGE = 'No users match that criteria'
export const EMPTY_DATA_SECONDARY_MESSAGE = 'Try selecting another option'
