import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'

const baseApiAddress = `${domains.LMS_API}/`

const api = (function(baseApiAddress) {
  const _api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 10000,
  })

  _api.interceptors.request.use(
    async request => {
      const token = await Auth0Manager.getAccessToken()
      request.headers.Authorization = `Bearer ${token}`
      return request
    },
    error => {
      return Promise.reject(error)
    },
  )

  _api.interceptors.response.use(
    response => {
      return response
    },

    error => {
      if (error?.response?.status === 401) {
        location.replace(window.location.origin)
      }
      return Promise.reject(error)
    },
  )

  return {
    baseApiAddress: baseApiAddress,

    getTrainingAttempts: async (program, learners) => {
      return _api.post(`/attempts`, {
        program: program.toLowerCase(),
        learners: learners,
      })
    },
  }
})(baseApiAddress, true)

export default api
