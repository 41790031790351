export const REMOVE_USER_TEXT = 'Remove user'
export const CANCEL_PENDING_INVITE_TEXT = 'Cancel Invitation'
export const SEND_REMINDER_TEXT = 'Send an email notification'
export const CHANGE_TO_TEXT = 'Change to'
export const REINVITE_AS_TEXT = 'Invite as'
export const CANT_BE_REMOVED_TEXT =
  'Designate another ROLE to change your role or remove you from a license. ROLEs can’t change or remove themselves.'
export const ROSTER_INVITE_CANT_BE_REMOVED_TEXT =
  'Contact your Rostering Administrator to cancel invitations. Invitations to rostered licenses cannot be cancelled in User Management.'
export const ROSTER_USER_CANT_BE_REMOVED_TEXT =
  'Contact your Rostering Administrator to remove users from the license. Users cannot be removed from rostered licenses in User Management.'
