import React from 'react'
import PropTypes from 'prop-types'

const InfoIcon = () => (
  <svg height="42px" version="1.1" viewBox="0 0 42 42" width="42px">
    <title>information_icon</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="information_icon"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M21,0 C32.5979797,0 42,9.40202025 42,21 C42,32.5979797 32.5979797,42 21,42 C9.40202025,42 0,32.5979797 0,21 C0,9.40202025 9.40202025,0 21,0 Z M21.5,17 L20.5,17 C19.3954305,17 18.5,17.8954305 18.5,19 L18.5,19 L18.5,32 C18.5,33.1045695 19.3954305,34 20.5,34 L20.5,34 L21.5,34 C22.6045695,34 23.5,33.1045695 23.5,32 L23.5,32 L23.5,19 C23.5,17.8954305 22.6045695,17 21.5,17 L21.5,17 Z M21,8 C19.3431458,8 18,9.34314575 18,11 C18,12.6568542 19.3431458,14 21,14 C22.6568542,14 24,12.6568542 24,11 C24,9.34314575 22.6568542,8 21,8 Z"
        fill="#E77564"
        id="Combined-Shape"
      />
    </g>
  </svg>
)

InfoIcon.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.number,
}

export default InfoIcon
