let breakpointSizes = {
  large: '1366px',
  medium: '992px',
  tablet: '768px',
  mobile: '375px',
}

let variables = {
  breakpoints: {
    large: `@media screen and (max-width: ${breakpointSizes.large})`,
    medium: `@media screen and (max-width: ${breakpointSizes.medium})`,
    tablet: `@media screen and  (max-width: ${breakpointSizes.tablet})`,
    mobile: `@media screen and (max-width: ${breakpointSizes.mobile})`,
    sizes: breakpointSizes,
  },

  borderRadius: '0.25rem',
  borderRadiusLg: '0.5rem',

  buttons: {
    borderRadiusBase: '6.25rem',
    borderRadiusSquare: '0.15rem',
    borderRadiusRound: '2rem',
    widthBase: '6.87rem',
    borderBase: '1px solid #495057',
    fontSizeBase: '0.812rem',
    fontWeightBase: '600',
    startBtBorder: '1px solid #3399FF',

    paddingSize: {
      small: '0.5rem 2.5rem',
      standard: '0.75rem 1.75rem',
      large: '1rem 2.5rem',
    },
  },

  containerMaxWidth: '1366px',

  fontFamily: 'Molde-regular',
  fontFamilySemibold: 'Molde-medium',
  fontFamilyBold: 'Molde-Semibold',

  // keep one or the other header font variable, decided when theming is finalized
  fontFamilyHeading: 'SharpSlab',
  fontFamilyHeadingSemibold: 'SharpSlab-Semibold',
  fontFamilyHeadingBold: 'SharpSlab-Bold',
  fontFamilyHeadingExtraBold: 'SharpSlab-Extrabold',

  fontWeightNormal: 400,

  font: {
    size: {
      buttons: {
        base: '1rem',
      },
      headers: {
        h1: '2.25rem',
        h1Large: '3.5rem',
        h2: '1.75rem',
        h2Large: '2.5rem',
        h3: '1.5rem',
        h4: '1.3125rem',
        h5: '1.25rem',
        h6: '1.125rem',
      },
    },
  },

  colors: {
    // palette
    white: '#fff',
    gray100: '#F3F3F3',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#cccccc',
    gray500: '#DDDDDD',
    gray550: '#949494',
    gray600: '#999999',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#212529',
    black: '#000',
    secondStepBlue: '#002855',
    secondStepOrange: '#ff4b21',
    navyBlue: '#212b36',
    red: '#ff0000',
    blue: '#3c6e90',
    blueLight: '#f0f1f6',
    darkBlue: '#002855',
    darkerDarkBlue: '#25282A',
    blueGray: '#8393AF',
    iconBlue: '#0f3964',
    darkGray: '#555555',
    mediumGray: '#666666',
    lightGray: '#999999',
    orange: '#e1523d',
    lightOrange: '#E77564',
    yellow: '#ffbf3f',
    darkYellow: '#cd9933',
    teal: '#487a7b',
    darkTeal: '#2b494a',
    tealKit: '#4B908C',
    tealKitHover: '#165A57',
    pink: '#ff7999',
    error: '#DC3545',

    extendGreen: '#01b7ab',
    professionalDevelopmentPurple: '#805acd',
    principalToolkitOrange: '#e17240',

    // TODO: fake colors, can be removed once real theme colors are given from design
    skittlesPink: '#F2059F',
    skittlesBlue: '#05DBF2',
    skittlesGreen: '#04D94F',
    skittlesYellow: '#F2CB05',
    skittlesOrange: '#F28705',

    // links
    linkBase: '#42a5f5',
    linkVisited: '#805aa6',
    grayLink: '#666',
    linkLight: '#ffffff',
    linkLightHover: '#f47b55',

    // backgrounds
    backgroundBody: '#1f1f1f',
    backgroundModalLight: 'rgba(255, 255, 255, 0.8)',
    backgroundModalDark: 'rgba(69,79,91,0.88)',
    backgroundNeutralGray: '#f1f1f1',
    backgroundModalGray: '#EEF0F2',
    backgroundNeutralGrayOneAccent: '#999',
    backgroundNeutralGrayTwoAccent: '#888',

    // notifications
    success: '#ebf8dd',
    question: '#e3ebf0',
    info: '#e3ebf0',
    warning: '#fdf6da',
    danger: '#f6cad0',

    // fonts
    fontLight: '#fff',
    textTwo: '#454f5b',
    gradeLogo: '#42a5f5',
    gradeLogoHover: '#4A8CE2',

    // borders
    borderBase: '#495057',
    borderInput: '#ced4da',
    borderInputFocus: '#0064d5',
    borderTable: '#CED8DF',

    // components
    progressFill: '#eec200',
    progressBackground: '#eff1f3',
    progressInactiveBackground: '#fff',
    classTile: '#212b36',

    tabTitleActive: '#4f90b2',
    tabTitleInactive: '#212b36',
    tabBorder: '#c4cdd5',
    tabUnselect: '#d7d7d7',

    buttonTextBase: '#3e4e57',
    buttonStart: '#3399ff',
    buttonRestart: '#00cc99',
    buttonDisabled: '#112748',
    buttonDisabledKit: '#2f495c',
    buttonHover: '#0E3A67',
    rosterInfoBackground: '#FCF1EF',
    rosterInfoBorder: '#E77564',
    rosterTooltipBackground: '#F9FAFB',

    // forms
    inputPlaceholder: 'rgba(0,0,0,.59)',
  },

  spacing: {
    // Spacing supplied by https://projects.invisionapp.com/share/BGXKOXZCU6T#/screens/420413051
    indigo70_4_375rem: '4.375rem',
    orange44_2_75rem: '2.75rem',
    red40_2_5rem: '2.5rem',
    amber28_1_75rem: '1.75rem',
    purple24_1_5rem: '1.5rem',
    turquoise20_1_25rem: '1.25rem',
    blue16_1rem: '1rem',
    yellow12_0_75rem: '0.75rem',
    pink8_0_5rem: '0.5rem',
    green4_0_25rem: '0.25rem',
  },
}

// variables = Object.assign(variables, {
//   colors: {
//     // map colors to states
//     blue: variables.secondStepBlue,
//     red: '#ce1f43',
//     orange: variables.secondStepOrange,
//     yellow: '#f6a320',
//     green: '#5d9f00',
//     teal: '#247470',
//     cyan: variables.linkBase,
//   },
// })
//
// variables = Object.assign(variables, {
//   colors: {
//     // map colors to states
//     primary: variables.blue,
//     secondary: variables.gray600,
//     success: variables.green,
//     info: variables.cyan,
//     warning: variables.yellow,
//     danger: variables.red,
//     light: variables.gray100,
//     dark: variables.gray800,
//   },
// })

export default variables
