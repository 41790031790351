import styled from 'styled-components'
import { Box } from 'grommet'
import { themeGet } from 'styled-system'
import Select from 'components/Select'

export const AssignSchoolContainer = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  ${themeGet('breakpoints.medium')} {
    width: 100%;
  }
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
  width: 100%;
`

export const AssignSchoolSelect = styled(Select)`
  color: ${themeGet('colors.darkGray')};
  border-radius: 5px;
`

export const AssignSchoolFieldBox = styled(Box)`
  width: 50%;
  padding-right: 1.5rem;
  ${themeGet('breakpoints.tablet')} {
    width: 100%;
    padding-right: 0;
  }}
`

export const Input = styled.input`
  background-clip: padding-box;
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.borderInput')};
  box-sizing: border-box;
  border-radius: 0px;
  color: ${themeGet('colors.gray700')};
  display: block;
  font-weight: 400;
  height: 2.375rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0.375rem 0.75rem;
  transition-delay: 0s, 0s, 0s;
  transition-duration: 0.15s, 0.15s, 0.15s;
  transition-property: border-color, box-shadow, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;

  :focus {
    color: ${themeGet('colors.gray700')};
    background-color: ${themeGet('colors.white')};
    border-color: ${themeGet('colors.borderInputFocus')};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 40, 85, 0.25);
  }
`
