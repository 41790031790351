import 'promise-polyfill/src/polyfill'
import ObjectAssign from 'es6-object-assign'
import 'array-from-polyfill'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter, push } from 'connected-react-router'
import store, { history } from 'store'
import 'regenerator-runtime/runtime'
import { Normalize } from 'styled-normalize'
import Auth0ProviderWrapper from 'auth/providers/Auth0ProviderWrapper'
import { Logger } from 'logging'
import { FlagsSingleton, FlagsProvider } from 'flags'

import { auth0Settings, LD_CREDS } from '../src/config/env'
import flagsAppLevelSubscribes from '../src/config/flagsAppLevelSubscribes'
import flagsLocalDevFlagOverride from '../src/config/flagsLocalDevFlagOverride'
import AppInitialize from './AppInitialize'
import App from './App/App'

ObjectAssign.polyfill()

Logger.init(history)

const dispatch = store.dispatch

function pushTo(url) {
  dispatch(push(url))
}

const render = async () => {
  const flagsConfig = {
    user: LD_CREDS.user,
    options: {},
    clientID: LD_CREDS.clientSideID,
  }
  FlagsSingleton.initialize({
    clientOptions: flagsConfig,
    appLevelSubscribedFlags: flagsAppLevelSubscribes,
    localDevFlagOverride: flagsLocalDevFlagOverride,
  })
  await FlagsSingleton.waitForFlagSystemReady()

  ReactDOM.render(
    <FlagsProvider softReload>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Auth0ProviderWrapper {...auth0Settings} history={history}>
            <React.Fragment>
              <Normalize />
              <AppInitialize>
                <App history={history} pushTo={pushTo} />
              </AppInitialize>
            </React.Fragment>
          </Auth0ProviderWrapper>
        </ConnectedRouter>
      </Provider>
    </FlagsProvider>,
    document.getElementById('root'),
  )
}

render()
