import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Select = styled.select`
  background-clip: padding-box;
  background-color: white;
  box-sizing: border-box;
  color: ${themeGet('colors.gray700')};
  display: block;
  height: 2.375rem;
  margin: 0;
  padding: 0.375rem 0.75rem;
  text-transform: none;
  transition-delay: 0s, 0s, 0s;
  transition-duration: 0.15s, 0.15s, 0.15s;
  transition-property: border-color, box-shadow, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  border: 1px solid ${themeGet('colors.borderInput')};
`
