import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Loading } from 'secondstep-components'
import { ASSIGNING_LICENSE_TEXT } from '../../lib/constants'
import { operations as siteOperations } from '../../store/sitesManager'
import { operations as licenseOperations } from '../../store/licenseManager'
import LicenseManagement from './component'

class LicenseManagementContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedLicense: null,
    }
  }

  onSelectLicense = selectedLicense =>
    this.setState({ selectedLicense: selectedLicense })

  clearSelectedLicense = () => {
    this.setState({ selectedLicense: null }, () => {
      const { pushTo } = this.props
      this.props.fetchLicenses()
      this.props.fetchSites()
      pushTo('/license-management')
    })
  }

  onSelectUserManagement = siteId => {
    const { pushTo } = this.props
    pushTo(`/user-management/${siteId}`)
  }

  onSelectAssign = () => {
    const { pushTo } = this.props
    pushTo('/license-management/assign-license')
  }

  render() {
    const { selectedLicense } = this.state
    const { currentUser, isUpdating, userLicenses } = this.props
    return (
      <>
        {isUpdating && (
          <Loading
            dataTestId="saving-assigning-license"
            loadingText={ASSIGNING_LICENSE_TEXT}
          />
        )}
        {!isUpdating && (
          <LicenseManagement
            clearSelectedLicense={this.clearSelectedLicense}
            currentUser={currentUser}
            onSelectAssign={this.onSelectAssign}
            onSelectLicense={this.onSelectLicense}
            onSelectUserManagement={this.onSelectUserManagement}
            selectedLicense={selectedLicense}
            userLicenses={userLicenses}
          />
        )}
      </>
    )
  }
}

LicenseManagementContainer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  fetchLicenses: PropTypes.func,
  fetchSites: PropTypes.func,
  isUpdating: PropTypes.bool.isRequired,
  pushTo: PropTypes.func.isRequired,
  userLicenses: PropTypes.object.isRequired,
}

function mapStateToProps({
  licenseManager: { isLoading, isUpdating, userLicenses },
  userProfileManager,
}) {
  return {
    currentUser: userProfileManager.profile,
    isLoading,
    isUpdating,
    userLicenses,
  }
}

const mapDispatchToProps = {
  fetchSites: siteOperations.fetchSites,
  fetchLicenses: licenseOperations.fetchLicenses,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LicenseManagementContainer)
