import React from 'react'
import moment from 'moment'
import { Logger } from 'logging'
import { PROGRAM_CODES } from './constants'

RegExp.escape = function(s) {
  const str = JSON.stringify(s)
  const escaped = str.substring(1, str.length - 1)
  // eslint-disable-next-line
  return escaped.replace(/[-\/^$*+?.()|[\]{}]/g, '\\$&')
}

export const emailRegex = /^[a-zA-Z0-9!`#$%&'*+/=?^_‘{|}~-]+(?:\.[a-zA-Z0-9!`#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/

// SkuCode to Display hash
export const SkuCodeToDisplay = {
  ADL: { productCode: 'SELA', hierarchy: 2 },
  BPU: { productCode: 'BPU', hierarchy: 3 },
  CPU: { productCode: 'CPU', hierarchy: 4 },
  K8P: { productCode: 'K8', hierarchy: 1 },
  K8PF: { productCode: 'K8', hierarchy: 1 },
  MSP: { productCode: 'K8', hierarchy: 1 },
  HSP: { productCode: 'HSP', hierarchy: 5 },
}

// TODO: we should refactor AccessLevel and RolesInfo into one object
// also I had duplicating this, but capitalizing and uncapitalizing the role is really really annoying
const teacher = {
  role: 'teacher',
  friendlyName: 'Teacher',
  name: 'Teacher',
  article: 'a',
  plural: 's',
}
const admin = {
  role: 'admin',
  friendlyName: 'Administrator',
  name: 'Admin',
  article: 'an',
  plural: 's',
}
const leaderTeam = {
  role: 'Leader Team',
  friendlyName: 'Leader Team',
  name: 'Leader Team',
  article: 'a',
  plural: '',
}
const staff = {
  role: 'staff',
  friendlyName: 'Staff',
  name: 'Staff',
  article: 'a',
  plural: '',
}
const coordinator = {
  role: 'coordinator',
  friendlyName: 'Coordinator',
  name: 'Coordinator',
  article: 'a',
  plural: 's',
}
const siteLeader = {
  role: 'site leader',
  friendlyName: 'Site Leader',
  name: 'Site Leader',
  article: 'a',
  plural: 's',
}
export const AccessLevel = {
  teacher,
  Teacher: teacher,
  admin,
  Admin: admin,
  'leader team': leaderTeam,
  'Leader Team': leaderTeam,
  staff,
  Staff: staff,
  coordinator,
  Coordinator: coordinator,
  'site leader': siteLeader,
  'Site Leader': siteLeader,
}

export const AddUsersRolesInfo = {
  ADL: [
    {
      role: 'Teacher',
      id: 'Teacher',
      description: [
        'Can view and teach lessons',
        'Can access microlearnings',
        'Can access online training',
      ],
    },
    {
      role: 'Leader Team',
      id: 'Leader Team',
      description: [
        'Can view and teach lessons',
        'Can access microlearnings',
        'Can access Leader supports',
        'Can access online training',
      ],
    },
    {
      role: 'Administrator',
      id: 'Admin',
      description: [
        'Can manage users',
        'Can view reports',
        'Can view and teach lessons',
        'Can access microlearnings',
        'Can access Leader supports',
        'Can access online training',
      ],
    },
  ],
  Default: [
    {
      role: 'Teacher',
      id: 'Teacher',
      description: ['Can view and teach lessons', 'Can access online training'],
    },
    {
      role: 'Administrator',
      id: 'Admin',
      description: [
        'Can manage users',
        'Can view reports',
        'Can view and teach lessons',
        'Can access online training',
      ],
    },
  ],
}

export const RolesInfo = {
  K8P: [
    {
      id: 'Teacher',
      name: 'Teacher',
      description: 'Can create classes and teach lessons',
    },
    {
      id: 'Admin',
      name: 'Administrator',
      description:
        'Can add, remove and change users, access progress reports, create classes and teach lessons',
    },
  ],
  MSP: [
    {
      id: 'Teacher',
      name: 'Teacher',
      description: 'Can create classes and teach lessons',
    },
    {
      id: 'Admin',
      name: 'Administrator',
      description:
        'Can add, remove and change users, access progress reports, create classes and teach lessons',
    },
  ],
  SSP: [
    {
      id: 'Teacher',
      name: 'Teacher',
      description: 'TBD',
    },
    {
      id: 'Coordinator',
      name: 'Coordinator',
      description: 'TBD',
    },
    {
      id: 'Family',
      name: 'Family',
      description: 'TBD',
    },
  ],
  ADL: [
    {
      id: 'Staff',
      name: 'Staff',
      description: 'Can only view Staff track',
    },
    {
      id: 'Leader Team',
      name: 'Leader Team',
      description: 'Can view Leader Team and Staff tracks',
    },
    {
      id: 'Site Leader',
      name: 'Site Leader',
      description:
        'Can add and remove users, change user roles, view reports, and view Leader Team and Staff tracks',
    },
  ],
  HSP: [
    {
      id: 'Teacher',
      name: 'Teacher',
      description: 'Can access online training',
    },
    {
      id: 'Admin',
      name: 'Administrator',
      description:
        'Can add, remove, and change users, view reports, and access online training',
    },
  ],
  OST: [
    {
      id: 'Teacher',
      name: 'Teacher',
      description: 'TBD',
    },
  ],
  BPU: [
    {
      id: 'Teacher',
      name: 'Teacher',
      description: 'Can access online training',
    },
    {
      id: 'Admin',
      name: 'Administrator',
      description:
        'Can add, remove, and change users, view reports, and access online training',
    },
  ],
  CPU: [
    {
      id: 'Teacher',
      name: 'Teacher',
      description: 'Can access online training',
    },
    {
      id: 'Admin',
      name: 'Administrator',
      description:
        'Can add, remove, and change users, view reports, and access online training',
    },
  ],
}

export const InvitationStatus = {
  Invited: 'Invited',
  Cancelled: 'Cancelled',
  Active: 'Active',
  Removed: 'Removed',
  None: null,
}

export const AVAILABLE_TRAINING_REPORTS = ['CPU', 'BPU']

export const AVAILABLE_LESSON_REPORTS = ['K8P', 'MSP', 'ADL', 'K12P']

export const sortArrayBy = (array, key) => {
  if (array == null) {
    return
  }
  return array.sort((a, b) => {
    const valueA = a[key]?.toLowerCase()
    const valueB = b[key]?.toLowerCase()
    if (valueA < valueB) {
      return -1
    }
    if (valueA > valueB) {
      return 1
    }
    return 0
  })
}

export const findLicenseUser = (license, email) => {
  const licenseUsers = license.users.filter(user => user.email === email)
  if (licenseUsers.length > 0) return licenseUsers[0]
}

export const displayDate = (date, format = 'MMMM DD, YYYY') => {
  if (!date) {
    return ''
  }
  const formattedDate = moment(date).format(format)
  return formattedDate
}

export const isTrainingReportAvailable = (
  license,
  bpuTrainingData,
  cpuTrainingData,
) => {
  const skuCode = license?.product?.skuCode
  const isCorrectSkuCode = AVAILABLE_TRAINING_REPORTS.includes(skuCode)
  let trainingData
  if (skuCode === 'BPU') trainingData = bpuTrainingData
  if (skuCode === 'CPU') trainingData = cpuTrainingData
  return {
    showTrainingReportDownload: isCorrectSkuCode && !license?.isExpired,
    trainingData,
  }
}

export const isLessonReportAvailable = license => {
  const skuCode = license?.product?.skuCode
  const isCorrectSkuCode = AVAILABLE_LESSON_REPORTS.includes(skuCode)
  return isCorrectSkuCode && !license?.isExpired
}

export const displayRoles = roles => {
  return [...roles]
    .map(role => role.toLowerCase())
    .map(role => AccessLevel[role].friendlyName)
    .sort()
    .join(', ')
}

export const filterCanceledUsers = (licenseUsers = []) =>
  licenseUsers?.filter(
    ({ status }) =>
      status !== InvitationStatus.Cancelled &&
      status !== InvitationStatus.Removed,
  )

export const displayAdmins = (row, currentUserEmail) => {
  if (row.admins == null || row.admins.length === 0) return ''

  const currentUserInfo = row.admins.find(
    admin => admin.email === currentUserEmail,
  )
  const firstTwoAdmins = row.admins.slice(0, 2)
  let hasCurrentUser = firstTwoAdmins.includes(currentUserInfo)

  if (currentUserInfo && !hasCurrentUser) firstTwoAdmins[0] = currentUserInfo

  const adminsToDisplay = firstTwoAdmins.map(admin => {
    return admin.firstName
      ? `${admin.firstName} ${admin.lastName}`
      : admin.email
  })

  return adminsToDisplay?.join(', ')
}

export const validateEmail = email => {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const extractExpirationInfo = (obj, format = 'MMMM DD, YYYY') => {
  const startDate = obj.startDate ? moment(obj.startDate) : null
  const cancellationDate = obj.canceledDate
    ? moment(obj.canceledDate)
    : undefined
  const expirationDate = obj.expirationDate
    ? moment(obj.expirationDate)
    : undefined
  const date =
    obj.canceledDate && cancellationDate < expirationDate
      ? cancellationDate
      : expirationDate
  const now = moment.utc()
  const isExpired = date <= now
  const daysLeft = Math.max(0, date && date.diff(now, 'days'))
  let icon = ''
  if (date <= now) icon = <i className="fa fa-times-circle text-danger" />
  else if (daysLeft <= 90)
    icon = <i className="fa fa-exclamation-triangle text-warning" />

  return {
    now: now,
    startDate: {
      native: obj.startDate,
      moment: startDate,
      formatted: obj.startDate ? startDate.format(format) : null,
    },
    expirationDate: {
      native: obj.expirationDate,
      moment: expirationDate,
      formatted: obj.expirationDate ? expirationDate.format(format) : null,
    },
    canceledDate: {
      native: obj.canceledDate,
      moment: obj.canceledDate ? cancellationDate : null,
      formatted: obj.canceledDate ? cancellationDate.format(format) : null,
    },
    effectiveEndDate: {
      moment: date,
      formatted: date && date.format(format),
    },
    format: format,
    isExpired: isExpired,
    isExpiringSoon: !isExpired && daysLeft <= 90,
    expirationWarningThresholdDays: 90,
    daysLeft: daysLeft,
    label: isExpired ? 'Expired on ' : 'Expires on ',
    icon: icon,
  }
}

export const capitalize = string =>
  string.replace(/(\b[a-z](?!\s))/g, char => char.toUpperCase())

export const inputModeType = {
  single: 'single',
  bulk: 'bulk',
}

export const pluralize = (number, word, plural = word + 's') => {
  const isSingular = [1, -1].includes(Number(number))
  return isSingular ? word : plural
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

const hasSela = licenses => {
  const programCodes = licenses.reduce(
    (acc, license) => acc.concat(license.product.programCodes),
    [],
  )
  return programCodes.indexOf('ADL') > -1
}

const siteRoles = [admin, leaderTeam, teacher]

export const getSitesApplicableRoles = site => {
  if (hasSela(site.licenses)) {
    return siteRoles
  }

  return siteRoles.filter(role => role.name !== leaderTeam.friendlyName)
}

export const UpdateSiteUserNotifications = {
  grantNewRole: {
    operationName: 'grantNewRole',
    successMessage: ({ email, role }) =>
      `You've successfully granted the ${role} role to ${email}.`,
    errorMessage: ({ email, role }) =>
      `We weren't able to grant the ${role} role to ${email}. Please try again later.`,
  },
  removeUser: {
    operationName: 'removeUser',
    successMessage: ({ email }) => `You've successfully removed ${email}.`,
    errorMessage: ({ email }) =>
      `We weren't able to remove ${email}. Please try again later.`,
  },
}

export const siteLicenseChecker = site => {
  const programCodeChecks = {
    hasK8License: false,
    hasSelaLicense: false,
    hasBpuLicense: false,
    hasCpuLicense: false,
  }

  const { licenses = [] } = site

  licenses.forEach(license => {
    const { isExpired } = license
    const { programCodes = [] } = license.product

    if (!isExpired) {
      programCodes.forEach(code => {
        if (PROGRAM_CODES.k8.includes(code)) {
          programCodeChecks.hasK8License = true
        }
        if (PROGRAM_CODES.sela.includes(code)) {
          programCodeChecks.hasSelaLicense = true
        }
        if (PROGRAM_CODES.bpu.includes(code)) {
          programCodeChecks.property = true
        }
        if (PROGRAM_CODES.cpu.includes(code)) {
          programCodeChecks.hasCpuLicense = true
        }
      })
    }
  })

  return programCodeChecks
}
