import styled from 'styled-components'

export const Container = styled.div`
  align-content: center;
  background-color: white;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  margin-top: -6px;
  padding: 10px 0;
  position: absolute;
  right: 10px;
  box-shadow: rgb(163 163 163) 0px 0px 4px;
  top: 100%;
  min-width: 250px;
  z-index: 1;
`

export const Carrot = styled.img`
  height: 8px;
  position: absolute;
  right: 13px;
  top: -5px;
  width: 14px;
`

export const MenuItemContainer = styled.button`
  display: flex;
  background-color: white;
  cursor: pointer;
  flex-direction: column;
  height: 30px;
  justify-content: center;
  width: 100%;
  line-height: 30px;
  border: none;

  :focus,
  :hover {
    background-color: #e77564;

    span {
      color: white;
    }
  }
`

export const MenuItem = styled.span`
  align-content: center;
  color: black;
  cursor: pointer;
  font-family: 'Molde-Regular';
  font-size: 14px;
  margin-left: 16px;
  margin-top: 2px;
  height: 100%;
`

export const UserName = styled.span`
  display: flex;
  font-family: 'Molde-Regular';
  color: #002855;
  font-size: 16px;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  margin: 0 16px 3px;
  border-bottom-width: 1.5px;
  border-bottom-color: #dddddd;
  border-bottom-style: solid;
`

export const UserIconWrapper = styled.div`
  height: 50px;
  width: 50px;
  margin-right: 12px;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
  }
`
