const DELETING_LESSON_PROGRESS_REPORT_SUCCESS =
  'lms/DELETING_LESSON_PROGRESS_REPORT_SUCCESS'
const DELETING_TRAINING_ATTEMPTS_SUCCESS =
  'lms/DELETING_TRAINING_ATTEMPTS_SUCCESS'
const FETCH_LESSON_PROGRESS_REPORT = 'lms/FETCH_LESSON_PROGRESS_REPORT'
const FETCH_LESSON_PROGRESS_REPORTS_ERROR =
  'lms/FETCH_LESSON_PROGRESS_REPORTS_ERROR'
const FETCH_LESSON_PROGRESS_REPORT_SUCCESS =
  'lms/FETCH_LESSON_PROGRESS_REPORT_SUCCESS'
const FETCH_TRAINING_ATTEMPTS = 'lms/FETCH_TRAINING_ATTEMPTS'
const FETCH_TRAINING_ATTEMPTS_ERROR = 'lms/FETCH_TRAINING_ATTEMPTS_ERROR'
const FETCH_TRAINING_ATTEMPTS_SUCCESS = 'lms/FETCH_TRAINING_ATTEMPTS_SUCCESS'

export default {
  DELETING_LESSON_PROGRESS_REPORT_SUCCESS,
  DELETING_TRAINING_ATTEMPTS_SUCCESS,
  FETCH_LESSON_PROGRESS_REPORT,
  FETCH_LESSON_PROGRESS_REPORTS_ERROR,
  FETCH_LESSON_PROGRESS_REPORT_SUCCESS,
  FETCH_TRAINING_ATTEMPTS,
  FETCH_TRAINING_ATTEMPTS_ERROR,
  FETCH_TRAINING_ATTEMPTS_SUCCESS,
}
