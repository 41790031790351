export const CANCEL_BUTTON_TX = 'Cancel'
export const ADD_USER_TX_BUTTON = 'Add user'
export const ADD_USERS_TX_BUTTON = 'Add users'
export const ADD_USER_MODAL_FORM_SCREEN = 'ADD_USER_MODAL_FORM_SCREEN'
export const ADD_USER_MODAL_DATA_LOSS_SCREEN = 'ADD_USER_MODAL_DATA_LOSS_SCREEN'
export const ADD_USER_MODAL_CONFIRMATION_SCREEN =
  'ADD_USER_MODAL_CONFIRMATION_SCREEN'
export const CANCEL_TX = 'Cancel'
export const DATA_LOSS_SCREEN_TEXT_SINGLE_USER =
  "The emails you've entered in the Add Multiple Users Tool will be lost"
export const DATA_LOSS_SCREEN_HEADER_SINGLE_USER = 'Go to Add Single User Tool?'
export const DATA_LOSS_SCREEN_TEXT_MULTIPLE_USERS =
  "The email you've entered in the Add Single User Tool will be lost"
export const DATA_LOSS_SCREEN_HEADER_MULTIPLE_USERS =
  'Go to Add Multiple Users Tool?'
export const CONFIRMATION_SCREEN_SCREEN_TEXT_PREFIX = 'Remember that ROLEs'
export const CONFIRMATION_SCREEN_SCREEN_ASSIGN_ROLE_TX = 'Confirm'
export const CONFIRMATION_SCREEN_SCREEN_HEADER = 'Add ROLE?'
