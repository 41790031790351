import React, { Component } from 'react'
import PropTypes from 'prop-types'
import carrotImage from './assets/carrot.svg'
import { AppleIcon } from 'secondstep-components'
import Item from './item'
import {
  Container,
  Carrot,
  UserName,
  UserIconWrapper,
} from './component.styles'
import { NAVIGATION_ITEMS } from './data'

export default class GlobalNavProfileDropdown extends Component {
  static propTypes = {
    show: PropTypes.bool,
  }

  render() {
    const { show, navigateLeaveApp, name } = this.props || {}
    return (
      show && (
        <Container>
          <Carrot src={carrotImage} />
          <UserName>
            <UserIconWrapper>
              <AppleIcon />
            </UserIconWrapper>
            {name}
          </UserName>
          {NAVIGATION_ITEMS.map(({ label, link, onClick }, index) => (
            <Item
              aria-label={`Navigation: ${label}`}
              index={index}
              key={`${index}-${label}`}
              label={label}
              link={link}
              onClick={() => (onClick ? onClick() : navigateLeaveApp(link))}
              onPress={() => (onClick ? onClick() : navigateLeaveApp(link))}
            />
          ))}
        </Container>
      )
    )
  }
}
