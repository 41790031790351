import React from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { LicenseButton, LicensesTable } from './component.styles'
import { displayAdmins, displayDate } from 'lib/util'
import ProductDisplay from '../ProductDisplay'

const Licenses = ({ licenses, onSelectUserManagement, currentUser }) => {
  const updatedLicenses = licenses.map(license => {
    return {
      ...license,
      dataTestId: `assigned-license-licenseId-${license.licenseId}-sku-${license.sku}`,
    }
  })

  const columns = [
    {
      title: 'School',
      fieldValue: row => row.siteName || '-',
      sortValue: row => {
        return row.siteName.toLowerCase() || ''
      },
    },
    {
      title: 'Product',
      fieldValue: row => ProductDisplay(row),
      sortValue: row => row.product?.description,
    },
    {
      title: 'License ID',
      fieldValue: 'nsLicenseId',
    },
    {
      title: 'Subscription Id',
      fieldValue: 'nsSubscriptionId',
      cellClassName: 'text-center',
    },
    {
      title: 'Expiration Date',
      fieldValue: row => displayDate(row.expirationDate),
      sortValue: 'expirationDate',
    },
    {
      title: 'Administrators',
      fieldValue: row => displayAdmins(row, currentUser.email),
    },
    {
      title: '',
      fieldValue: row => {
        if (
          row.hasUsers == '-' ||
          row.admins == '-' ||
          row.admins.length === 0 ||
          !row.admins.some(
            admin =>
              admin.email.toLowerCase() === currentUser.email.toLowerCase(),
          )
        ) {
          return '-'
        }
        return (
          <LicenseButton
            dataTestId="button-manage-users"
            hasUsers={row.hasUsers}
            onClick={() => onSelectUserManagement(row.siteId)}
          >
            {row.hasUsers ? 'Manage Users' : 'Add Users'}
          </LicenseButton>
        )
      },
      sort: false,
      filter: false,
      cellClassName: 'text-center',
    },
  ]

  return (
    <LicensesTable
      columns={columns}
      filter={false}
      headThemeLight
      key="assigned-users-autotable"
      rows={updatedLicenses}
      sort
    />
  )
}

Licenses.propTypes = {
  currentUser: PropTypes.object.isRequired,
  licenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectUserManagement: PropTypes.func,
}

const mapStateToProps = ({ userProfileManager }) => {
  return {
    currentUser: userProfileManager.profile,
  }
}

export default connect(mapStateToProps)(Licenses)
