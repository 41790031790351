export const CANCEL_TX = 'Cancel'
export const SEND_TX = 'Send'
export const PENDING = 'pending'
export const ALL = 'all'
export const FAQ_LINK_URL =
  'https://support.secondstep.org/hc/en-us/articles/16638044907547'
export const FAQ_LINK = 'Need help? See FAQ'
export const displayText = {
  all: 'Everyone',
  admin: 'Administrators',
  leaderTeam: 'Leader Team',
  teacher: 'Teachers',
  pending: 'Pending Accounts',
}
