import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const Container = styled.div.attrs(({ boxShadow, dataTestId, theme }) => {
  return {
    boxShadowValue: boxShadow
      ? `1px 1px 10px 1px ${theme.colors.boxShadow}`
      : 'none',
    'data-testid': dataTestId,
  }
})`
  background-color: ${themeGet('colors.white')};
  box-shadow: ${({ boxShadowValue }) => boxShadowValue};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding: 0.75rem 1.75rem;
`

Container.propTypes = {
  boxShadow: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
}

export default Container
