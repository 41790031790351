import types from './types'

const fetchingTrainingAttempts = () => ({ type: types.FETCH_TRAINING_ATTEMPTS })

const fetchingTrainingAttemptsError = error => ({
  type: types.FETCH_TRAINING_ATTEMPTS_ERROR,
  error,
})

const fetchingTrainingAttemptsSuccess = (siteId, licenseId, program, trainingData) => ({
  type: types.FETCH_TRAINING_ATTEMPTS_SUCCESS,
  siteId,
  licenseId,
  program,
  trainingData,
})

export default {
  fetchingTrainingAttempts,
  fetchingTrainingAttemptsError,
  fetchingTrainingAttemptsSuccess,
}
