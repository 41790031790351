import React from 'react'
import PropTypes from 'prop-types'
import {
  EmailErrorEmail,
  EmailErrorListWrapper,
  EmailErrorListItem,
  EmailErrorListItemError,
} from './component.styles'
import { emailRegex } from 'lib/util'

const getEmailErrors = email => {
  const errors = []
  if (email == null) {
    errors.push('Email is empty')
    return errors
  }
  const [localPart, domainPart] = email?.split('@')

  if (!localPart) errors.push('missing username portion before "@"')
  if (!domainPart) {
    errors.push('missing domain portion after "@"')
  } else {
    const domainParts = domainPart.split('.')
    if (domainParts.length < 2 || !domainParts[1]) {
      errors.push('missing domain extension after "." in domain')
    }
  }

  if (!email.includes('@')) errors.push('missing "@" symbol')
  return errors
}

const EmailErrorList = ({ dataTestId, emails }) => {
  return (
    <EmailErrorListWrapper data-testid={`add-modal-summary-${dataTestId}`}>
      {emails?.map(email => {
        const isValidEmail = emailRegex.test(email)
        const errors = isValidEmail ? [] : getEmailErrors(email)

        return (
          <EmailErrorListItem key={email}>
            <EmailErrorEmail>{email}</EmailErrorEmail>
            {errors.length > 0 && (
              <>
                {' has the following errors:'}
                <ul>
                  {errors.map(error => (
                    <EmailErrorListItemError key={`${error}-${email}`}>
                      {error}
                    </EmailErrorListItemError>
                  ))}
                </ul>
              </>
            )}
          </EmailErrorListItem>
        )
      })}
    </EmailErrorListWrapper>
  )
}

EmailErrorList.propTypes = {
  dataTestId: PropTypes.string,
  emails: PropTypes.arrayOf(PropTypes.string).required,
}

export default EmailErrorList
