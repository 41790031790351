import React from 'react'
import PropTypes from 'prop-types'
import { CsvDownloadLink } from 'secondstep-components'
import RosteredLicenseInfo from 'components/RosteredLicenseInfo'
import { isTrainingReportAvailable, isLessonReportAvailable } from 'lib/util'
import { Skus } from '../AssignLicense/constants'

import {
  ASSIGN_LICENSES_BUTTON_TEXT,
  RENEW_LICENSES_BUTTON_TEXT,
  TRAINING_DOWNLOAD_TEXT,
  LESSON_DOWNLOAD_LINK,
  EXPIRED_LICENSES_MESSAGE,
  SELA_REPORTS_LINK,
  BASIC_LESSON_REPORT_TEXT,
  K8_PROGRESS_REPORT_TEXT,
  SELA_PROGRESS_REPORT_TEXT,
  LICENSE_CONTAINER_HEADER_TEXT,
  LICENSE_HEADER_TEXT,
  EXPIRATION_DATE_HEADER_TEXT,
  PROGRAM_REPORTS_HEADER_TEXT,
  SITE_SUMMARY_EXPIRED_MESSAGE,
  RENEW_FAQ_LINK,
} from './constants'
import {
  AssignLicensesButton,
  Expiration,
  Icon,
  LicenseName,
  LicenseRow,
  SiteName,
  SiteSummaryContainer,
  ReportLink,
  ReportLinkWrapper,
  TableHeader,
  ColumnHeader,
  RenewLicensesButton,
  SiteSummaryTable,
  StyledGraphIcon,
} from './component.styles'

import Warning from '../GlobalNav/assets/warning.svg'

const SiteSummary = ({
  bpuTrainingDownloadData,
  cpuTrainingDownloadData,
  hasUnassignedLicenses,
  redirectToLicenseManagement,
  selectedSite,
}) => {
  let progressReportText = BASIC_LESSON_REPORT_TEXT

  const productNames = {
    K8P: 'Grades K-8',
    ADL: 'SEL for Adults',
    BPU: 'Bullying Prevention Unit Training',
    CPU: 'Child Protection Unit Training',
    HSP: 'High School',
    K12P: 'Grades K-12',
  }

  const openRenewFAQLink = () => window.open(RENEW_FAQ_LINK)

  const formatProduct = (skuCode, duration, description, id) => {
    const licenseDurationInYears =
      duration > 364
        ? `${duration / 365}-Year, ID: ${id}`
        : `${duration}-Day, ID: ${id}`
    if (skuCode === Skus.MSPSkuCode) {
      progressReportText = BASIC_LESSON_REPORT_TEXT
      const newDescription = description.slice(6)
      return `${newDescription}, ${licenseDurationInYears}`
    } else if (skuCode === Skus.K8SkuCode || skuCode === Skus.K12SkuCode) {
      progressReportText = K8_PROGRESS_REPORT_TEXT
    } else if (skuCode === Skus.ADLSkuCode) {
      progressReportText = SELA_PROGRESS_REPORT_TEXT
    }
    const productName = productNames[skuCode]
    if (!productName) {
      return ''
    }
    return `${productName}, ${licenseDurationInYears}`
  }
  return (
    <>
      {selectedSite && (
        <>
          <SiteName>{selectedSite.name}</SiteName>
          <SiteSummaryContainer>
            {selectedSite.licenses.some(license => !license.isExpired) ? (
              <>
                <TableHeader>{LICENSE_CONTAINER_HEADER_TEXT}</TableHeader>
                <SiteSummaryTable>
                  <thead>
                    <tr>
                      <ColumnHeader>{LICENSE_HEADER_TEXT}</ColumnHeader>
                      <ColumnHeader>{EXPIRATION_DATE_HEADER_TEXT}</ColumnHeader>
                      <ColumnHeader>{PROGRAM_REPORTS_HEADER_TEXT}</ColumnHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSite.licenses.map(license => {
                      if (license.isExpired || license.cancelledDate) return
                      const {
                        product: { skuCode, duration, description },
                        expirationDate,
                        nsLicenseId,
                      } = license
                      const licenseSummary = formatProduct(
                        skuCode,
                        duration,
                        description,
                        nsLicenseId,
                      )
                      const {
                        showTrainingReportDownload,
                        trainingData: { data, filename, headers } = {},
                      } = isTrainingReportAvailable(
                        license,
                        bpuTrainingDownloadData,
                        cpuTrainingDownloadData,
                      )
                      const showLessonReportLink = isLessonReportAvailable(
                        license,
                      )
                      const formattedDate = new Date(
                        expirationDate,
                      ).toLocaleDateString('en-us', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })

                      return (
                        <LicenseRow key={nsLicenseId}>
                          <LicenseName>{licenseSummary}</LicenseName>
                          <Expiration>
                            {`Expire${
                              license.isExpired ? 'd' : 's'
                            } ${formattedDate}`}
                          </Expiration>
                          {showTrainingReportDownload && (
                            <ReportLinkWrapper>
                              {data?.length > 0 && (
                                <>
                                  <CsvDownloadLink
                                    data={data}
                                    data-testid="training-download-link"
                                    filename={filename}
                                    headers={headers}
                                    isAsync={false}
                                  >
                                    {TRAINING_DOWNLOAD_TEXT}
                                  </CsvDownloadLink>
                                </>
                              )}
                            </ReportLinkWrapper>
                          )}
                          {showLessonReportLink && (
                            <ReportLinkWrapper>
                              <StyledGraphIcon />
                              <ReportLink
                                dataTestId="download-report-link"
                                href={
                                  skuCode === 'ADL'
                                    ? SELA_REPORTS_LINK
                                    : LESSON_DOWNLOAD_LINK
                                }
                              >
                                {progressReportText}
                              </ReportLink>
                            </ReportLinkWrapper>
                          )}
                        </LicenseRow>
                      )
                    })}
                  </tbody>
                </SiteSummaryTable>
              </>
            ) : (
              <>
                <span>
                  <Icon alt={'Warning'} label={'WARNING'} src={Warning} />
                  Second Step
                  <sup>® </sup>
                  {SITE_SUMMARY_EXPIRED_MESSAGE}
                </span>
                <br />
                <span>{EXPIRED_LICENSES_MESSAGE}</span>
                <br />
                <RenewLicensesButton onClick={openRenewFAQLink}>
                  {RENEW_LICENSES_BUTTON_TEXT}
                </RenewLicensesButton>
                {hasUnassignedLicenses && (
                  <AssignLicensesButton onClick={redirectToLicenseManagement}>
                    {ASSIGN_LICENSES_BUTTON_TEXT}
                  </AssignLicensesButton>
                )}
              </>
            )}
          </SiteSummaryContainer>
          {selectedSite.isRostered && <RosteredLicenseInfo />}
        </>
      )}
    </>
  )
}

SiteSummary.propTypes = {
  bpuTrainingDownloadData: PropTypes.object,
  cpuTrainingDownloadData: PropTypes.object,
  hasUnassignedLicenses: PropTypes.bool,
  redirectToLicenseManagement: PropTypes.func,
  selectedSite: PropTypes.object,
}

export default SiteSummary
