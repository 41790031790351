import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TextLink } from 'secondstep-components'
import { Box } from 'grommet'

export const AssignConfirmTextBox = styled(Box)`
  // margin-top: 0;
`

export const AssignConfirmLabel = styled.label`
  display: flex;
`

export const AssignConfirmTextLink = styled(TextLink)`
  text-decoration: underline;
  color: ${themeGet('colors.darkGray')};

  &:hover {
    text-decoration: underline;
    color: ${themeGet('colors.buttonHover')};
  }
`

export const Check = styled.input.attrs({
  type: 'checkbox',
})`
  margin-right: 0.5rem;
`
