import styled from 'styled-components'

export const LicensesStatusBox = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
`
