import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Notification from 'components/Notification'
import { actions as notificationActions } from 'store/notificationsManager'
import { NotificationList, NotificationWrapper } from './component.styles'

// TODO: Refactor to Container/Component pair with this just being connect

const NotificationContainer = ({ notifications, removeNotification }) => {
  return (
    <NotificationList>
      <NotificationWrapper>
        {notifications.map(notification => {
          const onDismiss = () => removeNotification(notification)
          return (
            <Notification
              autoDismissTimeout={notification.timeout}
              dismissable={notification.dismissable}
              key={notification.id}
              shadow
              {...notification}
              onDismiss={onDismiss}
            />
          )
        })}
      </NotificationWrapper>
    </NotificationList>
  )
}

NotificationContainer.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  removeNotification: PropTypes.func.isRequired,
}

const mapStateToProps = ({ notificationsManager }) => ({
  notifications: notificationsManager,
})

const mapDispatchToProps = {
  removeNotification: notificationActions.removeNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer)
