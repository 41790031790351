import styled from 'styled-components'

export const RegTrademarkIconSemibold = styled.sup`
  position: relative;
  font-size: 55%;
  top: -0.4375rem;
`

export const RegTrademarkIconBold = styled.sup`
  position: relative;
  font-size: 175%;
  top: 0.8rem;
`
