import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo } from 'secondstep-components'

export const UsersCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  font-size: 1rem;
  margin-bottom: -0.4063rem;
  color: ${themeGet('colors.backgroundBody')};
  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1rem;
  }
`

export const UserCountHeader = styled(HeaderTwo)`
  font-size: 1.3125rem;
  font-family: ${themeGet('headerFontFamilySemibold')};
  color: ${themeGet('colors.darkBlue')};
`

export const UserCount = styled.span`
  display: inline-block;
  font-family: ${themeGet('fontFamilySemibold')};
`

export const Wrapper = styled.div``
