import types from './types'

export const initialState = {
  areSitesInitialized: false,
  isSiteAdmin: false,
  error: null,
  // TODO: Update to user action specific message when site users are fully wired up
  message: 'Loading',
  sites: {
    data: null,
    isInitialized: false,
    isLoading: false,
  },
  // TODO: Update this reducer to store users by site
  siteUsers: {
    addBulkUsersResponse: null,
    data: null,
    isAddBulkUsersCompleted: false,
    isLoading: false,
    isDeleting: false,
    isUpdating: false,
  },
  k8SiteUsers: {
    data: null,
    isLoading: false,
  },
  remindUsers: {
    success: null,
    isLoading: false,
  },
}

const productOrder = ['K8P', 'ADL', 'BPU', 'CPU']

const sortLicensesBySku = (licenseA, licenseB) => {
  return (
    productOrder.indexOf(licenseA.product?.sku) -
    productOrder.indexOf(licenseB.product?.sku)
  )
}

const sortListByName = (siteA, siteB) => {
  if (siteA.name < siteB.name) {
    return -1
  }
  if (siteA.name > siteB.name) {
    return 1
  }
  return 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_SITES:
    case types.FETCHING_SITES_SUCCESS:
    case types.FETCHING_SITES_ERROR:
      if (action.type === types.FETCHING_SITES_SUCCESS) {
        action.sites.forEach(site => {
          site.licenses.sort(sortLicensesBySku)
        })
      }
      return {
        ...state,
        error: action.type === types.FETCHING_SITES_ERROR ? action.error : null,
        isSiteAdmin:
          action.type === types.FETCHING_SITES_SUCCESS
            ? action.sites.length > 0
            : state.isSiteAdmin,
        sites: {
          data:
            action.type === types.FETCHING_SITES_SUCCESS
              ? action.sites.sort(sortListByName)
              : state.sites.data,
          isInitialized:
            action.type === types.FETCHING_SITES_ERROR ||
            action.type === types.FETCHING_SITES_SUCCESS,
          isLoading: action.type === types.FETCHING_SITES,
        },
      }

    case types.FETCHING_K8_SITE_USERS:
    case types.FETCHING_K8_SITE_USERS_SUCCESS:
    case types.FETCHING_K8_SITE_USERS_ERROR:
      return {
        ...state,
        error:
          action.type === types.FETCHING_K8_SITE_USERS_ERROR
            ? action.error
            : null,
        k8SiteUsers: {
          ...state.k8SiteUsers,
          data:
            action.type === types.FETCHING_K8_SITE_USERS_SUCCESS
              ? {
                  ...state.k8SiteUsers.data,
                  [action.siteId]: action.k8SiteUsers,
                }
              : state.k8SiteUsers.data,
          isLoading: action.type === types.FETCHING_K8_SITE_USERS,
        },
      }

    case types.FETCHING_SITE_USERS:
    case types.FETCHING_SITE_USERS_SUCCESS:
    case types.FETCHING_SITE_USERS_ERROR:
      return {
        ...state,
        error:
          action.type === types.FETCHING_SITE_USERS_ERROR ? action.error : null,
        siteUsers: {
          ...state.siteUsers,
          data:
            action.type === types.FETCHING_SITE_USERS_SUCCESS
              ? {
                  ...state.siteUsers.data,
                  [action.siteId]: action.siteUsers,
                }
              : state.siteUsers.data,
          isLoading: action.type === types.FETCHING_SITE_USERS,
        },
      }

    case types.CREATING_SITE_USER:
    case types.CREATING_SITE_USER_SUCCESS:
    case types.CREATING_SITE_USER_ERROR:
      return {
        ...state,
        error:
          action.type === types.CREATING_SITE_USER_ERROR ? action.error : null,
        siteUsers: {
          ...state.siteUsers,
          data:
            action.type === types.CREATING_SITE_USER_SUCCESS
              ? {
                  ...state.siteUsers.data,
                  [action.siteUser.siteId]: [
                    ...state.siteUsers.data[action.siteUser.siteId],
                    action.siteUser,
                  ],
                }
              : state.siteUsers.data,
          isLoading: action.type === types.CREATING_SITE_USER,
        },
      }

    case types.CREATING_SITE_USERS:
    case types.CREATING_SITE_USERS_SUCCESS:
    case types.CREATING_SITE_USERS_ERROR:
      const siteId = action?.siteId
      return {
        ...state,
        error:
          action.type === types.CREATING_SITE_USERS_ERROR ? action.error : null,
        siteUsers: {
          ...state.siteUsers,
          addBulkUsersResponse:
            action.type === types.CREATING_SITE_USERS_SUCCESS
              ? {
                  newSiteUsers: action.newSiteUsers,
                  failedSiteUsers: action.failedSiteUsers,
                  invalidSiteUsers: action.invalidSiteUsers,
                  sendEmail: action.sendEmail,
                }
              : null,
          data:
            action.type === types.CREATING_SITE_USERS_SUCCESS
              ? {
                  ...state.siteUsers.data,
                  [siteId]: [
                    ...state.siteUsers.data[siteId],
                    ...action.newSiteUsers,
                  ],
                }
              : state.siteUsers.data,
          isLoading: action.type === types.CREATING_SITE_USERS,
          isAddBulkUsersCompleted: action.type !== types.CREATING_SITE_USERS,
        },
      }

    case types.REMINDING_SITE_USER:
    case types.REMINDING_SITE_USER_SUCCESS:
    case types.REMINDING_SITE_USER_ERROR:
      return {
        ...state,
        error:
          action.type === types.REMINDING_SITE_USER_ERROR ? action.error : null,
        remindUsers: {
          success: action.type === types.REMINDING_SITE_USER_SUCCESS,
          isLoading: action.type === types.REMINDING_SITE_USER,
        },
      }
    case types.UPDATING_SITE_USER:
    case types.UPDATING_SITE_USER_SUCCESS:
    case types.UPDATING_SITE_USER_ERROR:
      let newSiteUsers
      if (action.type === types.UPDATING_SITE_USER_SUCCESS) {
        newSiteUsers = [...state.siteUsers.data[action.siteUser.siteId]]

        const siteUserIndex = newSiteUsers.findIndex(
          siteUser => siteUser.id === action.siteUser.id,
        )
        newSiteUsers[siteUserIndex] = action.siteUser
      }
      return {
        ...state,
        error:
          action.type === types.UPDATING_SITE_USER_ERROR ? action.error : null,
        siteUsers: {
          ...state.siteUsers,
          data:
            action.type === types.UPDATING_SITE_USER_SUCCESS
              ? {
                  ...state.siteUsers.data,
                  [action.siteUser.siteId]: newSiteUsers.filter(
                    u => u.isActive === true,
                  ),
                }
              : state.siteUsers.data,
          isUpdating: action.type === types.UPDATING_SITE_USER,
        },
      }

    case types.RESET_BULK_ADD_USERS:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          isAddBulkUsersCompleted: false,
          addBulkUsersResponse: null,
        },
      }

    default:
      return state
  }
}
