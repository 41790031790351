import { domains } from 'config/env'

export const ASSIGN_LICENSES_BUTTON_TEXT = 'ASSIGN LICENSES'
export const EXPIRED_LESSON_TEXT = `This License is Expired`
export const EXPIRED_LICENSES_MESSAGE = `To resume access, renew and assign licenses.`
export const NON_EXPIRED_LICENSE_PREFIX = 'License expires on '
export const TRAINING_DOWNLOAD_TEXT = 'Download Training Data (CSV)'
export const BASIC_LESSON_REPORT_TEXT = 'Lesson Progess Reports'
export const K8_PROGRESS_REPORT_TEXT = 'K8 Digital Progress Report'
export const SELA_PROGRESS_REPORT_TEXT = 'SEL for Adults Progress Report'
export const LESSON_DOWNLOAD_LINK = domains.LEARN_APP_DOMAIN + '/reports'
export const SELA_REPORTS_LINK = LESSON_DOWNLOAD_LINK + '/sel-for-adults/'
export const LICENSE_CONTAINER_HEADER_TEXT = 'Second Step Programs'
export const LICENSE_HEADER_TEXT = 'License'
export const EXPIRATION_DATE_HEADER_TEXT = 'Expiration Date'
export const PROGRAM_REPORTS_HEADER_TEXT = 'Program Reports'
export const RENEW_LICENSES_BUTTON_TEXT = 'RENEW LICENSES'
export const SITE_SUMMARY_EXPIRED_MESSAGE = 'licenses have expired'
export const RENEW_FAQ_LINK =
  'https://support.secondstep.org/hc/en-us/articles/7704410319899-How-do-I-renew-my-Second-Step-licenses-'
