import React from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { LicensesExpiredTable } from './component.styles'
import { displayDate, displayAdmins } from 'lib/util'
import ProductDisplay from '../ProductDisplay/component'

const LicensesExpired = ({ licenses, currentUser }) => {
  const updatedLicenses = licenses.map((license, licenseIndex) => {
    return {
      ...license,
      dataTestId: `expired-license-subscriptionId-${
        license.subscriptionId
      }-sku-${license.sku}-${licenseIndex + 1}`,
    }
  })
  const columns = [
    {
      title: 'School',
      fieldValue: ({ siteName }) => `${siteName || 'License Not Assigned'}`,
    },
    {
      title: 'Product',
      fieldValue: row => ProductDisplay(row),
      sortValue: row => row.product?.description,
    },
    {
      title: 'License Id',
      fieldValue: row => row.nsLicenseId || '-',
      cellClassName: 'text-center',
    },
    {
      title: 'Subscription Id',
      fieldValue: 'nsSubscriptionId',
      cellClassName: 'text-center',
    },
    {
      title: 'Expiration Date',
      fieldValue: row => displayDate(row.expirationDate) || '-',
      sortValue: 'expirationDate',
    },
    {
      title: 'Administrators',
      fieldValue: row => displayAdmins(row, currentUser.email) || '-',
      sortValue: row => displayAdmins(row, currentUser.email) || '-',
    },
  ]

  return (
    <LicensesExpiredTable
      columns={columns}
      filter={false}
      headThemeLight
      key="expired-users-autotable"
      rows={updatedLicenses}
      sort
    />
  )
}

LicensesExpired.propTypes = {
  currentUser: PropTypes.object.isRequired,
  licenses: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = ({ userProfileManager }) => {
  return {
    currentUser: userProfileManager.profile,
  }
}

export default connect(mapStateToProps)(LicensesExpired)
