import PropTypes from 'prop-types'
import { Modal } from './component.styles'
import { CloseButton } from 'secondstep-components'
import LUMConfirmationDialog from 'components/LUMConfirmationDialog'

const StutterStepModal = ({ header, isOpen, onCancel, onConfirm, text }) => {
  return (
    <Modal dataTestId="confirmation-modal" isModalOpen={isOpen}>
      <CloseButton
        dataTestId="confirmation-modal-close-button"
        onClick={onCancel}
      />
      <LUMConfirmationDialog
        confirmText="Confirm"
        header={header}
        onCancel={onCancel}
        onConfirm={onConfirm}
        showIcon={false}
        text={text}
      />
    </Modal>
  )
}

StutterStepModal.propTypes = {
  header: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.string,
}

export default StutterStepModal
