import React from 'react'
import PropTypes from 'prop-types'
import { pluralize } from 'lib/util'
import {
  ACTIVE,
  ADMINISTRATOR,
  LEADER_TEAM,
  PENDING,
  TEACHER,
  USER_COUNT_HEADER_TEXT,
} from './constants'
import {
  UserCount,
  UsersCountContainer,
  UserCountHeader,
  Wrapper,
} from './component.styles'
import { ErrorBoundary } from 'secondstep-components'

const SiteUserCountInfo = ({ hasSelaLicense, userCounts }) => {
  const {
    admin,
    adminActive,
    adminPending,
    all,
    leaderTeam,
    leaderTeamActive,
    leaderTeamPending,
    teacher,
    teacherActive,
    teacherPending,
  } = userCounts || {}
  const adminTotalCount = `${admin} ${pluralize(admin, ADMINISTRATOR)}:`
  const adminCountDetail = `${adminActive} ${ACTIVE} • ${adminPending} ${PENDING}`
  const leaderTeamTotalCount = `${leaderTeam} ${LEADER_TEAM}:`
  const leaderTeamDetail = `${leaderTeamActive} ${ACTIVE} • ${leaderTeamPending} ${PENDING}`
  const teacherTotalCount = `${teacher} ${pluralize(teacher, TEACHER)}:`
  const teacherCountDetail = `${teacherActive} ${ACTIVE} • ${teacherPending} ${PENDING}`
  const userCountHeader = `${all} ${USER_COUNT_HEADER_TEXT}`

  return (
    <ErrorBoundary>
      <Wrapper>
        <UserCountHeader>{userCountHeader}</UserCountHeader>
        {userCounts && (
          <UsersCountContainer>
            <div data-testid="site-user-add-remind-active-users">
              <UserCount>{adminTotalCount}</UserCount> {adminCountDetail}
            </div>
            {hasSelaLicense && (
              <div>
                <UserCount>{leaderTeamTotalCount}</UserCount> {leaderTeamDetail}
              </div>
            )}
            <div data-testid="site-user-add-remind-non-active-users">
              <UserCount>{teacherTotalCount}</UserCount> {teacherCountDetail}
            </div>
          </UsersCountContainer>
        )}
      </Wrapper>
    </ErrorBoundary>
  )
}

SiteUserCountInfo.propTypes = {
  hasSelaLicense: PropTypes.bool,
  userCounts: PropTypes.object,
}

export default SiteUserCountInfo
