import * as yup from 'yup'
import { MAX_EMAILS_COUNT } from './constants'
import { emailRegex } from './util'

export const userProfileSchema = yup.object().shape({
  city: yup
    .string()
    .trim()
    .required('Choose your City'),
  country: yup
    .string()
    .trim()
    .required('Choose your country'),
  displayName: yup.string().trim(),
  email: yup
    .string()
    .trim()
    .email('Enter a valid Email Address')
    .required('Enter your Email Address'),
  firstName: yup
    .string()
    .max(50, "Enter a First Name that's 50 characters or fewer")
    .trim()
    .required('Enter your First Name'),
  jobTitle: yup
    .string()
    .trim()
    .required('Enter your Job Title'),
  lastName: yup
    .string()
    .max(50, "Enter a Last Name that's 50 characters or fewer")
    .trim()
    .required('Enter your Last Name'),
  siteId: yup.string().required(),
  siteName: yup.string().when('siteId', {
    is: siteId => siteId === 'UNLISTED',
    then: yup
      .string()
      .trim()
      .required('Enter your School Name'),
    otherwise: yup
      .string()
      .trim()
      .nullable(),
  }),
  region: yup.string().trim(),
})

export const licenseSchema = yup.object().shape({
  isCanceled: yup.boolean(),
  canceledDate: yup.date().when('isCanceled', {
    is: true,
    then: yup.date().required(),
    otherwise: yup.date().notRequired(),
  }),
  maxPtkCount: yup.number().min(0),
  maxSeatCount: yup.number().min(0),
  maxClassCount: yup
    .number()
    .max(1)
    .min(-1),
  sku: yup
    .number()
    .max(999999, 'Sku code too high')
    .min(900000, 'Sku code too low')
    .required(),
})

export const singleAddUserSchema = yup.object().shape({
  selectedRole: yup.string().required(),
  sendNotification: yup.string().required(),
  emails: yup
    .string()
    .max(256, 'Please enter 256 characters or less')
    .trim()
    .required('Please enter an email address')
    .test('email', function(value) {
      const isValidEmail = emailRegex.test(value)

      if (!isValidEmail) {
        const missingParts = []
        const [localPart, domainPart] = value.split('@')

        if (!localPart) {
          missingParts.push(`missing username portion in before "@"`)
        }
        if (!domainPart) {
          missingParts.push(`missing domain portion in after "@"`)
        } else {
          const domainParts = domainPart.split('.')
          if (domainParts.length < 2 || !domainParts[1]) {
            missingParts.push('missing domain extension after "." in domain')
          }
        }

        if (!value.includes('@')) {
          missingParts.push('missing "@" symbol')
        }
        return this.createError({
          message: `Invalid email address in '${value}': 
            ${missingParts.join(', ')}`,
        })
      } else return true
    }),
  otherwise: yup.string(),
})

export const bulkAddUserSchema = yup.object().shape({
  selectedRole: yup.string().required(),
  sendNotification: yup.string().required(),
  emails: yup
    .string()
    .required('Please enter at least one email address')
    // the validation function needs to be a regular function so that
    // 'this' is bound to the yup test context
    .test('emails', function(value) {
      const string = value || ''
      const emails = string
        .split(/,|;/)
        .map(email => email.trim())
        .filter(email => !!email)

      if (emails.length > MAX_EMAILS_COUNT) {
        return this.createError({
          message: "You've exceeded the maximum number of email addresses",
        })
      }
      return true
    }),
  otherwise: yup.string(),
})
