import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ContainedButton, HeaderTwo, BodyCopy } from 'secondstep-components'

export const RootWrapper = styled.div`
  padding: 2rem 2.25rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 29.688rem;
  box-sizing: border-box;
`
export const FaqLinkContainer = styled.div`
  text-align: right;
  margin-top: 1rem;
`

export const FaqLink = styled.a`
  text-decoration: none;
  font-family: ${themeGet('fontFamilySemibold')};
  color: ${themeGet('colors.blue')};
  &:hover {
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.25rem;
  min-height: 2.6875rem;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const CancelButton = styled(ContainedButton)`
  background-color: transparent;
  color: ${themeGet('colors.blue')};
  min-width: 8rem;
  padding: 0.25rem 2.57rem 0 2.57rem;
  &:not([disabled]):hover {
    border-color: ${themeGet('colors.blue')};
    background-color: ${themeGet('colors.blue')};
    color: ${themeGet('colors.white')};
  }

  &:focus {
    outline-color: ${themeGet('colors.darkBlue')};
    outline-style: dashed;
    outline-width: 2.5px;
    outline-offset: 5px;
  }
`

export const ConfirmButton = styled(ContainedButton)`
  margin-left: 1rem;
  min-width: 8rem;
  padding: 0.25rem 2.25rem 0 2.25rem;
  &:not([disabled]):hover {
    background-color: ${themeGet('colors.secondStepBlue')};
    border-color: ${themeGet('colors.secondStepBlue')};
  }

  &:focus {
    outline-color: ${themeGet('colors.darkBlue')};
    outline-style: dashed;
    outline-width: 2.5px;
    outline-offset: 5px;
  }
`

export const Divider = styled.hr`
  margin-top: 0.9375rem;
  margin-bottom: 0rem;
  width: 100%;
`

export const Header = styled(HeaderTwo)`
  font-size: 1.625rem;
  font-family: ${themeGet('fontFamilyHeadingBold')};
  color: ${themeGet('colors.secondStepBlue')};
`
export const Paragraph = styled(BodyCopy)`
  font-size: 1.125rem;
  line-height: 1.438rem;
  font-family: ${themeGet('fontFamilySemibold')};
  color: ${themeGet('colors.darkGray')};
  margin-top: 1.25rem;
`
export const Trademark = styled.span`
  font-size: 2rem;
`
