import React from 'react'
import PropTypes from 'prop-types'
import { displayDate } from 'lib/util'
import {
  LicenseUnassignedContainedButton,
  UnassignedLicensesTable,
} from './component.styles'
import ProductDisplay from '../ProductDisplay/component'

const LicensesUnassigned = ({ licenses, onSelectLicense, onSelectAssign }) => {
  const updatedLicenses = licenses.map((license, licenseIndex) => {
    return {
      ...license,
      dataTestId: `unassigned-license-subscriptionId-${
        license.subscriptionId
      }-sku-${license.sku}-${licenseIndex + 1}`,
    }
  })
  const columns = [
    {
      title: 'Product',
      fieldValue: row => ProductDisplay(row),
      sortValue: row => row.product?.description,
    },
    {
      title: 'Subscription Id',
      fieldValue: 'nsSubscriptionId',
      cellClassName: 'text-center',
    },
    {
      title: 'Expiration Date',
      fieldValue: row => displayDate(row.expirationDate),
      sortValue: 'expirationDate',
    },
    {
      title: '',
      fieldValue: row => {
        if (!onSelectLicense) return null
        const { dataTestId } = row || {}
        return (
          <LicenseUnassignedContainedButton
            dataTestId={`button-assign-${dataTestId}`}
            onClick={() => {
              onSelectLicense(row)
              onSelectAssign()
            }}
          >
            Assign License
          </LicenseUnassignedContainedButton>
        )
      },
      sort: false,
      filter: false,
      cellClassName: 'text-center',
    },
  ]

  const defaultSort = {
    columnTitle: 'Expiration Date',
    direction: 'asc',
  }

  return (
    <UnassignedLicensesTable
      columns={columns}
      defaultSort={defaultSort}
      filter={false}
      headThemeLight
      key="unassigned-users-autotable"
      rows={updatedLicenses}
      sort
    />
  )
}

LicensesUnassigned.propTypes = {
  licenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectAssign: PropTypes.func,
  onSelectLicense: PropTypes.func,
}

export default LicensesUnassigned
