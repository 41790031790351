import { paths } from '../../components/LUMRouter/paths'

export const LICENSE_MANAGEMENT_HEADER = 'License Management'
export const ASSIGN_LICENSE_HEADER = 'Assign License'
export const LICENSE_MANAGEMENT_ASSIGNED_TEXT =
  'To give staff and administrators access to programs, add them to the appropriate license.'
export const LICENSE_MANAGEMENT_UNASSIGNED_TEXT =
  'To activate a program for a school or organization, assign a license to that site.'
export const LICENSE_MANAGEMENT_EXPIRED_TEXT = `Go to <a href="${paths.EXPIRED_LICENSE_INSTRUCTIONS}">renewal instructions</a> to renew.`
export const ASSIGN_LICENSE_MANAGEMENT_TEXT =
  'Find a school or organization to assign:'
export const RETURN_TO_LICENSE_LIST = '< Back to All Licenses'
export const BACK_TO_DASHBOARD = '< Back to Dashboard'
