const BASE_LICENSE_MANAGEMENT = '/license-management'
const ASSIGNED_LICENSES = `${BASE_LICENSE_MANAGEMENT}/assigned-licenses`
const UNASSIGNED_LICENSES = `${BASE_LICENSE_MANAGEMENT}/unassigned-licenses`
const EXPIRED_LICENSES = `${BASE_LICENSE_MANAGEMENT}/expired-licenses`
const ASSIGN_LICENSE = `${BASE_LICENSE_MANAGEMENT}/assign-license`
const EXPIRED_LICENSE_INSTRUCTIONS =
  'https://support.secondstep.org/hc/en-us/articles/7704410319899-How-do-I-renew-my-Second-Step-licenses'

const USER_MANAGEMENT = '/user-management/:siteId?'

export const paths = {
  ASSIGNED_LICENSES,
  ASSIGN_LICENSE,
  BASE_LICENSE_MANAGEMENT,
  USER_MANAGEMENT,
  EXPIRED_LICENSES,
  EXPIRED_LICENSE_INSTRUCTIONS,
  UNASSIGNED_LICENSES,
}
