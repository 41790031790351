const FETCHING_K8_SITE_USERS = 'FETCHING_K8_SITE_USERS'
const FETCHING_K8_SITE_USERS_ERROR = 'FETCHING_K8_SITE_USERS_ERROR'
const FETCHING_K8_SITE_USERS_SUCCESS = 'FETCHING_K8_SITE_USERS_SUCCESS'

const FETCHING_SITES = 'FETCHING_SITES'
const FETCHING_SITES_SUCCESS = 'FETCHING_SITES_SUCCESS'
const FETCHING_SITES_ERROR = 'FETCHING_SITES_ERROR'

const FETCHING_SITE_USERS = `FETCHING_SITE_USERS`
const FETCHING_SITE_USERS_SUCCESS = `FETCHING_SITE_USERS_SUCCESS`
const FETCHING_SITE_USERS_ERROR = `FETCHING_SITE_USERS_ERROR`

const CREATING_SITE_USER = 'CREATING_SITE_USER'
const CREATING_SITE_USER_SUCCESS = 'CREATING_SITE_USER_SUCCESS'
const CREATING_SITE_USER_ERROR = 'CREATING_SITE_USER_ERROR'

const CREATING_SITE_USERS = 'CREATING_SITE_USERS'
const CREATING_SITE_USERS_SUCCESS = 'CREATING_SITE_USERS_SUCCESS'
const CREATING_SITE_USERS_ERROR = 'CREATING_SITE_USERS_ERROR'

const UPDATING_SITE_USER = 'UPDATING_SITE_USER'
const UPDATING_SITE_USER_SUCCESS = 'UPDATING_SITE_USER_SUCCESS'
const UPDATING_SITE_USER_ERROR = 'UPDATING_SITE_USER_ERROR'

const REMINDING_SITE_USER = 'REMINDING_SITE_USER'
const REMINDING_SITE_USER_SUCCESS = 'REMINDING_SITE_USER_SUCCESS'
const REMINDING_SITE_USER_ERROR = 'REMINDING_SITE_USER_ERROR'

const REMINDING_SITE_USERS = 'REMINDING_SITE_USERS'
const REMINDING_SITE_USERS_SUCCESS = 'REMINDING_SITE_USERS_SUCCESS'
const REMINDING_SITE_USERS_ERROR = 'REMINDING_SITE_USERS_ERROR'

const RESET_BULK_ADD_USERS = 'RESET_BULK_ADD_USERS'

export default {
  CREATING_SITE_USER,
  CREATING_SITE_USER_SUCCESS,
  CREATING_SITE_USER_ERROR,
  CREATING_SITE_USERS,
  CREATING_SITE_USERS_SUCCESS,
  CREATING_SITE_USERS_ERROR,
  FETCHING_K8_SITE_USERS,
  FETCHING_K8_SITE_USERS_ERROR,
  FETCHING_K8_SITE_USERS_SUCCESS,
  FETCHING_SITES,
  FETCHING_SITES_SUCCESS,
  FETCHING_SITES_ERROR,
  FETCHING_SITE_USERS,
  FETCHING_SITE_USERS_SUCCESS,
  FETCHING_SITE_USERS_ERROR,
  UPDATING_SITE_USER,
  UPDATING_SITE_USER_SUCCESS,
  UPDATING_SITE_USER_ERROR,
  REMINDING_SITE_USER,
  REMINDING_SITE_USER_SUCCESS,
  REMINDING_SITE_USER_ERROR,
  REMINDING_SITE_USERS,
  REMINDING_SITE_USERS_SUCCESS,
  REMINDING_SITE_USERS_ERROR,
  RESET_BULK_ADD_USERS,
}
