import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Tooltip, AutoTable } from 'secondstep-components'

export const RoleDescription = styled.p`
  padding-bottom: 0.563rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
`

export const RolesTooltip = styled(Tooltip)`
  div {
    top: 2rem;
    left: 4.688rem;
    padding: 0.875rem 0.75rem 0.313rem;
  }
`

export const NameAndEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Name = styled.span`
  font-weight: bold;
`

export const Email = styled.span``

export const TooltipIcon = styled.i.attrs(() => ({
  className: `fas fa-question-circle`,
}))``

export const UserActionsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const SiteUserTable = styled(AutoTable)`
  color: ${themeGet('colors.darkGray')};
  border: 0.13rem solid ${themeGet('colors.borderTable')};
  background-color: white;
  margin-top: 1rem;

  tbody tr td {
    border-bottom: 1px solid ${themeGet('colors.gray400')};
    line-height: 24px;
  }

  [class*='AutoTableHeaderContent'] div {
    width: 10px;
    height: 5px;
    padding-top: 0;
    padding-right: 5px;
  }

  [class*='AutoTableHeaderTh'] {
    border-bottom: 1px solid ${themeGet('colors.gray400')};
    padding: 0;
    ${themeGet('breakpoints.mobile')} {
      padding-left: ${themeGet('spacing.blue16_1rem')};
      padding-right: ${themeGet('spacing.blue16_1rem')};
    }
    background-color: ${themeGet('colors.borderTable')};
  }

  .userActionsHeader div {
    height: 20px;
    display: block;
  }

  [class*='AutoTableHeaderTh']:last-child {
    padding-right: 0.9rem;
  }

  [class*='AutoTableHeaderTh']:first-child {
    padding-left: 0.9rem;
  }

  [class*='AutoTableHeaderSpan'] {
    margin-right: ${themeGet('spacing.pink8_0_5rem')};
    padding: 0;
  }

  [class*='HeaderSortContainer'] {
    z-index: 1;
  }
`
