import { createSelector } from '@reduxjs/toolkit'

const selectSiteUsersBySiteId = createSelector(
  [
    state => state.sitesManager.siteUsers.data,
    state => state.sitesManager.k8SiteUsers.data,
    (_, siteId) => siteId,
    (_, __, isK8Site) => isK8Site,
  ],
  (siteUsersBySiteId, k8SiteUsersBySiteId, selectedSiteId, isK8Site) => {
    if (
      !siteUsersBySiteId ||
      (!k8SiteUsersBySiteId && isK8Site) ||
      !selectedSiteId
    ) {
      return null
    }

    let siteUsers = siteUsersBySiteId[selectedSiteId]
    if (isK8Site) {
      const k8SiteUsers = k8SiteUsersBySiteId[selectedSiteId]
      if (Array.isArray(k8SiteUsers) && Array.isArray(siteUsers)) {
        const emailToClassCountMap = new Map()
        k8SiteUsers.forEach(u => {
          emailToClassCountMap.set(u.email, u.classCount)
        })
        siteUsers = siteUsers.map(u => {
          const { inviteEmail } = u
          return {
            ...u,
            classesCreated: emailToClassCountMap.get(inviteEmail) || 0,
          }
        })
      }
    }

    return siteUsers
  },
)
export default {
  selectSiteUsersBySiteId,
}
