import axios from 'axios'
import { domains } from 'config/env'
import { Logger } from 'logging'
import Auth0Manager from 'auth/utils/auth0Manager'

const baseApiAddress = `${domains.SECONDSTEP_API}/`
const sitesBase = '/sites'

const logEvent = ({ method, url, data }) => {
  const eventProperties = { method }

  if (url) {
    eventProperties.url = url
  }
  if (data) {
    eventProperties.data = data
  }

  Logger.logEvent({
    name: 'Second Step API',
    properties: eventProperties,
  })
}

export const createClient = customAddress => {
  const _api = axios.create({
    baseURL: customAddress || baseApiAddress,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    },
  })

  _api.interceptors.request.use(
    async request => {
      const token = await Auth0Manager.getAccessToken()
      request.headers.Authorization = `Bearer ${token}`
      logEvent(request)
      return request
    },
    error => {
      return Promise.reject(error)
    },
  )

  _api.interceptors.response.use(
    response => {
      return response
    },

    error => {
      if (error?.response?.status === 401) {
        location.replace(window.location.origin)
      }
      return Promise.reject(error)
    },
  )

  return {
    baseApiAddress: customAddress || baseApiAddress,
    getCountries: () => {
      const url = '/location/countries'
      return _api.get(url)
    },

    getRegions: country => {
      const url = `/location/regions?country=${country}`
      return _api.get(url)
    },

    getCitiesWithRegion: (country, region) => {
      const url = `location/cities?country=${country}&region=${region}`
      return _api.get(url)
    },

    getCitiesWithoutRegion: country => {
      const url = `location/cities?country=${country}`
      return _api.get(url)
    },

    getNSCustomersWithRegion: (city, country, region) => {
      const url = `/netsuitecustomers?country=${country}&region=${region}&city=${city}`
      return _api.get(url)
    },

    getNSCustomersWithoutRegion: (city, country) => {
      const url = `netsuitecustomers?country=${country}&city=${city}`
      return _api.get(url)
    },

    getUserProfile: () => {
      const url = '/profile/me'
      return _api.get(url)
    },

    getUserAccess: () => {
      const url = '/access/me'
      return _api.get(url)
    },

    getLicenses: () => {
      const url = `/licenses/me`
      return _api.get(url)
    },

    postLicenseAssignment: licenseAssignment => {
      const url = '/licenses'
      return _api.post(url, licenseAssignment)
    },

    getSites: () => {
      const url = `${sitesBase}/me`
      return _api.get(url)
    },

    getSiteUsers: siteId => {
      const url = `${sitesBase}/${siteId}/siteusers`
      return _api.get(url)
    },

    createSiteUser: (siteUser, sendEmail) => {
      const url = `${sitesBase}/${siteUser.siteId}/siteusers?sendEmail=${sendEmail}`
      return _api.post(url, siteUser)
    },

    createSiteUsers: (siteId, siteUsers, sendEmail) => {
      const url = `${sitesBase}/${siteId}/siteusers?sendEmail=${sendEmail}`
      return _api.patch(url, siteUsers)
    },

    updateSiteUser: siteUser => {
      const url = `${sitesBase}/${siteUser.siteId}/siteusers/${siteUser.id}`
      return _api.put(url, siteUser)
    },

    remindSiteUser: siteUser => {
      const url = `${sitesBase}/${siteUser.siteId}/siteusers/${siteUser.id}/remind`
      return _api.post(url)
    },

    remindSiteUsers: (siteId, emailFilters) => {
      const url = `${sitesBase}/${siteId}/siteusers/remind`
      return _api.post(url, emailFilters)
    },
  }
}

const api = createClient()
export default api
