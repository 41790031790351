import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import { ErrorBoundary } from 'secondstep-components'
import LUMRouter from 'components/LUMRouter'
import { domains } from 'config/env'

export const App = ({ pushTo, userProfile }) => {
  const { profile } = userProfile || {}
  const hasUserProfile = profile && !isEmpty(profile)
  if (hasUserProfile && profile?.isFirstTimeUser) {
    window.location.href = `${domains.APP_DOMAIN}/account-details/first-time`
    return null
  }

  if (hasUserProfile && !profile?.hasUpdatedProfile) {
    window.location.href = `${domains.APP_DOMAIN}/account-details/prompt-to-complete`
    return null
  }
  return (
    <ErrorBoundary>
      {profile ? <LUMRouter pushTo={pushTo} /> : null}
    </ErrorBoundary>
  )
}

const mapStateToProps = ({ router, userProfileManager }) => {
  return {
    router: router,
    userProfile: userProfileManager,
  }
}

// TODO: Update this once Secondstep layout is updated
export default withRouter(connect(mapStateToProps)(App))

App.propTypes = {
  pushTo: PropTypes.func,
  userProfile: PropTypes.object.isRequired,
}
