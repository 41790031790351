export const LOADING_TEXT = 'Loading...'
export const NO_SITES_FOUND_TX = 'No sites found.'
export const REDIRECT_TO_DASHBOARD = '< Dashboard'
export const REDIRECT_TO_LICENSE_MANAGEMENT = 'License Management'
export const CONFIRM_DIALOG_SUBTITLE =
  'Invited users will receive another email notification reminding them to create their SecondStep account so they can be added to the license'
export const FILTER_USER_MAP = {
  all: 'All Users',
  admin: 'Administrators',
  teacher: 'Teachers',
  leaderTeam: 'Leader Team',
  pending: 'Pending Accounts',
  noClasses: 'No Classes Created',
}
