import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ContainedButton,
  DownloadIcon,
  GraphIcon,
  OutlinedButton,
  TextLink,
  WarningIcon,
} from 'secondstep-components'

export const AssignLicensesButton = styled(OutlinedButton)`
  margin-right: 1rem;
  :disabled {
    background: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    color: ${themeGet('colors.white')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.primaryBackground')};
    border-color: ${themeGet('colors.buttons.primaryBackground')};
    color: ${themeGet('colors.white')};
  }

  ${themeGet('breakpoints.medium')} {
    margin-right: 0.5rem;
  }
`

export const AlertIcon = styled(WarningIcon)`
  margin-bottom: 1.35rem;
`

export const Icon = styled.img``

export const SiteSummaryContainer = styled.div`
  background-color: ${themeGet('colors.gray100')};
  margin-bottom: 1rem;
  padding: 1.6rem 1.875rem 1.875rem;

  span {
    font-family: ${themeGet('fontFamilySemibold')};
    color: ${themeGet('colors.darkGray')};
    font-size: 20px;
    line-height: 2em;
  }

  span:first-of-type {
    font-family: ${themeGet('headerFontFamilySemibold')};
    color: ${themeGet('colors.secondStepBlue')};
    font-size: 22px;
  }

  img {
    width: 30px;
    vertical-align: text-bottom;
    margin-right: 0.3em;
  }

  button {
    margin-top: 1em;
    margin-right: 0.8em;
    width: 190px;
    height: 48px;
    font-size: 18px;
  }
`

export const SiteSummaryTable = styled.table`
  background-color: ${themeGet('colors.gray100')};
  flex-direction: column;
  justify-content: space-between;
  border-collapse: collapse;
  width: 100%;
`

export const TableHeader = styled.h2`
  font-family: ${themeGet('headerFontFamilySemibold')};
  font-size: 1.125rem;
  color: ${themeGet('colors.secondStepBlue')};
  margin-bottom: 0.6875rem;
`

export const TableRowContainer = styled.tr``

export const ColumnHeader = styled.th`
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 0.875rem;
  color: ${themeGet('colors.darkGray')};
  text-align: left;
  padding-bottom: 0.3125rem;
`

export const StyledGraphIcon = styled(GraphIcon)`
  color: ${themeGet('colors.blue')};
  margin-right: 0.5rem;
  margin-top: -0.2188rem;
`

export const StyledDownloadIcon = styled(DownloadIcon)`
  color: ${themeGet('colors.blue')};
  margin-right: 0.5rem;
  margin-top: -0.2188rem;
`

export const RenewLicensesButton = styled(ContainedButton)`
  margin-left: 0;
`

export const ReportLinkWrapper = styled.td`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.875rem 0;
  div {
    margin: 0;
    a {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
`

export const ReportLink = styled(TextLink)`
  cursor: pointer;
  width: fit-content;
  color: ${themeGet('colors.blue')};
  &:focus,
  &:hover {
    text-decoration: underline;
    color: ${themeGet('colors.secondStepBlue')};
  }

  font-family: ${themeGet('fontFamilySemibold')};
`

export const SiteName = styled.h2`
  color: ${themeGet('colors.darkBlue')};
  font-size: 2.375rem;
  font-family: ${themeGet('headerFontFamilySemibold')};
  font-weight: 600;
  letter-spacing: 0.0313rem;
  padding-bottom: 2.375rem;
  margin-left: 1.25rem;
`
export const SiteSummaryHeader = styled.h2`
  font-size: 1.125rem;
  font-family: ${themeGet('headerFontFamilySemibold')};
  color: ${themeGet('colors.darkBlue')};
  margin-bottom: 1.75rem;
`

export const LicenseRow = styled.tr`
  font-size: 1rem;
  width: 100%;
  border-top: 0.0625rem solid ${themeGet('colors.borderTable')};
`

export const LicenseName = styled.td`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamily')};
  padding: 1rem 2.5rem 0.875rem 0;
`

export const Expiration = styled.td`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamily')};
  padding: 1rem 2.5rem 0.875rem 0;
`
