// ========
// DO NOT PLACE THIS FOLDER IN THE FLAG SYSTEM. Controls flag system setup and can lead to inconsistency
// ========
import { withAuth0 } from '@auth0/auth0-react'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FlagsSingleton } from 'flags'
import { LD_CREDS } from 'config/env'
import { Grommet } from 'grommet'
import { getTheme } from '../themes'
import { Loading } from 'secondstep-components'
import SecondStepLayout from '../layouts/SecondStepLayout'
import { operations as sitesOperations } from 'store/sitesManager'

import {
  handleAuthUpdate,
  handleSitesMeFetch,
  handleUpdateUserContext,
  handleUserAnalyticsSetup,
} from './handlers'
import { LOADING_CONTEXT, LOADING_PROFILE } from './constants'

export const AppInitialize = ({
  access,
  auth0,
  children,
  dispatch,
  fetchSites,
  profile,
  router,
  sites,
}) => {
  // ============= Launch DARKLY USER CONTEXT ================
  const [isLoadingUserContext, setLoadingUserContext] = useState(null)
  const [hasUserContextBeenCalled, setContextHasBeenCalled] = useState(false)
  const hasAllInitialStateBeenLoaded =
    (!isEmpty(access) || access !== undefined) &&
    !isEmpty(profile) &&
    sites !== null &&
    !isLoadingUserContext &&
    hasUserContextBeenCalled

  useEffect(() => {
    const defaultLaunchDarklyUser = LD_CREDS.user.key
    const shouldUpdateLaunchDarklyUserContext =
      !isEmpty(profile) &&
      !hasUserContextBeenCalled &&
      FlagsSingleton.getUserContext().key === defaultLaunchDarklyUser

    if (shouldUpdateLaunchDarklyUserContext) {
      handleUpdateUserContext({
        profile,
        setContextHasBeenCalled,
        setLoadingUserContext,
      })
    }
  }, [profile, hasUserContextBeenCalled])
  // ============= END Launch DARKLY USER CONTEXT ================

  // ============= AUTH UPDATES ================
  useEffect(() => {
    handleAuthUpdate({ auth0, dispatch })
    handleSitesMeFetch(auth0, fetchSites)
  }, [auth0?.isAuthenticated, auth0?.isLoading, auth0?.user?.nickname])

  // ======== USER ANALYTICS SETUP =================
  useEffect(() => {
    handleUserAnalyticsSetup(profile, sites)
  }, [profile, sites])

  const mainTheme = getTheme('main')
  return (
    <Grommet theme={mainTheme}>
      {isLoadingUserContext && <Loading loadingText={LOADING_CONTEXT} />}
      {!hasAllInitialStateBeenLoaded && !isLoadingUserContext && (
        <Loading dataTestId="loading-profile" loadingText={LOADING_PROFILE} />
      )}
      {hasAllInitialStateBeenLoaded && (
        <SecondStepLayout>{children}</SecondStepLayout>
      )}
    </Grommet>
  )
}

const mapStateToProps = ({
  router,
  sitesManager,
  userAccessManager,
  userProfileManager,
}) => {
  return {
    profile: userProfileManager?.profile,
    access: userAccessManager?.access,
    router: router,
    sites: sitesManager?.sites?.data,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    fetchSites: () => dispatch(sitesOperations.fetchSites()),
  }
}

export default withAuth0(
  connect(mapStateToProps, mapDispatchToProps)(AppInitialize),
)

AppInitialize.propTypes = {
  access: PropTypes.object,
  auth0: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    loginWithRedirect: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      nickname: PropTypes.string,
      sub: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  dispatch: PropTypes.func,
  fetchSites: PropTypes.func,
  profile: PropTypes.object,
  router: PropTypes.object,
  sites: PropTypes.array,
}
