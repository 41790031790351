import types from './types'

export const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      const { id, text, color, dismissable, timeout } = action
      return [
        ...state,
        {
          id,
          text,
          color,
          dismissable,
          timeout,
        },
      ]

    case types.REMOVE_NOTIFICATION:
      return state.filter(n => {
        return n.id !== action.id
      })

    default:
      return state
  }
}
