import React from 'react'
import PropTypes from 'prop-types'
import {
  ActionLink,
  CantEditRemoveCopy,
  CantEditRemoveWrapper,
  UserActionsWrapper,
  UserActionsButton,
  UserActionMenu,
  UserActionMenuItem,
  Tip as Tooltip,
} from './component.styles'

// TODO: Add some animations and also make the last menu
// open upward
const UserActions = ({
  actions,
  areActionsOpen,
  cantRemoveEditText,
  isCurrentUser,
  isRostered,
  rowId,
  toggleActions,
}) => {
  return (
    <UserActionsWrapper>
      <UserActionsButton
        data-testid={`user-actions-button-${rowId}`}
        onClick={toggleActions}
      >
        <i className="fas fa-ellipsis-h" />
      </UserActionsButton>
      {areActionsOpen && (
        <UserActionMenu
          data-testid={`user-actions-menu-${rowId}`}
          useGrayBackground={isCurrentUser}
        >
          {isCurrentUser ? (
            <CantEditRemoveWrapper
              data-testid={`user-actions-cant-remove-${rowId}`}
            >
              <CantEditRemoveCopy>{cantRemoveEditText}</CantEditRemoveCopy>
            </CantEditRemoveWrapper>
          ) : (
            <>
              {actions.map(action => (
                <UserActionMenuItem key={action.text}>
                  {action.type === 'remove' && isRostered ? (
                    <Tooltip
                      content={action.tooltipText}
                      dataTestId="user-actions-rostered-tooltip"
                    >
                      <ActionLink
                        dataTestId={`user-actions-menu-item-${action.type}-${rowId}`}
                        disabled
                        type={action.type}
                      >
                        {action.text}
                      </ActionLink>
                    </Tooltip>
                  ) : (
                    <ActionLink
                      dataTestId={`user-actions-menu-item-${action.type}-${rowId}}`}
                      onClick={action.handler}
                      type={action.type}
                    >
                      {action.text}
                    </ActionLink>
                  )}
                </UserActionMenuItem>
              ))}
            </>
          )}
        </UserActionMenu>
      )}
    </UserActionsWrapper>
  )
}

UserActions.propTypes = {
  actions: PropTypes.array.isRequired,
  areActionsOpen: PropTypes.bool.isRequired,
  cantRemoveEditText: PropTypes.string.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  isRostered: PropTypes.bool.isRequired,
  rowId: PropTypes.string.isRequired,
  toggleActions: PropTypes.func.isRequired,
}

export default UserActions
