/* eslint-disable */
const SUBDOMAIN = process.env.SUBDOMAIN
const HOST_DOMAIN = process.env.HOST_DOMAIN
const LD_CLIENT_ID = process.env.LD_CLIENT_ID
const AUTH_SUBDOMAIN = process.env.AUTH_SUBDOMAIN
const AUTH_CLIENT_ID = process.env.AUTH_CLIENT_ID

const PACT_MOCK_SERVICE_URL = 'http://localhost:1234'

const urlMapping = {
  admin: `https://admin${SUBDOMAIN}.${HOST_DOMAIN}`,
  api: `https://api${SUBDOMAIN}.${HOST_DOMAIN}/v3`,
  app: `https://app${SUBDOMAIN}.${HOST_DOMAIN}`,
  cdn: 'https://web-assets-p-endpoint.azureedge.net',
  learn: `https://learn${SUBDOMAIN}.${HOST_DOMAIN}`,
  lms: `https://lms${SUBDOMAIN}.${HOST_DOMAIN}`,
  login: `https://login${SUBDOMAIN}.${HOST_DOMAIN}`,
  publicSite: `https://www${SUBDOMAIN}.${HOST_DOMAIN}`,
  player: `https://player${SUBDOMAIN}.${HOST_DOMAIN}`,
  reporting: `https://reporting${SUBDOMAIN}.${HOST_DOMAIN}`,
}

let lmsUrl = process.env.LOCAL_LMS ? 'http://localhost:8091' : urlMapping.lms
let reportingUrl = process.env.LOCAL_REPORTING
  ? 'http://localhost:6010'
  : urlMapping.reporting
let apiUrl = urlMapping.api

if (process.env.PACT_TESTING) {
  lmsUrl = PACT_MOCK_SERVICE_URL
  apiUrl = PACT_MOCK_SERVICE_URL
}

const config_env = {
  default: {
    domains: {
      ADMIN_DOMAIN: urlMapping.admin,
      APP_DOMAIN: urlMapping.app,
      CDN: urlMapping.cdn,
      LEARN_APP_DOMAIN: urlMapping.learn,
      LMS_API: `${lmsUrl}/api/v1`,
      PUBLIC_SITE_DOMAIN: urlMapping.publicSite,
      REPORTING_API: `${reportingUrl}/graphql`,
      SECONDSTEP_API: apiUrl,
      STORE_DOMAIN: urlMapping.publicSite,
    },
    auth0Settings: {
      domain: `login${SUBDOMAIN}.${HOST_DOMAIN}`,
      audience: 'secondstep-apis',
      clientId: AUTH_CLIENT_ID,
    },
  },
  local: {
    domains: {
      ADMIN_DOMAIN: 'http://localhost:4001',
      APP_DOMAIN: 'http://localhost:3000',
      CDN: urlMapping.cdn,
      LEARN_APP_DOMAIN: 'http://localhost:4000',
      LMS_API: `${lmsUrl}/api/v1`,
      PUBLIC_SITE_DOMAIN: 'https://www-np-dev.cfctest.org',
      REPORTING_API: `${reportingUrl}/graphql`,
      SECONDSTEP_API: 'https://api-np-dev.cfctest.org/v3',
      STORE_DOMAIN: 'https://store-np-dev.secondstep.org',
    },
    auth0Settings: {
      domain: `login-np-dev.cfctest.org`,
      audience: 'secondstep-apis',
      clientId: 'qfrKmK8DN5b3rsLXjCqFYrQeh46YYqUJ',
    },
  },
  localApp: {
    domains: {
      ADMIN_DOMAIN: 'http://localhost:4001',
      APP_DOMAIN: urlMapping.app,
      CDN: urlMapping.cdn,
      LEARN_APP_DOMAIN: urlMapping.learn,
      LMS_API: `${lmsUrl}/api/v1`,
      PUBLIC_SITE_DOMAIN: urlMapping.publicSite,
      REPORTING_API: `${reportingUrl}/graphql`,
      SECONDSTEP_API: apiUrl,
      STORE_DOMAIN: urlMapping.publicSite,
    },
    auth0Settings: {
      domain: `login-np-dev.cfctest.org`,
      audience: 'secondstep-apis',
      clientId: 'qfrKmK8DN5b3rsLXjCqFYrQeh46YYqUJ',
    },
  },
}

// duplicate `default` settings as `test` for jest
config_env.test = config_env.default

const environment = process.env.BUILD_TYPE || 'test'
let env = config_env[environment]
env.environment = environment
export const domains = env.domains
export const auth0Settings = env.auth0Settings
export const LD_CREDS = {
  clientSideID: LD_CLIENT_ID,
  user: {
    email: 'fakeLumUser@notrealdomain.org',
    kind: 'user',
    key: 'default_fake_lum_user_key',
    name: 'Default Fake LUM User',
  },
}

export default env
