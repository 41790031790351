import actions from './actions'
import api from 'lib/api/secondStepApi'

const fetchCountries = () => {
  return async dispatch => {
    dispatch(actions.fetchingCountries())

    try {
      const response = await api.getCountries()
      dispatch(actions.fetchingCountriesSuccess(response.data))
    } catch (error) {
      dispatch(
        actions.fetchingLocationError(
          'There was a problem loading your countries. Please try again later.',
        ),
      )
    }
  }
}

const fetchRegions = country => {
  return async dispatch => {
    dispatch(actions.fetchingRegions())

    try {
      const response = await api.getRegions(country)
      dispatch(actions.fetchingRegionsSuccess(response.data))
    } catch (error) {
      dispatch(
        actions.fetchingLocationError(
          'There was a problem loading your regions. Please try again later.',
        ),
      )
    }
  }
}

const fetchCitiesWithRegion = (country, region) => {
  return async dispatch => {
    dispatch(actions.fetchingCitiesWithRegion())

    try {
      const response = await api.getCitiesWithRegion(country, region)
      dispatch(actions.fetchingCitiesWithRegionSuccess(response.data))
    } catch (error) {
      dispatch(
        actions.fetchingLocationError(
          'There was a problem loading your cities. Please try again later.',
        ),
      )
    }
  }
}

const fetchCitiesWithoutRegion = country => {
  return async dispatch => {
    dispatch(actions.fetchingCitiesWithoutRegion())

    try {
      const response = await api.getCitiesWithoutRegion(country)
      dispatch(actions.fetchingCitiesWithoutRegionSuccess(response.data))
    } catch (error) {
      dispatch(
        actions.fetchingLocationError(
          'There was a problem loading your cities. Please try again later.',
        ),
      )
    }
  }
}

const fetchNetSuiteCustomersWithRegion = (city, country, region) => {
  return async dispatch => {
    dispatch(actions.fetchingNetSuiteCustomersWithRegion())

    try {
      const response = await api.getNSCustomersWithRegion(city, country, region)
      dispatch(actions.fetchingNetSuiteCustomersWithRegionSuccess(response.data))
    } catch (error) {
      dispatch(
        actions.fetchingLocationError(
          'There was a problem loading your sites. Please try again later.',
        ),
      )
    }
  }
}

const fetchNetSuiteCustomersWithoutRegion = (city, country) => {
  return async dispatch => {
    dispatch(actions.fetchingNetSuiteCustomersWithoutRegion())

    try {
      const response = await api.getNSCustomersWithoutRegion(city, country)
      dispatch(actions.fetchingNetSuiteCustomersWithoutRegionSuccess(response.data))
    } catch (error) {
      dispatch(
        actions.fetchingLocationError(
          'There was a problem loading your sites. Please try again later.',
        ),
      )
    }
  }
}

export default {
  fetchCitiesWithoutRegion,
  fetchCitiesWithRegion,
  fetchCountries,
  fetchRegions,
  fetchNetSuiteCustomersWithoutRegion,
  fetchNetSuiteCustomersWithRegion,
}
