import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { FormInputSelect } from 'secondstep-components'

export const Header = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 3.125rem;
  font-weight: bold;
  font-variant-caps: all-small-caps;
  background-color: ${themeGet('colors.error')};
  margin-bottom: 0.125rem;
  color: ${themeGet('colors.white')};
  width: 9.375rem;
`

export const StyledFormInputSelect = styled(FormInputSelect)`
  label {
    font-size: 0.75rem;
    font-family: ${themeGet('fontFamilySemibold')};
    color: ${themeGet('colors.darkGray')};
  }
  button {
    width: 27rem;
    font-family: ${themeGet('fontFamily')};
    border: 0.0938rem solid ${themeGet('colors.blueGray')};
  }
  svg {
    color: ${themeGet('colors.gray550')};
  }
`
