import React from 'react'
import { Checkbox, Label } from './component.styles'

export const CustomCheckBox = ({
  text,
  checked,
  onChange,
  isDisabled,
  name,
}) => {
  const handleChange = e => onChange(e)

  return (
    <Label>
      <Checkbox
        checked={checked}
        disabled={isDisabled}
        name={name}
        onChange={handleChange}
      />
      {text}
    </Label>
  )
}
