import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Divider,
  IconContainer,
  Icons,
  Icon,
  Left,
  Logo,
  LogoContainer,
  Right,
  Title,
  TitleContainer,
} from './component.styles'
import { LOGO_LINK, SSLogo } from './data'

import ProfileDropdown from './ProfileDropdown'

class GlobalNavigation extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    title: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.profileDropdownRef = React.createRef()
    this.state = { showProfileDropdown: false }
  }

  closeProfileDropdown = () => {
    this.setState({ showProfileDropdown: false })
  }

  onPress = () => {
    const { showProfileDropdown } = this.state || {}
    this.setState({ showProfileDropdown: !showProfileDropdown })
  }

  navigationEvent = (route, type) => {
    const NEW_TAB = 'NEW_TAB'
    const OPEN = 'OPEN'

    if (type === NEW_TAB) {
      window.open(route, '_blank', 'noopener,noreferrer')
    } else if (type === OPEN) {
      window.location.href = route
    } else {
      window.location.href = route
    }
  }

  navigateLeaveApp = route => {
    this.closeProfileDropdown()
    this.navigationEvent(route, 'OPEN')
  }

  navigateToPage = route => {
    this.closeProfileDropdown()
    this.navigationEvent(route)
  }

  render() {
    const { showProfileDropdown } = this.state || {}
    const { backgroundColor, name, product } = this.props || {}
    const { title } = product || {}
    return (
      <Container
        backgroundColor={backgroundColor}
        data-testid={'global-nav-container'}
      >
        <Left>
          <LogoContainer
            aria-label="Second Step Programs: Home"
            data-testid={'global-nav-logo'}
            href={LOGO_LINK}
          >
            <Logo accessible alt="My Dashboard" src={SSLogo} />
          </LogoContainer>
          <Divider />
          <TitleContainer>
            <Title data-testid={'global-nav-title'}>{title}</Title>
          </TitleContainer>
        </Left>
        <Right>
          <Icons>
            <IconContainer
              aria-controls="profile-dropdown"
              aria-expanded={showProfileDropdown}
              aria-label="Profile Dropdown"
              data-testid={`global-nav-icon-0-PROFILE`}
              onClick={this.onPress}
              onPress={this.onPress}
              role="button"
            >
              <Icon
                focusable="false"
                labelledBy="t1"
                role="img"
                title={'My Account'}
              />
            </IconContainer>
          </Icons>
          <ProfileDropdown
            data-testid={'global-nav-profile-dropdown'}
            id="profile-dropdown"
            key={'profile-dropdown'}
            name={name}
            navigateLeaveApp={this.navigateLeaveApp}
            navigateToPage={this.navigateToPage}
            ref={this.profileDropdownRef}
            show={showProfileDropdown}
          />
        </Right>
      </Container>
    )
  }
}

export default GlobalNavigation
