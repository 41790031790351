import types from './types'
import { sortArrayBy } from '../../lib/util'
// import { InvitationStatus, addUserToLicense } from '../../lib/util'
// import {
//   ADDING_USERS_TEXT,
//   CANCELLING_INVITATION_TEXT,
//   INVITING_USERS_TEXT,
//   LOADING_LUM_TEXT,
//   UPDATING_USER_ROLE_TEXT,
// } from '../../lib/constants'

export const initialState = {
  error: null,
  isDeleting: false,
  isInitialized: false,
  isUpdating: false,
  isLoading: false,
  loadingMessage: null,
  isLicenseAdmin: false,
  isBulkOperationCompleted: false, // Indicator of add multiple users operation status
  bulkOperationResult: [],
  userLicenses: [],
  selectedUserLicense: {},
}

// TODO: This reducer aint immutable enough. We need to make it more immutable.
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_LICENSES:
      return {
        ...state,
        isLoading: true,
      }

    case types.FETCHING_LICENSES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isLicenseAdmin: false,
      }

    case types.FETCHING_LICENSES_SUCCESS:
      const { licenses } = action
      // TODO: this needs to be fixed up with the new licenses/me endpoint
      // userLicenses.sort((a, b) => a.siteName.localeCompare(b.siteName))
      // const hasSelectedLicense = state.selectedUserLicense.licenseId
      // const updatedSelectedUserLicense = hasSelectedLicense
      //   ? state.selectedUserLicense
      //   : userLicenses[0]

      licenses.activeLicenses.sort((a, b) => {
        const siteNameA = a.siteName || ''
        const siteNameB = b.siteName || ''
        return siteNameA.localeCompare(siteNameB)
      })

      // const { unassignedLicenses: unassignedSetupLicenses } = action.licenses
      licenses.unassignedLicenses.sort((a, b) => {
        const productDescriptionA = a.product.description || ''
        const productDescriptionB = b.product.description || ''
        return productDescriptionA.localeCompare(productDescriptionB)
      })

      return {
        ...state,
        isLoading: false,
        userLicenses: licenses,
        isInitialized: true,
        // TODO: this needs to be changed later when we update license assignment
        isLicenseAdmin: true,
      }

    case types.SENDING_LICENSE_ASSIGNMENT: {
      return {
        ...state,
        error: null,
        isUpdating: true,
      }
    }

    case types.SENDING_LICENSE_ASSIGNMENT_ERROR: {
      return {
        ...state,
        error: action.error,
        isUpdating: false,
      }
    }

    case types.SENDING_LICENSE_ASSIGNMENT_SUCCESS: {
      const { license: newLicense, subscriptionId } = action
      const skuValue = newLicense?.product?.sku?.skuValue

      // remove a license on this subscription from unassigned licenses
      const removeIndex = state.userLicenses.unassignedLicenses.findIndex(
        license =>
          license?.product?.sku?.skuValue === skuValue &&
          license.subscriptionId === subscriptionId,
      )
      const newUnassignedLicenses = [...state.userLicenses.unassignedLicenses]
      newUnassignedLicenses.splice(removeIndex, 1)
      // add newly assigned license to assigned licenses collection
      const newActiveLicenses = [
        ...state.userLicenses.activeLicenses,
        newLicense,
      ]
      const newAdminLicenses = [...state.userLicenses.adminLicenses, newLicense]
      const sortedNewActiveLicenses = sortArrayBy(newActiveLicenses, 'siteName')
      const sortedNewAdminLicenses = sortArrayBy(newAdminLicenses, 'siteName')

      return {
        ...state,
        error: null,
        isUpdating: false,
        userLicenses: {
          ...state.userLicenses,
          unassignedLicenses: newUnassignedLicenses,
          activeLicenses: sortedNewActiveLicenses,
          adminLicenses: sortedNewAdminLicenses,
        },
        isLicenseAdmin: true,
      }
    }

    default:
      return state
  }
}
