import { createGlobalStyle } from 'styled-components'
import theme from '../themes/main'

export default createGlobalStyle`
  html {
    font-size: 16px;
  }
  body {
    font-family: ${theme.fontFamily};
    box-sizing: border-box;
    color: ${theme.colors.textBase};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-family: ${theme.fontFamilyBold};
    font-weight: 400;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.headerBase};
  }

  h1 {
    font-size: 2.625rem;
    line-height: 3rem;

    ${theme.breakpoints.mobile} {
      font-size: 2.325rem;
      line-height: 2.75rem;
    }
  }

  h2 {
    font-size: 1.75rem;
    line-height: 2.125rem;

    ${theme.breakpoints.mobile} {
      font-size: 1.625rem;
      line-height: 2rem;
    }
  }

  h3 {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  h4 {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  h5 {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  p, a {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  a {
    color: ${theme.linkBase};
  }

  div[data-reach-dialog-overlay] {
    z-index: 2;
  }

  [class*="SelectContainer"] span {
    margin-bottom: 0;
  }

  [class*="SelectContainer"] > div > button:disabled {
    opacity: 1;
    cursor: not-allowed;
  }

  #form-input-select-license__drop  {
    button:hover, button:focus-visible {
      color: ${theme.global.hover.color};
      background-color: ${theme.colors.darkBlue};
    }
  }
`
