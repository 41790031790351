import React from 'react'
import propTypes from 'prop-types'
import {
  RegTrademarkIconSemibold,
  RegTrademarkIconBold,
} from './component.styles'

const RegTrademarkText = ({ fontWeight, text }) => {
  if (!text) text = 'Second Step'
  return (
    <>
      {text}
      {fontWeight === 'semibold' && (
        <RegTrademarkIconSemibold>&reg;</RegTrademarkIconSemibold>
      )}
      {fontWeight === 'bold' && (
        <RegTrademarkIconBold>&reg;</RegTrademarkIconBold>
      )}{' '}
    </>
  )
}

RegTrademarkText.propTypes = {
  fontWeight: propTypes.string,
  text: propTypes.string,
}

export default RegTrademarkText
