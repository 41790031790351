import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ErrorBoundary } from 'secondstep-components'
import SiteUserGrid from './component'

const SiteUserGridContainer = props => {
  const {
    handleCancelUserAccess,
    handleGrantNewRole,
    handleRemindEmail,
    hasK8License,
    site,
    currentUser,
    users,
    displayUserActions,
  } = props

  return (
    <ErrorBoundary>
      <SiteUserGrid
        currentUser={currentUser}
        displayUserActions={displayUserActions}
        handleCancelUserAccess={handleCancelUserAccess}
        handleGrantNewRole={handleGrantNewRole}
        handleRemindEmail={handleRemindEmail}
        hasK8License={hasK8License}
        site={site}
        users={users}
      />
    </ErrorBoundary>
  )
}

SiteUserGridContainer.propTypes = {
  currentUser: PropTypes.object.isRequired,
  displayUserActions: PropTypes.bool,
  handleCancelUserAccess: PropTypes.func.isRequired,
  handleGrantNewRole: PropTypes.func.isRequired,
  handleRemindEmail: PropTypes.func.isRequired,
  hasK8License: PropTypes.bool,
  site: PropTypes.object.isRequired,
  users: PropTypes.array,
}

const mapStateToProps = ({ licenseManager, userProfileManager }) => {
  const { selectedUserLicense } = licenseManager || {}
  const license = { ...selectedUserLicense }
  return {
    currentUser: userProfileManager.profile,
    license,
  }
}

export default connect(mapStateToProps)(SiteUserGridContainer)
